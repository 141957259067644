<div class="about">
  <h1>
    INFO HAYAT TV
  </h1>

  <h2>HAYAT HD</h2>

  <p>Hayat TV- osnovana je 1991. godine, te je neprekidnim radom osvojila ne samo bosanskohercegovački medijski prostor,
    nego i šire, te postala programski prepoznatljiva. Pripada grupi televizijskih kuća opšteg programskog
    opredjeljenja, koje su orijentisane ka širem auditoriju, što se odražava u programima informativnog, zabavnog,
    muzičkog,&nbsp; sportskog, dokumentarnog, dječijeg, vjerskog i kulturalnog karaktera. Nakon 23 godine uspješnog
    rada, Hayat je naprepoznatljiviji bh. TV brend. &nbsp;Danas, emitujemo ŠEST kanala od kojih PET možete pratiti putem
    satelita Eutelsat 16A</p>

  <p><strong>Važne činjenice:</strong></p>

  <ul>
    <li>Hayat je kompanija koja ima 250 uposlenika</li>
    <li>Emituje 5 programa: Hayat HD, Hayat PLUS, Hayat MUSIC, Hayat FOLK i prvi &nbsp;dječiji bh. kanal – Hayatovci.
    </li>
    <li>Svi kanali emituju se 24/7, s udjelom od 7 do 9 sati vlastite produkcije</li>
    <li>Hayat TV u svijetu gleda više od 20 miliona ljudi, od toga 2,5 miliona u BiH</li>
    <li>Cijeli Hayatov paket emituje u sklopu paketa m:sat</li>
    <li>Putem kablovskih i IPTV sistema Hayat TV možete pratiti u: USA, AUS, Švedskoj, Danskoj,&nbsp; Njemačkoj,
      Austriji, Švicarskoj, Sloveniji, Hrvatskoj, Crnoj Gori, Srbiji, Makedoniji i Turskoj
    </li>
    <li>Hayat TV je dobitnik nagrade “CNN World Report” za najbolji prilog u 2002. godini, u kategoriji “Special
      Events”
    </li>
    <li>Pružamo tehničku HD podršku svjetskim i regionalnim TV stanicama kao što su: Al Jazeera , CNN, APTN, Reuters,
      &nbsp;TRT, HRT, Nova TV i druge.
    </li>
    <li>Naši poslovni partneri su najjači privredni subjekti u BiH i šire.</li>
    <li>Hayat TV je društveno odgovorna kompanija, a dokaz tome su mnogobrojne humanitarne akcije i projekti koje smo
      pokrenuli kao što su “Dobri ljudi”, pomoću kojih smo sakupili&nbsp; stotine hiljada maraka za institucije kojima
      je pomoć najpotrebnija&nbsp; a tu je i emisija “Ispuni mi&nbsp;&nbsp; &nbsp;želju”, u okviru koje smo ispunili
      više od 1.000 želja naših gledalaca širom BiH.
    </li>
    <li>Prva smo komercijalna TV stanica u regionu čija je zvanična FB stranica verifikovana od strane Facebooka
      (facebook.com/HayatMediaBIH)
    </li>
    <li>Kroz aplikaciju Hayat i web portal možete koristiti servis GLEDAJ HAYAT (web tv) i videoteku HAYAT PLAY
      (s-vod)
    </li>
  </ul>

  <h2>HAYAT PLUS</h2>

  <p>TV kanal iz Hayatove porodice kanala namjenjen prvenstveno dijaspori. Program emitujemo od 2002. godine</p>


  <p> Hayat PLUS izvorno emituje u HD. </p>


  <p>Emisije &#8220;Hayat PLUS&#8221; produkcije ostvaruju se uglavnom putem direktnog kontakta s našim ljudima,
    Bosancima i Hercegovcima, u svijetu.</p>


  <p>&#8211; Reportaže o životu ljudi u Australiji, Sjednjenim državama i Evropi.</p>


  <p>&#8211; Najbolje serije bez reklama i prekida</p>


  <p>&#8211; Pregled Nogometne lige Federacije BiH.</p>


  <p>&#8211; Posebnu pažnju posvećujemo religiji u službi života.</p>


  <p>&#8211; Reemitujemo program Glasa Amerike (VOA) na jezicima naroda u BiH.</p>


  <p>&#8211; Emitujemo i najznačajnije emisije iz produkcije Hayat&nbsp; TV-a i to u terminu prilagođenom ljudima u
    drugim vremenskim zonama.</p>


  <p>&#8211; Sarađujemo s lokalnim tv-stanicama emitujući njihove sedmične hronike. Naši gledatelji imaju priliku dobiti
    i informacije iz Bosanske Krajine, Travnika, Brčkog, Jablanice, Tešnja i drugih gradova BiH.</p>


  <p>&#8211; U svijetu Hayat PLUS gleda više od 20 miliona ljudi!</p>


  <h2>HAYAT MUSIC</h2>

  <p>&#8211; TV kanal iz Hayatove porodice kanala &nbsp;(Hayat TV, Hayat PLUS, Hayat FOLK, HAYATOVCI).</p>


  <p>&#8211; Prvi i jedini bh. muzički kanal koji emituje samo ZABAVNU muziku, uz slogan NAJBOLJA DOMAĆA MUZIKA.</p>


  <p>&#8211; Program se emituje od avgusta 2011. godine.</p>


  <p>&#8211; Hayat MUSIC izvorno emituje u HD. </p>


  <p>&#8211; Na HMTV muzika je važna i zato s posebnom pažnjom biramo svaku playlistu.</p>


  <h2>HAYAT FOLK</h2>

  <p>&#8211; Drugi muzički kanal Hayata specijalizovan za pravu narodnu muziku i sevdah</p>


  <p>&#8211; Program se emituje od oktobra 2011. godine.</p>


  <p>&#8211; Hayat FOLK izvorno emituje u HD. </p>


  <p>&#8211; Od juna 2016. programski sadržaj čini SAMO muzika iz BIH, zatim, istinska narodna muzika iz prošlosti, uz
    procenat od tek 5% novokomponovane folk muzike iz regiona</p>


  <p>&#8211; Kanal je namijenjen širokoj publici koja voli istinsku narodnu muziku</p>


  <h2>HAYATOVCI</h2>

  <p>&#8211; Peti kanal Hayata namijenjen djeci uzrasta do 14 godina</p>


  <p>&#8211; Program se emituje od maja 2014. godine. </p>
  <p>&#8211; Hayatovci izvorno emituje u HD. </p>

  <p>&#8211; Programski sadržaj čine crtani filmovi i edukativni sadržaji</p>


  <p>&#8211; Dnevno emitujemo 6 sati premijernog dječijeg programa a vikendom 8 sati premijera</p>


  <p>&#8211; Program je koncipiran prema uzrastu djece po time slotovima:</p>


  <p>&nbsp;&nbsp; 06:00h &#8211; 09:00h &#8211; sadržaj za djecu do 6 godina</p>


  <p>&nbsp;&nbsp; 09:00h &#8211; 12:00h &#8211; sadžaj za djecu do 14 godina</p>


  <p>&nbsp;&nbsp; 12h &#8211; 18h &#8211; prva repriza premijernog sadržaja</p>


  <p>&nbsp;&nbsp; 18h &#8211; 00h &#8211; druga repriza premijernog sadržaja</p>


  <p>&nbsp;&nbsp; 00h &#8211; 06h &#8211; treća repriza premijernog sadržaja</p>


  <p>&#8211; Svi sadržaji su sihronizovani na bosanski jezik (trenutno 60% sadržaja a u budućnosti, uskoro i 90%) ili sa
    hrvatskom sihronizacijom</p>


  <p>Na Hayatovcima se emituje i kompletan paket trenutno najpopularnijih crtića na svijetu – LEGO.</p>


</div>
