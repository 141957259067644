import { Component } from '@angular/core';

@Component({
  selector: 'app-smart-tv-guide',
  standalone: true,
  imports: [],
  templateUrl: './smart-tv-guide.component.html',
  styleUrl: './smart-tv-guide.component.css'
})
export class SmartTvGuideComponent {

}
