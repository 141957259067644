import { Component } from '@angular/core';

@Component({
  selector: 'app-article-upload-loader',
  templateUrl: './article-upload-loader.component.html',
  standalone: true,
  styleUrls: ['./article-upload-loader.component.css']
})
export class ArticleUploadLoaderComponent {

}
