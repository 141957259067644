<div class="show">
  <div class="top">
    <button (click)="toggleEdit()">
      {{ isEditing ? 'Save' : 'Edit' }}
    </button>
  </div>

  @if(isEditing){
    <form [formGroup]="showForm" class="info">
      <div class="left">
        <div class="wrapper show-name">
          <input type="text" formControlName="name" id="name">
        </div>
        <div class="wrapper about">
          <textarea type="text" formControlName="about" id="about"></textarea>
        </div>
      </div>
      <div class="right">
        <mat-checkbox formControlName="chatEnabled">Chat</mat-checkbox>
      </div>
    </form>
  } @else if (this.show){
    <div class="info">
      <div class="left">
        <div class="wrapper show-name">
          <p>{{show.name}}</p>
        </div>
        <div class="wrapper about">
          <p> {{ show.about}}</p>
        </div>
      </div>
      <div class="right">
        <mat-checkbox [checked]="this.show.chatEnabled" [disabled]="true">Chat</mat-checkbox>
      </div>
    </div>
  }

  <div class="episodes">
    @if(this.show && this.show.episodes){
      @for (episode of this.show.episodes; track episode){
        <div class="episode">
          <div class="episode-info">
            <div class="name">
              {{ episode.order }}. {{ episode.topic }}
            </div>
            <div class="guests">
              Gosti:
              @for(guest of episode.guests; track guest){
                <p>{{guest.name}}</p>
              }
            </div>
            <div class="questions">
              Q&A: {{ episode.hasQuestions }}
            </div>
          </div>
          <div class="control-buttons">
            <button class="edit-button" (click)="openCreateEpisodeDialog(episode)">
              <i class="fa fa-pencil"></i>
            </button>
            <!--<button class="edit-button" [routerLink]="episode.order.toString()" (click)="selectEpisode(episode)">
              <i class="fa fa-pencil"></i>
            </button>-->
            <!--@if(this){
              <button class="remove-button" (click)="deleteArticle(episode)">
                <i class="fa fa-remove"></i>
              </button>
            }-->
          </div>
        </div>
      }
    }
    <div class="episode add" (click)="openCreateEpisodeDialog()">
      <p>+</p>
    </div>  </div>
</div>
