<div class="about">
  <h1>
    ‘GLEDAJ HAYAT’ I ‘HAYAT PLAY’: PRETPLATIŠ SE NA WEB TV I DOBIJEŠ I VIDEOTEKU, SA VIŠE OD 20 HILJADA SATI PROGRAMA
  </h1>
  <p style="font-weight: bold">
    Uživanje u TV programima koje volite sa servisom GLEDAJ HAYAT sada je lakše nego ikada. Servis GLEDAJ HAYAT možete
    pratiti uz najsigurniji sistem pretplate, a svi postojeći pretplatnici na GLEDAJ Hayat dobijaju i potpuno BESPLATNO
    pristup servisu – Emisijama na zahtjev (VOD), odnosno videoteci Hayat PLAY.
  </p>

  <p>Sada sve Hayat kanale, možete gledati UŽIVO, bilo kad, bilo gdje, na vašem mobitelu, tabletu, računaru i smart TV
    uređaju putem Hayat aplikacije ili na portalu hayat.ba.
  </p>

  <p>Da bi ste gledali uživo bilo koji Hayatov kanal, sve što trebate uraditi je ukucati web adresu našeg portala, te
    kliknuti u gornjem lijevom uglu na ikonu, GLEDAJ HAYAT.
  </p>

  <p>
    Nakon pristupa servisu, odaberite željeni paket pretplate, putem <a href="https://www.paypal.com/ba/home">PayPal</a>
    servisa naplate i uživajte u vašim omiljenim kanalima. PayPal je najsigurniji sistem plaćanja na svijetu. Možete ga
    imati i vi jer vam za njega ne treba ništa osim bankovne kartice. Sve što treba da uradite je da svoju karticu
    prijavite na PayPal. Prijavom kartice na PayPal sistem više ne morate i ne trebate ukucavati kodove i šifre sa svoje
    kartice na razne web sajtove i time omogućiti hakiranje vaše karitice. Dakle, prijavite svoju karticu na PayPal i
    možete biti sigurni 100%. Više o prijavi na PayPal saznajte <a href="https://www.paypal.com/ba/home">OVDJE</a>.
  </p>

  <p>
    Kada je u pitanju aplikacija za mobitel i tablet, princip pristupa servisu GLEDAJ HAYAT je isti. Otvorite meni na
    aplikaciji i kliknite na ikonu, Gledaj Hayat.
  </p>

  <p>
    Uživajte u najboljem domaćem programu, najboljim serijama, najboljoj muzici, najboljem dječijem programu u društvu,
    sa dragom komšinicom, sa voljenom osobom, sa porodicom, sa djecom.
  </p>

  <p>
    Svi Hayatovi (<span style="font-weight: bold">Hayat HD, Hayat PLUS, Hayat MUSIC, Hayat FOLK, Hayatovci</span>)
    dostupni su sada, na vašem mobitelu, tabletu, računaru i smart TV uređaju.
  </p>

  <p>
    Cijene pretplate servisa GLEDAJ HAYAT su vrlo konkurentne u odnosu na sve slične servise u svijetu, tako da sada, po
    veoma povoljnim i pristupačnim cijenama možete uživati uz 5 HD TV kanala a sada, <span style="font-weight: bold">bez dodatnog plaćanja, dakle, po istoj cijeni, dobijate i naš novi servis HAYAT PLAY (emisije na zahtjev).</span>
  </p>

  <p>
    Paketi pretplate su:
  </p>

  <ul>
    <li>
      <a href="https://www.hayat.ba/gledaj_hayat_placanje.php">Jedan mjesec</a>: <span
      style="font-weight: bold">2 KM</span> (2€ za gledaoce van BIH)
    </li>
    <li>
      <a href="https://www.hayat.ba/gledaj_hayat_placanje.php">Tru mjeseca</a>: <span
      style="font-weight: bold">5 KM</span> (5€ za gledaoce van BIH)
    </li>
    <li>
      <a href="https://www.hayat.ba/gledaj_hayat_placanje.php">Šest mjeseci</a>: <span
      style="font-weight: bold">8 KM</span> (8€ za gledaoce van BIH)
    </li>
    <li>
      <a href="https://www.hayat.ba/gledaj_hayat_placanje.php">Devet mjeseci</a>: <span
      style="font-weight: bold">12 KM</span> (12€ za gledaoce van BIH)
    </li>
    <li>
      <a href="https://www.hayat.ba/gledaj_hayat_placanje.php">Godinu dana</a>: <span
      style="font-weight: bold">14 KM</span> (14€ za gledaoce van BIH)
    </li>
  </ul>

  <p>
    – To znači da od sada Hayat možete ponijeti sa sobom gdje god da krenete i gledati vaše omiljene programe UŽIVO,
    gdje god da se u svijetu nalazite. Posebno nam je drago istaći, da će vaši mališani s uživanjem, od sada na svom
    TABLETU, MOBITELU, RAČUNARU i SMART TV UREĐAJU gledati prvi bh. dječiji kanal – HAYATOVCI’, kaže Igor Todorovac,
    direktor digitalnih medija Hayata i dodaje da sada svi pretplatnici servisa dobijaju i bonus kanale, bez dodatne
    naplate.
  </p>

  <p>
    – U ponudi videoteke Hayat PLAY korisnici mogu pogledati sve propuštene emisije ali i određene emisije koje ste
    voljeli, odnosno, one koje se nalaze u ponudi servisa Hayat PLAY, završava Todorovac.
  </p>

  <p style="font-weight: bold">
    Uslove korištenja servisa kao i detaljniji opis pristupa servisu potražite <button>OVDJE</button>. Ukoliko imate dodatnih pitanja,
    obratite nam se na email: <a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a>
  </p>

  <p>
    GLEDAJ Hayat. Bilo kad. Bilo gdje.
  </p>

  <div>__________</div>

  <p>
    <strong>USLOVI KORIŠTENJA</strong>
  </p>

  <p>
    <strong>
      Hayat d.o.o. nije odgovoran za kvalitet internet veze koju vam nudi vaš provajder a putem kojeg koristite
      uslugu GLEDAJ HAYAT i Hayat PLAY. Hayat se odriče od odgovornosti za eventualne štete ili nemogućnosti korištenja
      usluge.
    </strong>
  </p>

  <p><strong>Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY koristiti poštujući autorska i druga srodna
    prava u pogledu sadržaja koji se servisom prenosi. Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY
    koristiti za svoje privatne potrebe te istu neće, ni na koji način učiniti dostupnom javnosti u smislu važećih
    propisa o autorskim i srodnim pravima, a posebno garantuje da uslugu neće koristiti u zajedničkim ili javnim
    prostorijama bilo koje vrste, te da neće vršiti daljnu redistribuciju signala trećim licima, bilo da je u pitanju
    snimljeni ili sadržaj uživo.</strong></p>

  <p><strong>GLEDAJ Hayat/Hayat PLAY</strong></p>

  <p><strong>GLEDAJ HAYAT je multiscreen usluga, koja omogućava praćenje omiljenih TV kanala putem svih uređaja koji
    imaju pristup internetu. (tablet, mobilni, desktop/laptop) Ovaj servis za sada nije moguće pratiti putem Smart ili
    neke druge vrste TV uređaja.</strong></p>

  <p><strong>Registracijom na uslugu GLEDAJ HAYAT/HAYAT PLAY, te plaćanjem pretplate, po jednoj pretplati, validni su
    jedni pristupni podaci. Ako se korisnik koji je platio pretplatu, nalazi na istoj IP adresi gdje i ostali
    potencijalni korisnici (npr. Uslugu platio jedan član porodice a tu su još tri člana porodice), onda i ostali
    potencijalni korisnici mogu pristupiti servisu sa korisničkim podacima baznog vlasnika, odnsono korisničkim podacima
    korisnika, koji je platio pretplatu.</strong></p>

  <p><strong>Ako se bazni vlasnik ne nalazi na istoj IP adresi na kojoj su i ostali potencijalni korisnici, usluga će
    biti validna samo baznom vlasniku ili neće raditi.</strong></p>

  <p>Sa jednim nalogom (shared password) servise nije moguće koristiti istovremeno, na više uređaja, na različitim IP
    adresama.</p>

  <p><strong>Hayat PLAY je VOD (Video On Demand/Video na zahtjev) usluga putem koje možete gledati propuštene epizode
    emisija i serija, koje su trenutno aktuelne i u ponudi servisa.</strong></p>

  <p><strong>Hayat zadržava pravo izmjene programa u ponudi, te u svakom momentu može povući iz ponude stare sadržaje,
    odnosno ubaciti nove.</strong></p>

  <p><strong>Tokom premijernog emitovanja, strani serijski program u ponudi sadrži najmanje 7 a najviše 20 epizoda. U
    rijetkim slučajevima moguć je i veći broj epizoda u ponudi ali samo u ograničenom periodu od najviše mjesec
    dana.</strong></p>

  <p><strong>Nakon otvaranja vašeg naloga i plaćanja pretplate na servis GLEDAJ Hayat/Hayat PLAY, pretplatnik ima
    mogućnost da koristi navedene usluge na bezbroj uređaja u jednom trenutku (tablet, mobilni, desktop/laptop ili TV s
    pristupom internetu) na istoj lokaciji korištenja (istoj pristupnoj adresi). Korištenje usluge na više lokacija
    istovremeno zahtijeva dodatne naloge. Plaćanjem jedne usluge dobijate pristup servisu GLEDAJ Hayat, odnosno gledanju
    kanala iz ponude UŽIVO, ali i servisu Hayat PLAY, odnosno videoteci. Usluge je moguće koristiti bilo gdje u svijetu
    samo s vašim nalogom.</strong></p>

  <p><strong>Ukoliko imate pitanja u vezi sa servisima, korištenjem, pretplatom ili ukoliko imate bilo kakav problem,
    molimo vas obratite se na mail <a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a></strong></p>

  <p><strong>Ukoliko imate pitanja u vezi sa&nbsp;<em>pretplatom</em>, molimo vas obratite se na mail <a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a>
    ili na broj +387 33 492 942 (09-17h CET)</strong></p>
</div>
