<div class="impressum">
  <h1>
    Impressum
  </h1>

  <p class="has-text-align-center">Hayat&nbsp;&nbsp;<a href="http://www.hayat.ba/" target="_blank"
                                                       rel="noreferrer noopener">www.hayat.ba</a></p>

  <p class="has-text-align-center">Hayat PRODUCTION&nbsp;<a href="http://www.hayatproduction.ba/" target="_blank"
                                                            rel="noreferrer noopener">www.hayatproduction.ba</a></p>


  <p class="has-text-align-center">Skinite Hayat aplikaciju za vaš ANDROID uređaj&nbsp;<strong><a
    href="https://goo.gl/nnI7Rm" target="_blank" rel="noreferrer noopener">ovdje</a></strong>.</p>


  <p class="has-text-align-center">Skinite Hayat aplikaciju za vaš iOS uređaj&nbsp;<a href="https://goo.gl/rkYmgD"
                                                                                      target="_blank"
                                                                                      rel="noreferrer noopener"><strong>ovdje</strong></a>.
  </p>


  <p class="has-text-align-center"><a rel="noreferrer noopener" href="mailto:digitala@hayat.ba" target="_blank"><strong>Pošaljite
    vijest</strong></a></p>


  <hr class="wp-block-separator has-css-opacity">


  <p class="has-text-align-center"><strong>Urednik portala&nbsp;hayat.ba</strong></p>


  <p class="has-text-align-center">Amir Saletović</p>


  <p class="has-text-align-center"><a href="mailto:amir.saletovic@hayat.ba" target="_blank" rel="noreferrer noopener">amir.saletovic&#64;hayat
    .ba</a></p>


  <p class="has-text-align-center"><strong>Redakcija digitalnih medija</strong></p>


  <p class="has-text-align-center">Emrah Fejzović<br>Admir Jamaković<br>Mehmed Sultanović<br>Alem Herak<br>Ines
    Sandžaktarević<br>Ema Plakalo</p>


  <p class="has-text-align-center"><strong>Digital Manager/Hayat PLAY</strong></p>


  <p class="has-text-align-center">Damir Beha</p>


  <p class="has-text-align-center"><strong>Kontakt – digitalni sektor:</strong></p>


  <p class="has-text-align-center"><a href="mailto:digitala@hayat.ba" target="_blank"
                                      rel="noreferrer noopener">digitala&#64;hayat.ba</a></p>


  <p class="has-text-align-center"><strong>Digital marketing manager</strong></p>


  <p class="has-text-align-center">Amer Musić</p>


  <p class="has-text-align-center"><a rel="noreferrer noopener" href="mailto:amer.music@hayat.ba" target="_blank">amer.music&#64;hayat
    .ba</a></p>


  <p class="has-text-align-center"><strong>Head of Digital Media</strong></p>


  <p class="has-text-align-center">Igor Todorovac</p>


  <p class="has-text-align-center"><a href="mailto:igor.todorovac@hayat.ba" target="_blank" rel="noreferrer noopener">igor.todorovac&#64;hayat
    .ba</a></p>

</div>
