import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Show} from "../../../../../../models/show.model";
import {ShowService} from "../../../../../../services/show.services";
import {Episode} from "../../../../../../models/episode.model";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-show-about',
  standalone: true,
  imports: [],
  templateUrl: './show-about.component.html',
  styleUrl: './show-about.component.css'
})
export class ShowAboutComponent implements OnInit{

  show: Show | null = null // Variable to hold the show data
  episode: Episode | null = null // Variable to hold the show data
  dataSubscription: Subscription;

  episodeOrder: number | null = 0
  constructor(
    private showService: ShowService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,

) {
    this.dataSubscription = this.showService.onEpisodeDataUpdated().subscribe(() => {
      // Update data when episode data is updated in the service
      this.updateEpisodeData();
    });
  }
  ngOnInit(): void {
    this.episode = this.showService.getEpisode()
    this.route.queryParamMap.subscribe(params => {
      const episodeOrderString = params.get('episode');
      this.episodeOrder = episodeOrderString ? parseInt(episodeOrderString, 10) : null;
    });
    setTimeout(() => {
      this.show = this.showService.getStoredShow();
      this.cdr.detectChanges(); // Call detectChanges if needed
    }, 1000);

    /*this.show = this.showService.getStoredShow()
    this.cdr.detectChanges()*/
    //this.episode = this.show?.episodes.find((ep: Episode) => ep.order === this.episodeOrder) || null;
  }

  updateEpisodeData() {
    // Update episodeData based on episodeId from the service
    this.episode = this.showService.getEpisode();
    this.cdr.detectChanges()
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe(); // Unsubscribe to avoid memory leaks
  }
}
