<div class="category">
  @if(category){
    <h2>
      {{category[categoriesProperty.name]}}
    </h2>
  }
  <div class="articles" *ngIf="categoryArticles">
    <div *ngIf="categoryArticles[categoryArticlesProperty.photo].length > 0" class="photo">
      <div class="colum-title">
        <p>Slike</p>
      </div>
      @for(article of categoryArticles[categoryArticlesProperty.photo]; track article; let i = $index){
        @if(i % 3 == 0){
          <a class="photo-highlight article"
               [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
               [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
              <p>{{article.category.name}}</p>
            </div>
            <img src="{{article.image_list?.[0]?.url}}">
            <h2>{{article[articlesProperty.title]}}</h2>
          </a>
        } @else {
          <a class="photo-newest article"
               [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
               [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
              <p>{{article.category.name}}</p>
            </div>
            <img src="{{article.image_list?.[0]?.url}}">
            <h3>{{article[articlesProperty.title]}}</h3>
          </a>
        }
        @if(i % 6 == 0){
          <div id="hayat_ba_450x125_left_rectangle_4_responsive" class="display-flex" style="min-height: 125px;">
            <script type="text/javascript">
              googletag.cmd.push(function() { googletag.display(this.getAd()); });
            </script>
          </div>
        }
      }
    </div>

    <div *ngIf="categoryArticles[categoryArticlesProperty.text].length > 0" class="text">
      <div class="colum-title">
        <p>Text</p>
      </div>
      @for(article of categoryArticles[categoryArticlesProperty.text]; track article; let i = $index){
        @if(i % 3 == 0){
          <a class="text-highlight article"
               [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
               [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
              <p>{{article.category.name}}</p>
            </div>
            <img src="{{article.image_list?.[0]?.url}}">
            <h2>{{article[articlesProperty.title]}}</h2>
          </a>
        } @else {
          <a class="text-newest article"
             [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
             [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
              <p>{{article.category.name}}</p>
            </div>
            <h3>{{article[articlesProperty.title]}}</h3>
          </a>
        }
      }
    </div>

    <div *ngIf="categoryArticles[categoryArticlesProperty.video].length > 0" class="video-column">
      <div class="colum-title">
        <p>Video</p>
      </div>
      @for(article of categoryArticles[categoryArticlesProperty.video]; track article; let i = $index){
        <div class="video-newest article">
          <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>
          @if(!isVideo(article.image_list?.[0]?.url)){
            <img src="{{ article.image_list?.[0]?.url }}">
          } @else {
            <video
              #videoElement
              [src]="article.image_list?.[0]?.url"
              controls
              appVideoPlayer
              loop
              preload

              playsinline
            >
            </video>
          }
          <a
            [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
            [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{article[articlesProperty.title]}}
          </a>
        </div>
        @if(i % 4 == 3){
          <div id="hayat_ba_380x125_right_rectangle_small_3_responsive" class="display-flex" style="min-height: 125px;">
            <script type="text/javascript">
              (function () {
                var randomiser = (Math.random() + 1).toString(36).substring(7);
                var adID = document.querySelector('#hayat_ba_380x125_right_rectangle_small_3_responsive').id = "hayat_ba_380x125_right_rectangle_small_3_responsive-" + randomiser;
                inView('#' + adID).once('enter', (function () {
                  googletag.cmd.push(function () {
                    if (window.innerWidth >= 1280) {
                      googletag.defineSlot('/272140683/hayat.ba_380x125_right_rectangle_small_3_desktop', [[320,100],[320,50],[300,100],[300,50],[380,125]], adID).addService(googletag.pubads());
                    } else {
                      googletag.defineSlot('/272140683/hayat.ba_325x125_right_rectangle_small_3_mobile', [[320,100],[320,50],[300,100],[300,50],[325,125]], adID).addService(googletag.pubads());
                    }
                    googletag.display(adID);
                    stpd.initializeAdUnit(adID);
                  });
                }));
              })();
            </script>
          </div>
        }
      }
    </div>
  </div>
  <button *ngIf="page < 20" (click)="loadNextPage()" class="next-page">Ucitaj jos</button>






  <div class="articles-small-devices">
    @for(article of articles; track article; let i = $index){
      @if(article[articlesProperty.photoPost]){
        <a class="photo-highlight article"
             [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
             [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
          <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </a>
      } @else if (article[articlesProperty.videoPost]){
        <div class="video-newest article">
          <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>

          <img *ngIf="!isVideo(article.image_list?.[0]?.url); else video"
               src="{{ article.image_list?.[0]?.url }}">
          <ng-template #video>
            <video
              width="100%"
              height="197px"
              [src]="article.image_list?.[0]?.url"
              controls
              appVideoPlayer
              loop
              preload="auto"
            ></video>
          </ng-template>

          <!--<video
            width="100%"
            height="197px"
            [src]="article.image_list?.[0]?.url"
            controls
            appVideoPlayer
            loop
            preload="auto"
          >
          </video>-->
          <a
            [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
            [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
            <span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{article[articlesProperty.title]}}
          </a>
        </div>
      } @else if (article[articlesProperty.textPost]){
        <a class="text-highlight article"
           [routerLink]="['../article', article[articlesProperty.url_title], article[articlesProperty.id]]"
           [attr.href]="'../article/' + article[articlesProperty.url_title] + '/' + article[articlesProperty.id]">
          <div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </a>
      }
      @if(i % 6 === 0 && i !== 0){
        <div id="hayat_ba_350x350_square_3_responsive" class="sidebar-height square-height">
          <script type="text/javascript">
            (function () {
              var randomiser = (Math.random() + 1).toString(36).substring(7);
              var adID = document.querySelector('#hayat_ba_350x350_square_3_responsive').id = "hayat_ba_350x350_square_3_responsive-" + randomiser;
              inView('#' + adID).once('enter', (function () {
                googletag.cmd.push(function () {
                  if (window.innerWidth >= 1280) {
                    googletag.defineSlot('/272140683/hayat.ba_350x350_square_3_desktop', [[336,280],[300,250],[336,336],[336,320],[320,320],[300,300],[320,250],[350,350]], adID).addService(googletag.pubads());
                  } else {
                    googletag.defineSlot('/272140683/hayat.ba_300x300_square_3_mobile', [[300,250],[300,300],[250,250]], adID).addService(googletag.pubads());
                  }
                  googletag.display(adID);
                  stpd.initializeAdUnit(adID);
                });
              }));
            })();
          </script>
        </div>
      }
    }
  </div>
</div>
