<div class="header">
  <div class="navigation">
    <div class="left">
      <button class="logo"
              aria-label="home"
              [routerLink]="'/'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      </button>
    </div>

    <div class="center">
      <a href="https://www.facebook.com" target="_blank" class="social-icon">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com" target="_blank" class="social-icon">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://www.twitter.com" target="_blank" class="social-icon">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="https://www.youtube.com" target="_blank" class="social-icon">
        <i class="fab fa-youtube"></i>
      </a>
    </div>

    <div class="right">
      <button  class="button icon"
               [routerLink]="'gledaj-hayat'">
        <img src="assets/hayat-play.webp" alt="Gledaj Hayat">
      </button>
      <button
        *ngIf="tokenExists()"
        class="button login"
        (click)="logoutUser()"
      >
        <p>Log out</p>
      </button>
    </div>
  </div>
</div>
