export enum GuestProperty {
  id = '_id',
  email = 'email',
  password = 'password',
  name = 'name',
  username = 'username',
  role = 'role',
  images = 'images',
  image_60px = 'image_60px',
  image_300px = 'image_300px',
}
