import { Component } from '@angular/core';
import {GledajHayatHeaderComponent} from "../../common/gledaj-hayat-header/gledaj-hayat-header.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-gledaj-hayat',
  standalone: true,
  imports: [
    GledajHayatHeaderComponent,
    RouterOutlet
  ],
  templateUrl: './hayat-tv.component.html',
  styleUrl: './hayat-tv.component.css'
})
export class HayatTvComponent {

}
