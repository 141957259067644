import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormField, MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {DateAdapter, provideNativeDateAdapter} from "@angular/material/core";
import {MatIcon} from "@angular/material/icon";


@Component({
  selector: 'app-schedule',
  standalone: true,
  imports: [
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatIcon, FormsModule, ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './schedule-dialog.component.html',
  styleUrl: './schedule-dialog.component.css'
})
export class ScheduleDialogComponent {
  selectedDate: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScheduleDialogComponent>,
  ) {}

  currentDateTime(): string {
    const now = new Date();
    // Format the date and time as a string that can be used in the datetime-local input
    return now.toISOString().slice(0, 16);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.selectedDate) {
      const dateTime = new Date(this.selectedDate);
      this.dialogRef.close(dateTime);
    }
  }
}
