@if (articles.length !== 0) {
  @if (priority1Articles.length == 0) {
    <div class="highlight">
      <!-- Left Column -->
      <div class="column first">
        @if (priority2Articles.length > 0) {
          <a class="article priority-2"
             [routerLink]="['/article', priority2Articles[0].url_title, priority2Articles[0][articlesProperty.id]]"
             [attr.href]="'/article/' + priority2Articles[0].url_title + '/' + priority2Articles[0][articlesProperty.id]">
          <!--               [routerLink]="'article/' + priority2Articles[0].url_title + '/' + priority2Articles[0][articlesProperty.id]">-->
            <h2>
              @if (priority2Articles[0].text_post) {
                <span class="icon">
                  <i class="fa-solid fa-align-justify"></i>
                </span>
              } @else if (priority2Articles[0].photo_post) {
                <span class="icon">
                  <i class="fa-solid fa-camera"></i>
                </span>
              } @else if (priority2Articles[0].video_post) {
                <span class="icon">
                  <i class="fa-solid fa-video"></i>
                  <span class="red-dot"></span>
                </span>
              }
              {{ priority2Articles[0].title }}
            </h2>
            <div class="category">
              <p>{{ priority2Articles[0].category.name }}</p>
            </div>
            @if (priority2Articles[0].image_list && priority2Articles[0].image_list.length > 0) {
              <div class="media-container">
                @if (!isVideo(priority2Articles[0].image_list[0].url)) {
                  <img ngSrc="{{ priority2Articles[0].image_list[0].url }}"
                       style="position: relative"
                       alt="{{ priority2Articles[0].subtitle }}"
                       priority="high"
                       fill="">
                } @else {
                  <video
                    #videoPriority2
                    [src]="priority2Articles[0].image_list[0].url"
                    controls
                    muted
                    preload
                    autoplay
                    loop
                    playsinline
                    (click)="handleVideoClick($event)"
                  >
                  </video>
                }
              </div>
            }
            <p>
              {{ priority2Articles[0].subtitle }}
            </p>
          </a>

        }
        @if (windowWidth >= 1025) {
<!--          <div-->
<!--            id='hayat_ba_450x125_left_rectangle_2_responsive'-->
<!--            class="display-flex"-->
<!--            style="min-height: 125px;"-->
<!--          >-->
<!--            <script type="text/javascript">-->
<!--              googletag.cmd.push(function () {-->
<!--                googletag.display('hayat_ba_450x125_left_rectangle_2_responsive');-->
<!--              });-->
<!--            </script>-->
<!--          </div>-->
          <!-- /272140683/adxp_hayatba_inarticle_2 -->
          <div id='div-gpt-ad-1723029415874-0' style='min-width: 160px; min-height: 50px;'>
          </div>
        }
      </div>

      <!-- Center Column -->
      <div class="right">
        <div class="top">
          <a *ngFor="let article of priority6Articles" class="article priority-6"
               [routerLink]="['/article', article.url_title, article[articlesProperty.id]]"
               [attr.href]="'/article/' + article.url_title + '/' + article[articlesProperty.id]">
<!--               [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">-->
            <div class="info">
              <div class="category">
                <p>{{ article.category.name }}</p>
              </div>
            </div>
            <div class="content">
              <!-- Article content for priority 6 -->
              <h2>
                @if (article.text_post) {
                  <span class="icon">
                  <i class="fa-solid fa-align-justify"></i>
                </span>
                } @else if (article.photo_post) {
                  <span class="icon">
                  <i class="fa-solid fa-camera"></i>
                </span>
                } @else if (article.video_post) {
                  <span class="icon">
                  <i class="fa-solid fa-video"></i>
                  <span class="red-dot"></span>
                </span>
                }
                {{ article[articlesProperty.title] }}
              </h2>
            </div>
          </a>
        </div>
        <div class="bottom">
          <div class="column second">
            @for (article of priority4Articles; track article) {
              <a class="article priority-4"
                   [routerLink]="['/article', article.url_title, article[articlesProperty.id]]"
                   [attr.href]="'/article/' + article.url_title + '/' + article[articlesProperty.id]">
<!--                   [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">-->
                <!-- Article content for priority 4 -->
                <div class="category">
                  <p>{{ article.category.name }}</p>
                </div>
                @if (article.image_list && article.image_list.length > 0) {
                  <div class="media-container">
                    @if (!isVideo(article.image_list[0].url)) {
                      <img ngSrc="{{article.image_list[0].url}}"
                           alt="{{ priority4Articles[0].subtitle }}"
                           fetchpriority="high"
                           fill=""
                           style="position: relative"
                           alt="">
                    } @else {
                      <video
                        width="250px"
                        height="140px"
                        [src]="article.image_list[0].url"
                        controls
                        muted
                        autoplay
                        preload="auto"
                        loop
                      >
                      </video>
                    }
                  </div>
                }
                <h2>
                  @if (article.text_post) {
                    <span class="icon">
                  <i class="fa-solid fa-align-justify"></i>
                </span>
                  } @else if (article.photo_post) {
                    <span class="icon">
                  <i class="fa-solid fa-camera"></i>
                </span>
                  } @else if (article.video_post) {
                    <span class="icon">
                  <i class="fa-solid fa-video"></i>
                  <span class="red-dot"></span>
                </span>
                  }
                  {{ article.title }}
                </h2>
                <p>{{ article.subtitle }}</p>
              </a>
            }

          </div>
          <div class="column third">
            @for (article of priority3Articles; track article) {
              <a class="article priority-3"
                   [routerLink]="['/article', article.url_title, article[articlesProperty.id]]"
                   [attr.href]="'/article/' + article.url_title + '/' + article[articlesProperty.id]">
<!--                   [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">-->
                <!-- Article content for priority 3 -->
                <div class="category">
                  <p>{{ article.category.name }}</p>
                </div>
                <div *ngIf="article.image_list && article.image_list.length > 0" class="media-container">
                  @if (!isVideo(article.image_list[0].url)) {
                    <img ngSrc="{{article.image_list[0].url}}"
                         alt="{{ priority3Articles[0].subtitle }}"
                         fetchpriority="high"
                         fill=""
                         style="position: relative"
                         priority="">
                  } @else {
                    <video
                      #videoPriority3
                      width="400px"
                      height="225px"
                      [src]="article.image_list[0].url"
                      controls
                      muted
                      autoplay
                      preload="auto"
                      loop
                    >
                    </video>
                  }
                </div>
                <h2>
                  @if (article.text_post) {
                    <span class="icon">
                  <i class="fa-solid fa-align-justify"></i>
                </span>
                  } @else if (article.photo_post) {
                    <span class="icon">
                  <i class="fa-solid fa-camera"></i>
                </span>
                  } @else if (article.video_post) {
                    <span class="icon">
                  <i class="fa-solid fa-video"></i>
                  <span class="red-dot"></span>
                </span>
                  }
                  {{ article.title }}
                </h2>
                <p>{{ article.subtitle }}</p>
              </a>

            }
            <div class="advertisement-container">
              @for (article of priority5Articles; track article) {
                <a class="article priority-5"
                   [routerLink]="['/article', article.url_title, article[articlesProperty.id]]"
                   [attr.href]="'/article/' + article.url_title + '/' + article[articlesProperty.id]">
<!--                     [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">-->
                  <!-- Article content for priority 4 -->
                  <div class="category">
                    <p>{{ article.category.name }}</p>
                  </div>
                  <div *ngIf="article.image_list && article.image_list.length > 0" class="media-container">
                    @if (!isVideo(article.image_list[0].url)) {
                      <img ngSrc="{{article.image_list[0].url}}"
                           alt="{{ priority5Articles[0].subtitle }}"
                           fetchpriority="high"
                           fill=""
                           style="position: relative"
                           priority=""
                      >
                    } @else {
                      <video
                        width="250px"
                        height="140px"
                        [src]="article.image_list[0].url"
                        controls
                        muted
                        autoplay
                        preload="auto"
                        loop
                      >
                      </video>
                    }
                  </div>
                  <h2>
                    @if (article.text_post) {
                      <span class="icon">
                        <i class="fa-solid fa-align-justify"></i>
                      </span>
                    } @else if (article.photo_post) {
                      <span class="icon">
                        <i class="fa-solid fa-camera"></i>
                      </span>
                    } @else if (article.video_post) {
                      <span class="icon">
                        <i class="fa-solid fa-video"></i>
                        <span class="red-dot"></span>
                      </span>
                    }
                    {{ article.title }}
                  </h2>
                </a>
              }
            </div>
            @if (windowWidth < 1025) {
<!--              <div id='hayat_ba_450x125_left_rectangle_2_responsive' class="display-flex" style="min-height: 125px;">-->
<!--                <script type="text/javascript">-->
<!--                  googletag.cmd.push(function () {-->
<!--                    googletag.display('hayat_ba_450x125_left_rectangle_2_responsive');-->
<!--                  });-->
<!--                </script>-->
<!--              </div>-->
            }
          </div>
        </div>
      </div>
    </div>

  } @else {
    <div class="breaking">

      <a *ngFor="let article of priority1Articles" class="article priority-1"
           [routerLink]="['/article', article.url_title, article[articlesProperty.id]]"
           [attr.href]="'/article/' + article.url_title + '/' + article[articlesProperty.id]">
<!--           [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">-->
        <!-- Article content for priority 2 -->
        <div class="category">
          <h5>{{ article.category.name }}</h5>
        </div>
        <div *ngIf="article.image_list && article.image_list.length > 0">
          <img *ngIf="!isVideo(article.image_list[0].url); else video"
               ngSrc="{{article.image_list[0].url}}"
               alt="{{ priority1Articles[0].subtitle }}"
               fetchpriority="high"
               fill
               priority>
          <ng-template #video>
            <video
              #videoPriority2
              width="575px"
              height="323px"
              [src]="article.image_list[0].url"
              controls
              muted
              autoplay
              preload="auto"
              loop
            >
            </video>
          </ng-template>
        </div>
        <h1>
          @if (article.text_post) {
            <span class="icon">
                  <i class="fa-solid fa-align-justify"></i>
                </span>
          } @else if (article.photo_post) {
            <span class="icon">
                  <i class="fa-solid fa-camera"></i>
                </span>
          } @else if (article.video_post) {
            <span class="icon">
                  <i class="fa-solid fa-video"></i>
                  <span class="red-dot"></span>
                </span>
          }
          {{ article.title }}
        </h1>
        <p>{{ article.subtitle }}</p>
      </a>
    </div>
  }
} @else {
  <div class="highlight">
    <!-- Left Column -->
    <div class="column first">
      <div class="article priority-2">
        <div class="skeleton-2-title">
          <div class="skeleton-title ">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
            <div class="title-lines shimmer-effect"></div>
          </div>
          <div class="skeleton-title">
            <div class="title-lines shimmer-effect"></div>
          </div>
          <div class="skeleton-title">
            <div class="title-lines shimmer-effect" style="width: 75% !important;"></div>
          </div>
        </div>
        <div class="skeleton-category">
          <div class="category-lines shimmer-effect"></div>
        </div>
        <div class="skeleton-media-container shimmer-effect">
        </div>
        <div class="skeleton-subtitle">
          <div class="subtitle-lines shimmer-effect"></div>
        </div>
        <div class="skeleton-subtitle">
          <div class="subtitle-lines shimmer-effect" style="width: 75% !important;"></div>
        </div>
      </div>

      <div
        class="skeleton"
        style="min-height: 125px; width: 100%"
      >
      </div>
    </div>

    <!-- Center Column -->
    <div class="right">
      <div class="top">
        <div class="article priority-6">
          <div class="skeleton-info">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
          </div>
          <!-- Article content for priority 6 -->
          <div class="skeleton-6-title">
            <span class="skeleton-icon">
              <i class="fa-solid fa-align-justify"></i>
            </span>
            <div class="title-6-lines shimmer-effect"></div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="column second">
          <div class="article priority-4">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-4-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-4-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-4-title">
                <div class="title-4-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines" shimmer-effect style="width: 75% !important;"></div>
            </div>
          </div>
          <div class="article priority-4">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-4-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-4-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-4-title">
                <div class="title-4-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-4-subtitle">
              <div class="subtitle-4-lines shimmer-effect" style="width: 75% !important;"></div>
            </div>
          </div>
        </div>
        <div class="column third">
          <div class="article priority-3">
            <div class="skeleton-category">
              <div class="category-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-media-container shimmer-effect">

            </div>
            <div style="border-bottom: 5px">
              <div class="skeleton-3-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-3-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-3-title">
                <div class="title-3-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
            <div class="skeleton-3-subtitle">
              <div class="subtitle-3-lines shimmer-effect"></div>
            </div>
            <div class="skeleton-3-subtitle">
              <div class="subtitle-3-lines shimmer-effect" style="width: 75% !important;"></div>
            </div>
          </div>

          <div class="advertisement-container">
            <div class="article priority-5">
              <div class="skeleton-category">
                <div class="category-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-media-container shimmer-effect">

              </div>
              <div class="skeleton-5-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>

            <div class="article priority-5">
              <div class="skeleton-category">
                <div class="category-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-media-container shimmer-effect">

              </div>
              <div class="skeleton-5-title">
                <span class="skeleton-icon">
                    <i class="fa-solid fa-align-justify"></i>
                </span>
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect"></div>
              </div>
              <div class="skeleton-5-title">
                <div class="title-5-lines shimmer-effect" style="width: 75% !important;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
