import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap, Observable, of, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import {Categories} from "../models/categories.model";
import {Router} from "@angular/router";
import {Route} from "../constants/route.constants";
import {Articles} from "../models/articles.model";
import {catchError} from "rxjs/operators";
import {
  ArticlePreviewDialogComponent
} from "../components/upload/article-preview-dialog/article-preview-dialog.component";

@Injectable({providedIn: 'root'})
export class CategoriesService {

  private _category!: Categories;

 _categories: Categories[] = [];

 private _prevCategory: Categories | null = null;

 private _page: number = 1;

  private readonly baseUrl: string = `${environment.backendUrl}/categories`;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getCategories(): Observable<Categories[]> {
    if(this._categories.length > 0){
      return of(this._categories)
    }
    return this.http.get<Categories[]>(`${this.baseUrl}`);
  }

  public getCategoryByUrl(url: string): Observable<Categories> {
    return this.http.get<Categories>(`${this.baseUrl}/${url}`);
  }

  public create(category: Categories): Observable<Categories> {
    return this.http.post<Categories>(`${this.baseUrl}`, category);
  }

  public updateCategories(categories: Categories[]): Observable<Categories[]>  {
    return this.http.put<Categories[]>(`${this.baseUrl}`, categories);
  }

  public updateCategory(id: string, category: Categories): Observable<Categories>  {
    return this.http.put<Categories>(`${this.baseUrl}/${id}`, category);
  }

  public delete(category: any): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${category._id}`, {body: category});
  }


  get categories(): Categories[] {
    if (this._categories.length === 0) {
      this.getCategories().subscribe(
        (response) => {
          this._categories = response;
        },
        (error) => {
          console.error('Failed to get categories:', error);
        })
    }
    return this._categories
  }
  set categories(value: Categories[]) {
    this._categories = value;
  }







  get gCategories(): Categories[] {
    if (this._categories.length === 0) {
      this.getCategories().subscribe(
        (response) => {
          this._categories = response;
        },
        (error) => {
          console.error('Failed to get categories:', error);
        })
    }
    return this._categories
  }

  public addCategory(value: Categories) {
    this._categories.push(value);
  }



  /*get category(): Categories {
    return this._category;
  }*/

  set category(value: Categories) {
    this._category = value;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

}
