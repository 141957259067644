import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Articles} from "../models/articles.model";


@Injectable({providedIn: 'root'})
export class SocialMediaService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getInstagramEmbed(url: string) {
    return this.http.get(`https://api.instagram.com/oembed/?url=${encodeURIComponent(url)}`);
  }

  getTiktokEmbed(url: string): Observable<any> {
    return this.http.get(`https://www.tiktok.com/oembed?url=${url}`);
  }
  getXEmbed(url: string): Observable<any> {
    return this.http.get(`https://publish.twitter.com/oembed?url=${url}`);
  }

}
