import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {ShowService} from "../../../../../services/show.services";
import {NgClass} from "@angular/common";
import {Episode} from "../../../../../models/episode.model";

@Component({
  selector: 'app-show',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    NgClass
  ],
  templateUrl: './show.component.html',
  styleUrl: './show.component.css'
})
export class ShowComponent implements OnInit{

  isChat!: boolean | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private showService: ShowService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    const chat = this.router.url.split('/').pop();
    if (chat === 'chat'){
      this.isChat = true
    }
  }


  selection(selection: string) {
    this.isChat = selection === 'chat';
    this.cdr.detectChanges()
  }

}
