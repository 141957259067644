import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter} from "rxjs";
import {Categories} from "../../models/categories.model";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {AuthService} from "../../services/auth.service";
import {CategoriesService} from "../../services/categories.services";
import {UserService} from "../../services/user.service";
import {ReturnUrlService} from "../../services/return-url.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-basic-header',
  templateUrl: './basic-header.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgIf
  ],
  styleUrls: ['./basic-header.component.css']
})
export class BasicHeaderComponent implements OnInit {
  public top: number | undefined;

  public categoriesProperty = CategoriesProperty;

  public categories!: Categories[];

  public selectedRoute: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private returnUrlService: ReturnUrlService,
    public categoriesService: CategoriesService,
    public userService: UserService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.selectedRoute = ((event as NavigationEnd).url)?.substring(1);
    });
  }

  ngOnInit(): void {

  }

  public tokenExists(): boolean {
    return this.authService.getToken() != null;
  }

  public async logoutUser(): Promise<void> {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    await this.userService.logoutProfile();
    this.router.navigateByUrl(returnUrl || '/');
  }

}
