<div class="surveys">
  <div class="page-title">
    <h1>Ankete</h1>
  </div>
  <div class="top">
    <button (click)="openCreateSurveyDialog()">Create Survey</button>

    <div class="filters">
      <button (click)="filterSurveys('active')"
              [disabled]="selectedFilter == 'active'">
        Aktivni
      </button>
      <button (click)="filterSurveys('expired')"
              [disabled]="selectedFilter == 'expired'">
        Istekli
      </button>
    </div>
  </div>
  <!-- List of Survey Questions -->
  <div class="survey-visual" (scroll)="onScroll()">
    <div class="left">
      <div class="survey-question" *ngFor="let survey of displaySurveys" (click)="selectSurvey(survey)">
        <i *ngIf="survey.surveyType == 'trivia'" class='fas fa-gift'></i>
        <i *ngIf="survey.surveyType == 'survey'" class='fas fa-poll-h'></i>
        {{ survey.question }}
      </div>
    </div>
    <div class="right">
      <div *ngIf="this.selectedSurvey !== undefined">
        <p>{{selectedSurvey.question}}</p>
        <div *ngFor="let answer of selectedSurvey.answerOptions; let i = index" class="answer-option">
          <div class="answer-container">
            <p class="answer">{{answer.answer}}</p>
          </div>
          <div class="percentage-bar-container">
            <div class="percentage-bar" [style.width.%]="calculatePercentage(answer.count, selectedSurvey.totalCount)"></div>
          </div>
          <div class="count-container">
            <p class="percentage-text">{{calculatePercentage(answer.count, selectedSurvey.totalCount) | number:'1.0-2'}}% ({{answer.count}})</p>
          </div>
        </div>
        <div><p>Total count: {{selectedSurvey.totalCount}}</p></div>
        <div class="winners">
          <button *ngIf="!selectedSurvey.winners && selectedSurvey.surveyType === 'trivia' && this.selectedFilter === 'expired'" (click)="generateWinner()">
            Generisi pobjenika
          </button>
          <div class="winner-data" *ngIf="selectedSurvey.winners">
            <p style="font-weight: bold">DOBITNIK POKLONA:</p>
            @for(winner of selectedSurvey.winners; track selectedSurvey.winners; let i = $index){
              <div class="winner">
                <p>{{i + 1}} {{winner.first_name}} {{winner.last_name}}</p>
                <p>{{winner.email}}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
