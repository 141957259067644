import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  model,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter, Observable, Subscription, window} from "rxjs";
import {Categories} from "../../models/categories.model";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {AuthService} from "../../services/auth.service";
import {CategoriesService} from "../../services/categories.services";
import {UserService} from "../../services/user.service";
import {ReturnUrlService} from "../../services/return-url.service";
import {User} from "../../models/user.model";
import {AsyncPipe, isPlatformBrowser, NgForOf, NgIf, NgSwitch} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {Route} from "../../constants/route.constants";
import {response} from "express";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgSwitch,
    MatIconModule,
    MatButtonModule,
    NgForOf,
    AsyncPipe,
    MatMenuModule
  ],
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, AfterViewInit{
  sidebarOpen: boolean = false;

  doesTokenExist: boolean = false;
  profile: User | undefined


  public top: number = 300;

  public categoriesProperty = CategoriesProperty;

  public categories: Categories[] | undefined;

  public selectedRoute: string = '';

  public threeSeconds = false;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private router: Router,
    public authService: AuthService,
    private returnUrlService: ReturnUrlService,
    public categoriesService: CategoriesService,
    public userService: UserService,
    private renderer: Renderer2,

  ) {

  }

  private routerSubscription: Subscription | undefined;
  private scrollEnabled: boolean = false; // New flag to control when scroll changes start

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformID)) {
      this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.resetHeaderHeight();
        }
      });
      setTimeout(() => {
        this.scrollEnabled = true;
      }, 3000);

      this.categoriesService.getCategories().subscribe(
        (response: Categories[]) => {
          this.categories = response;
        }
      );
      await this.userService.getUser()
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.scrollEnabled) {
      // @ts-ignore
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.top = Math.max(300 - scrollTop, 0);
    }
  }

  resetHeaderHeight() {
    this.top = 300; // Reset the height to 300px on route change
    this.scrollEnabled = false; // Disable scroll changes during the initial delay
    setTimeout(() => {
      this.scrollEnabled = true;
    }, 3000); // Re-enable scroll changes after 3 seconds
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1723029390981-0'); });`;
      scriptElement1.text = scriptContent;
      this.renderer.setAttribute(scriptElement1, 'id', 'google-tag-script-header');
      this.renderer.appendChild(document.body, scriptElement1);
      // this.loadGoogleTagScript();
    }
  }

  private isScriptLoaded = false;

  loadGoogleTagScript() {
    if (this.isScriptLoaded) {
      return; // Exit if the script has already been loaded
    }

    const existingScript = document.getElementById('google-tag-script-header');
    if (existingScript) {
      this.isScriptLoaded = true;
      return; // Exit if the script is already in the DOM
    }

    const script = document.createElement('script');
    script.id = 'google-tag-script-header';
    script.type = 'text/javascript';
    script.innerHTML = `
       googletag.cmd.push(function() {
         googletag.display('div-gpt-ad-1723029390981-0');
       });
      `;
    document.getElementById('div-gpt-ad-1723029390981-0')?.appendChild(script);
    this.isScriptLoaded = true; // Mark the script as loaded
  }

  updateTokenExists(): boolean {
    return this.authService.getToken() !== null;
  }


  public async logoutUser(): Promise<void> {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    await this.userService.logoutProfile();
    this.router.navigateByUrl(returnUrl || '/');
  }

  public setCategory(category: Categories): void {
    this.categoriesService.category = category;
    this.categoriesService.page = 1;
  }


  public customSetCategory(): void {
    var model: Categories = {
      [CategoriesProperty.name]: "Najnovije",
      [CategoriesProperty.category_url]: "najnovije",
      [CategoriesProperty.order_number]: 0,
      [CategoriesProperty.size]: 0,
      [CategoriesProperty.color_code]: '#000',
    };

    this.categoriesService.category = model;
    this.categoriesService.page = 1;
  }


  public checkUrlCategory(): void {
    var selected: string = this.selectedRoute
    /*if (selected.substring(0))*/
  }

  redirectToLogin(): void {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    this.router.navigate(['/login']);
  }

  isLogedIn(): boolean{
    return this.authService.isLoggedIn();
  }



  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }
  public customSetCategorySidebar(): void {
    var model: Categories = {
      [CategoriesProperty.name]: "Najnovije",
      [CategoriesProperty.category_url]: "najnovije",
      [CategoriesProperty.order_number]: 0,
      [CategoriesProperty.size]: 0,
      [CategoriesProperty.color_code]: '#000',
    };

    this.categoriesService.category = model;
    this.categoriesService.page = 1;
    this.toggleSidebar()
  }

  public setCategorySidebar(category: Categories): void {
    this.categoriesService.category = category;
    this.categoriesService.page = 1;
    this.toggleSidebar()
  }

    protected readonly Router = Router;
  protected readonly Route = Route;
}
