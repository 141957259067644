import {ChangeDetectorRef, Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {Guest} from "../../../../models/guest.model";
import {GuestProperty} from "../../../../models/guest-property.enum";
import {GuestsService} from "../../../../services/guests.services";
import {ImagesService} from "../../../../services/images.service";
import {ImageProperty} from "../../../../models/image-property.enum";

@Component({
  selector: 'app-crate-guest-dialog',
  standalone: true,
  imports: [
    MatCheckbox,
    MatIcon,
    ReactiveFormsModule
  ],
  templateUrl: './crate-guest-dialog.component.html',
  styleUrl: './crate-guest-dialog.component.css'
})
export class CrateGuestDialogComponent {
  guestForm: FormGroup;
  @Output() guestCreated = new EventEmitter<Guest>();
  @Output() guestUpdated = new EventEmitter<Guest>();

  newLinkControl: FormControl = new FormControl('', Validators.required);
  selectedFiles?: FileList;
  imageLinks: any[] = [];


  constructor(
    public dialogRef: MatDialogRef<CrateGuestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public guest: Guest,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private guestsService: GuestsService,
    private imagesService: ImagesService,
    private cdr: ChangeDetectorRef,


  ) {
    this.guestForm = this.formBuilder.group({
      _id: [this.guest?.[GuestProperty.id] || ''],
      name: [this.guest?.[GuestProperty.name] || '', Validators.required],
      password: [''],
      px60: [this.guest?.[GuestProperty.image_60px] || ''],
      px300: [this.guest?.[GuestProperty.image_300px] || ''],
      email: [this.guest?.[GuestProperty.email] || ''],
    });
  }

  onSubmit() {
    if(this.guest){
      if (this.guestForm.valid) {
        // @ts-ignore
        this.guestsService.update(this.guest?._id, this.guestForm.value).subscribe(result => {
          this.guest = { ...this.guest, ...this.guestForm.value };
          this.guestUpdated.emit(this.guest); // Emit the event with the created editor
          this.dialogRef.close();
        })
      }
    }
    else {
      if (this.guestForm.valid) {
        const formData = this.guestForm.value;
        // Send the form data to the backend
        this.guestsService.create(formData).subscribe(
          (result:any) => {
            this.guestCreated.emit(result.chair); // Emit the event with the created editor
            this.dialogRef.close();
          },
          (error) => {
            console.error('Error creating survey:', error);
            // Handle the error as needed
          }
        );
      }
    }
  }
  showPassword = false;

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const passwordInput = this.guestForm.get('password');

    if (passwordInput instanceof FormControl) {
      const inputElement = document.getElementById('password') as HTMLInputElement;

      if (inputElement) {
        inputElement.type = this.showPassword ? 'text' : 'password';
      }
    }
  }



  selectFiles(event: Event): void {
    // @ts-ignore
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {

        if (this.selectedFiles[i].size > 5 * 1024 * 1024){
          console.error(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`);
          this.snackBar.open(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`, 'Close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
          });
        }
        else {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }
  }

  upload(idx: number, file: File): void {
    if (file) {

      const username = this.guest[GuestProperty.name].replace(/[^a-zA-Z]/g, '');
      const newFileName = this.guest[GuestProperty.id]?.toString() + '_' + username;
      const renamedFile = new File([file], newFileName, {type: file.type});

      this.imagesService.upload(renamedFile, 'profile').subscribe(
        (response) => {
          this.guestForm.get('px60')?.setValue(response.px60)
          this.guestForm.get('px300')?.setValue(response.px300)
          this.guest[GuestProperty.image_60px] = response.px60
          this.guest[GuestProperty.image_300px] = response.px300
        },
        (error) => {
          // Handle error, e.g., display an error message
          console.error('Error creating article:', error);
        });
    }
  }

  protected readonly GuestProperty = GuestProperty;
  protected readonly ImageProperty = ImageProperty;
}
