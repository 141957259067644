import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import {Show} from "../models/show.model";
import {Articles} from "../models/articles.model";
import {Episode} from "../models/episode.model";


@Injectable({providedIn: 'root'})
export class QuestionService {

  private readonly baseUrl: string = `${environment.backendUrl}/questions`;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getShows(): Observable<Show[]> {
    return this.http.get<Show[]>(`${this.baseUrl}`);
  }

  public create(question: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, question);
  }

  public update(data: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, data);
  }

  public delete(question: string | undefined): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/`, {body: question});
  }

}
