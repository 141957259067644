<div class="uslovi">
  <h1>
    Opšti uslovi korištenja
  </h1>
  <p>

    Potvrđujem da imam 18 godina ili više i da sam pročitao/la i da prihvatam Opšte uslove korištenja Hayat d.o.o. Sarajevo. U skladu sa odredbom člana 5. Zakona o zaštiti ličnih/osobnih podataka („Sl. glasnik BiH“ broj 49/06), ovim izjavljujem i aceptiranjem potvrđujem svoju saglasnost da Hayat d.o.o. Sarajevo poduzima sve radnje vezano za obradu mojih ličnih/osobnih podataka i to: ime, prezime, email, a sve u svrhu ućešća za uručivanje poklona.

  </p>
  <p>

    Izjavljujem i aceptiranjem potvrđujem da sam od strane Hayat d.o.o. Sarajevo obaviješten/a o razlozima prikupljanja podataka i davanja ove saglasnosti u skladu sačlanom 22. Zakona o zaštiti ličnih podataka („Sl. glasnik BiH“ broj 49/06). Saglasnost za obradu gore navedenih ličnih/osobnih podataka se daje/odnosi na period do završetka odabira osobe kojoj će biti uručen poklon.

  </p>
  <p>

    Ova Suglasnost se daje u naprijed navedenu svrhu i u druge svrhe se ne može koristiti, a vrijedi do okončanja postupka odabira osobe kojoj će biti uručen poklon. Pod punom materijalnom i krivičnom odgovornošću izjavljujem da su svi upisani podaci istiniti.

  </p>
</div>
