export enum SurveysProperty {
  id = '_id',
  surveyType = 'surveyType',
  question = 'question',
  answerOptions = 'answerOptions',
  correctAnswer = 'correctAnswer',
  deadline = 'deadline',
  winners = 'winners',
  deadlineExpired = 'deadlineExpired',
  totalCount = 'totalCount',
}
