<div class="about-hayat">
  <h1>Sve o Hayat Media</h1>
  <div class="sections">
    <a href="https://www.hayat.ba/gledaj.php">GLEDAJ Hayat (Web TV UŽIVO)</a>
    <a href="https://www.hayat.ba/play.php">Hayat PLAY videoteka (S-VOD)</a>
    <button [routerLink]="'servis-gledaj-hayat'">
      Sve što trebaš znati o servisima Hayat PLAY i GLEDAJ Hayat
    </button>
    <button [routerLink]="'servis-gledaj-hayat-uputstvo'">
      Uputstvo za korištenje servisa GLEDAJ Hayat/Hayat PLAY
    </button>
    <button [routerLink]="'info-hayat-tv'">
      Info o Hayat TV kanalima
    </button>
    <a href="https://play.google.com/store/apps/details?id=com.segvic.hayat">Android aplikacija</a>
    <a href="https://apps.apple.com/us/app/hayat/id1003719038">iOS aplikacija</a>
    <button [routerLink]="'info-smart-tv'">
      Kako da gledate sve Hayatove sadržaje i na TV uređajima Android? Ovo su detaljne upute
    </button>
  </div>
</div>
