import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Categories} from "../../../models/categories.model";
import {CategoriesService} from "../../../services/categories.services";
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT, isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {filter} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {Articles} from "../../../models/articles.model";
import {HomeCategoryOneComponent} from "../home-category/home-category-one/home-category-one.component";
import {HomeCategoryTwoComponent} from "../home-category/home-category-two/home-category-two.component";
import {HomeCategoryThreeComponent} from "../home-category/home-category-three/home-category-three.component";
import {HighlightComponent} from "../highlight/highlight.component";
import {HomeTagsComponent} from "./home-tags/home-tags.component";
import {ArticlesService} from "../../../services/articles.service";
import {response} from "express";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [
    HomeCategoryOneComponent,
    HomeCategoryTwoComponent,
    HomeCategoryThreeComponent,
    HighlightComponent,
    NgForOf,
    NgIf,
    HomeTagsComponent
  ],
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public categoriesProperty = CategoriesProperty;

  public categories: Categories[] = [];
  public articles: Articles[][] = [];
  public highlight: Articles[] = [];

  articleSubscription: any;
  highlightSubscription: any;


  constructor(
    // @ts-ignore
    @Inject(DOCUMENT) private doc,
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer2: Renderer2,
    private categoriesService: CategoriesService,
    private articlesService: ArticlesService,
    private meta: Meta,
    private title: Title,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0); // Scroll to the top on route navigation
        }
      });
      this.categoriesService.getCategories().subscribe(result =>{
        this.categories = result
      })
      this.articlesService.getArticlesMainPage().subscribe(response => {
        this.articles = response
      })
    }

  }

}
