import {Component, OnInit} from '@angular/core';
import {GuestProperty} from "../../../models/guest-property.enum";
import {GuestsService} from "../../../services/guests.services";
import {Guest} from "../../../models/guest.model";
import {NgForOf} from "@angular/common";
import {Chair} from "../../../models/chair.model";
import {CreateChairDialogComponent} from "../administrator-chairs/create-chair-dialog/create-chair-dialog.component";
import {FormBuilder} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ChairService} from "../../../services/chair.service";
import {all} from "axios";
import {ChairProperty} from "../../../models/chair-property.enum";
import {CrateGuestDialogComponent} from "./crate-guest-dialog/crate-guest-dialog.component";

@Component({
  selector: 'app-administrator-guests',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './administrator-guests.component.html',
  styleUrl: './administrator-guests.component.css'
})
export class AdministratorGuestsComponent implements OnInit{

  allGuests: Guest[] | undefined;


  constructor(
    private guestsService: GuestsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private chairService: ChairService,
  ) {
  }
  ngOnInit(): void {
    this.guestsService.getGuests().subscribe(guests => {
      this.allGuests = guests;

    });
  }


  openCreateChairDialog(guest?: Guest) {
    const dialogRef = this.dialog.open(CrateGuestDialogComponent, {
      width: '500px',
      data: guest,
    });

    dialogRef.componentInstance.guestCreated.subscribe((createdGuest: Guest) => {
      // Update this.editors if an editor is created successfully
      if (createdGuest) {
        this.allGuests?.push(createdGuest);
        // Show snackbar with successful message
        this.snackBar.open("Editor created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.componentInstance.guestUpdated.subscribe((updatedGuest: Guest) => {
      if (updatedGuest) {
        // If an existing guest is updated, find and update it in allGuests
        const existingGuestIndex = this.allGuests?.findIndex(g => g._id === updatedGuest._id);
        if (this.allGuests && existingGuestIndex !== undefined && existingGuestIndex !== -1) {
          this.allGuests[existingGuestIndex] = updatedGuest;
        }
        this.snackBar.open("Guest updated successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });


    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /*deleteEditor(chairId: string): void{
    this.chairService.delete(chairId).subscribe(
      (result) => {
        const index = this.allGuests?.findIndex(chair => chair[ChairProperty.id] === chairId);

        if (index !== -1) {
          this.allGuests?.splice(index, 1); // Remove the editor from the array
        }
        this.snackBar.open("Profil uredno izbrisan", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    );
  }*/


}
