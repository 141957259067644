import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Tag} from "../models/tag.model";
import {Guest} from "../models/guest.model";
import {Chair} from "../models/chair.model";
import {catchError} from "rxjs/operators";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {User} from "../models/user.model";
import {ReturnUrlService} from "./return-url.service";


@Injectable({providedIn: 'root'})
export class GuestsService {

  private readonly baseUrl: string = `${environment.backendUrl}/guests`;
  private profile: Guest | undefined;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private returnUrlService: ReturnUrlService,
  ) {
  }

  public getGuests(): Observable<Guest[]> {
    return this.http.get<Guest[]>(`${this.baseUrl}`);
  }

  public fetchGuest(): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    return this.http.get<User>(`${this.baseUrl}/whoami`, {headers}).toPromise();

  }


  private fetchingGuest = false;

  async getGuest(): Promise<any> {
    if (this.profile) {
      return this.profile;
    }

    // Check if there is an ongoing user retrieval operation
    if (this.fetchingGuest) {
      await this.waitForGuest();
      return this.profile;
    }

    const token = this.authService.getToken();
    if (token == null) {
      return undefined;
    }

    try {
      this.fetchingGuest = true;

      // Call the fetchUser function
      const user = await this.fetchGuest();

      // Cache the user once fetched
      this.profile = user;

      // Resolve the waiting promises
      if (this.waitResolve) {
        const resolve = this.waitResolve;
        this.waitResolve = null;
        resolve();
      }

      return user;
    } catch (error) {
      console.error('Error fetching user:', error);
      await this.logoutProfile()
      return undefined;
    } finally {
      this.fetchingGuest = false;
    }
  }

  private waitResolve: (() => void) | null = null;

  private async waitForGuest(): Promise<void> {
    // If this.waitResolve is null, the promise will keep waiting until it's resolved.
    return new Promise<void>((resolve) => {
      this.waitResolve = resolve;
    });
  }

  public create(tag: Guest): Observable<Guest> {
    return this.http.post<Guest>(`${this.baseUrl}`, tag);
  }


  public update(id: string | undefined, tag: Guest): Observable<Guest> {
    console.log(id)
    if(id !== undefined){
      return this.http.put<Guest>(`${this.baseUrl}/${id}`, tag);
    }
    return of()
  }

  public delete(id: string | undefined, tag: Tag): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {body: tag});
  }

  public async logoutProfile(): Promise<void> {
    const returnUrl = this.returnUrlService.getReturnUrl();
    this.authService.logout()
    this.profile = undefined
  }

}
