<div class="header">
  <div class="navigation" [class.gledaj]="selectedRoute === 'televizija/gledaj/live-tv' || selectedRoute === 'televizija/gledaj/videoteka' || selectedRoute === 'televizija/gledaj'">
    <div class="left">
      <button class="logo"
              aria-label="home"
              [routerLink]="'/'">
        <img src="assets/hayat-logo.webp" alt="Hayat Logo">
      </button>
      <button [class.selected]="selectedRoute === 'televizija/gledaj/live-tv' || selectedRoute === 'televizija/gledaj/videoteka' || selectedRoute === 'televizija/gledaj'"
              class="button"
              [routerLink]="'gledaj'">
        <span>Gledaj Hayat</span>
      </button>
      <button [class.selected]="selectedRoute === 'televizija/o-nama'"
              class="button"
              [routerLink]="'o-nama'">
        <span>Sve o Hayat Media</span>
      </button>
      <button [class.selected]="selectedRoute === 'televizija/shows'"
              class="button"
              [routerLink]="'shows'">
        <span>Emisije</span>
      </button>

    </div>
    <div class="right">
      <button class="account-button" mat-button [matMenuTriggerFor]="menu">
        <i class="fa fa-user"></i>
      </button>
      <mat-menu #menu="matMenu">
        @if(this.isLogedIn()){
          <button mat-menu-item (click)="logoutUser()">Logout</button>

        } @else {
          <button mat-menu-item (click)="redirectToLogin()">Login</button>
        }
      </mat-menu>
    </div>
  </div>

  <!--///////////////////////////Toolbar small devices//////////////////////////////-->

  <div class="toolbar" [class.gledaj]="selectedRoute === 'televizija/ledaj/live-tv' || selectedRoute === 'televizija/gledaj/videoteka' || selectedRoute === 'televizija/gledaj'">
    <button class="menu-button" type="button"
            mat-button (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
    <button class="logo"
            aria-label="home"
            [routerLink]="'/'">
      <img src="assets/hayat-logo.webp">
    </button>
    <div class="live">
      <button class="account-button" mat-button [matMenuTriggerFor]="menu">
        <i class="fa fa-user"></i>
      </button>
      <mat-menu #menu="matMenu">
        @if(this.isLogedIn()){
          <button mat-menu-item (click)="logoutUser()">Logout</button>

        } @else {
          <button mat-menu-item (click)="redirectToLogin()">Login</button>
        }
      </mat-menu>
    </div>
  </div>


  <div class="sidebar" [class.active]="sidebarOpen" [class.gledaj-sidebar]="selectedRoute === 'televizija/gledaj/live-tv' || selectedRoute === 'televizija/gledaj/videoteka' || selectedRoute === 'tv/gledaj'">
    <div class="categories-sidebar">
      <button [class.selected]="selectedRoute === '/gledaj'"
              class="button regular"
              [routerLink]="'gledaj'"
              (click)="toggleSidebar()"
      >
        <span>Gledaj Hayat</span>
      </button>
      <button [class.selected]="selectedRoute === '/o-nama'"
              class="button regular"
              [routerLink]="'o-nama'"
              (click)="toggleSidebar()"
      >
        <span>Sve o Hayat Media</span>
      </button>
      <button [class.selected]="selectedRoute === 'televizija/shows'"
              class="button"
              [routerLink]="'shows'"
              (click)="toggleSidebar()"
      >
        <span>Emisije</span>
      </button>

    </div>
  </div>

  <!--Toolbar small devices-->

</div>
