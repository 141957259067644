import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import {Articles} from "../../../../models/articles.model";
import {Categories} from "../../../../models/categories.model";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {Images} from "../../../../models/images.model";
import {isPlatformBrowser, NgIf, NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";
// import {AdsenseModule} from "ng2-adsense";

@Component({
  selector: 'app-home-category-one',
  templateUrl: './home-category-one.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgOptimizedImage,
    // AdsenseModule
  ],
  styleUrls: ['./home-category-one.component.css']
})

export class HomeCategoryOneComponent implements OnInit, AfterViewInit{

  @Input()
  articles!: Articles[];
  @Input()
  category: Categories | undefined;
  @Input()
  categoryNo: number | undefined;

  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  windowWidth: number = 0;

  public articlesProperty = ArticlesProperty;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private renderer: Renderer2,
  )
  { }

  public adID: string = ''
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.windowWidth = window.innerWidth;
    }
  }

  private isScriptLoaded = false;

  ngAfterViewInit() {
    // After the view is initialized, access all video elements and set them to muted
    this.videoElements.forEach((videoElement) => {
      videoElement.nativeElement.muted = true;
    });

    if (isPlatformBrowser(this.platformID)) {
      console.log('Loading Done')
      console.log('Category no ' + this.categoryNo)
      setTimeout(()=>{
        try{
          // @ts-ignore
          (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        }catch(e){
          console.error("error");
        }
      },2000);
      this.loadGoogleTagScript(this.categoryNo);
    }

  }

  loadGoogleTagScript(category: number | undefined) {
    if (this.isScriptLoaded) {
      return; // Exit if the script has already been loaded
    }

    const existingScript = document.getElementById('google-tag-script-category-' + category);
    if (existingScript) {
      console.log("This is exsisting script for category " + category)
      this.isScriptLoaded = true;
      return; // Exit if the script is already in the DOM
    }

    let elementId = '';
    if(category === 0){
      elementId = 'div-gpt-ad-1723029011553-0'; // The ID of the div you want to check
      console.log('Category 0')
    } else if (category === 3){
      elementId = 'div-gpt-ad-1723029151035-0'; // The ID of the div you want to check
      console.log('Category 3')
    } else if (category === 6){
      elementId = 'div-gpt-ad-1723029100252-0'; // The ID of the div you want to check
      console.log('Category 6')
    } else if (category === 9){
      elementId = 'div-gpt-ad-1723029295501-0'; // The ID of the div you want to check
      console.log('Category 9')
    }
    const checkElement = () => {
      const divElement = document.getElementById(elementId);
      if (divElement) {
        // Element is found, load the script
        console.log('Element found, loading script...');
        const scriptElement = this.renderer.createElement('script');
        scriptElement.text = `googletag.cmd.push(function() {googletag.display('${elementId}');});`;
        this.renderer.setAttribute(scriptElement, 'id', 'google-tag-script-category-' + category);
        this.renderer.appendChild(document.body, scriptElement);
      } else {
        // Element not found, wait for a second and check again
        console.log('Element for category ' + category + ' not found, retrying...');
        setTimeout(checkElement, 1000); // Retry after 1 second
      }
    };

    // Start the check
    checkElement();

    this.isScriptLoaded = true; // Mark the script as loaded
  }

  isVideo(end: string | undefined | null | Images): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

}
