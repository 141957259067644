<form [formGroup]="surveyForm" (ngSubmit)="onSubmit()" class="survey">
  <div class="toggle-buttons">
    <button
      type="button"
      class="toggle-button"
      [ngClass]="{'selected': surveyForm.value.surveyType === 'survey'}"
      (click)="selectSurvey()"
    >
      Anketa
    </button>
    <button
      type="button"
      class="toggle-button"
      [ngClass]="{'selected': surveyForm.value.surveyType === 'trivia'}"
      (click)="selectTrivia()"
    >
      Nagrada
    </button>
  </div>

  <label for="question">Pitanje:</label>
  <input type="text" formControlName="question" id="question">

  <div formArrayName="answerOptions">
    <label>Odgovori:</label>
    <div *ngFor="let answer of answerOptions.controls; let i = index" class="answer-option">
      <input [id]="'answer' + i" type="text" [formControlName]="i">
    </div>
    <button type="button" class="regular-button" (click)="addAnswerOption()">Add Answer Option</button>

  </div>

  @if (surveyForm.value.surveyType === 'trivia'){
    <label for="correctAnswer">Tacan odgovor:</label>
    <select formControlName="correctAnswer" id="correctAnswer">
      @for (answer of answerOptions.controls; track answer; let i = $index){
        <option [ngValue]="i">{{i + 1}}</option>
      }
<!--      <option [ngValue]="i" *ngFor="let answer of answerOptions.controls; let i = index">{{i + 1}}</option>-->
    </select>
  }

  <div class="deadline">
    <label for="deadline">Deadline:</label>
    <input type="datetime-local" formControlName="deadline" id="deadline" [min]="currentDateTime()">
  </div>


  <div class="buttons">

    <button type="submit">Create Survey</button>
  </div>
</form>
