<div class="surveys">
  <div class="page-title">
    <h1>Urednici</h1>
  </div>
  <div class="top">
    <button (click)="openCreateChairDialog()">Create User</button>
  </div>
  <!-- List of Survey Questions -->
  <div class="survey-visual">
    <div class="editor"
         *ngFor="let editor of chairs"
    >
      <div class="name">
        {{ editor.name }}
      </div>
      <div class="icons">
        @if(editor.isKing){
          <i class="fa-regular fa-chess-king"></i>
        }
        @if(editor.isAdmin){
          <i class="fa-solid fa-user-tie"></i>
        }
        @if(editor.isEditor){
          <i class="fa-regular fa-newspaper"></i>
        }
      </div>
      @if(chair.permissionCreateChairs){
        <div class="options">
          <button (click)="openCreateChairDialog(editor)"
                  class="edit-button"
                  color="primary">
            Edit
          </button>
          <button (click)="deleteEditor(editor)"
                  class="edit-button"
                  color="warn">
            Delete
          </button>
        </div>
      }
    </div>
  </div>
</div>
