import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {User} from "../../../../../../models/user.model";
import {FormsModule} from "@angular/forms";
import {formatDate, NgClass, NgForOf, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {UserService} from "../../../../../../services/user.service";
import {ShowService} from "../../../../../../services/show.services";
import {Show} from "../../../../../../models/show.model";
import {Router} from "@angular/router";
import {Episode} from "../../../../../../models/episode.model";
import {UserProperty} from "../../../../../../models/user-property.enum";
import {QuestionService} from "../../../../../../services/question.services";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ReturnUrlService} from "../../../../../../services/return-url.service";
import {EpisodeProperty} from "../../../../../../models/episode-property.enum";
import {ConfirmationDialogComponent} from "../../../../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Guest} from "../../../../../../models/guest.model";
import {GuestsService} from "../../../../../../services/guests.services";
import {GuestProperty} from "../../../../../../models/guest-property.enum";

@Component({
  selector: 'app-episode-questions',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIf,
    MatIcon,
    NgForOf
  ],
  templateUrl: './episode-questions.component.html',
  styleUrl: './episode-questions.component.css'
})
export class EpisodeQuestionsComponent implements OnInit {
  @ViewChild('chatContainer', { read: ElementRef }) private chatContainer!: ElementRef;

  containerElement!: HTMLElement;
  scrollHeightBefore = 0; // Define scrollHeightBefore as a global variable

  //User currently accessing discourse
  user: User | undefined;
  alreadyAsked: boolean = false;

  //show data
  show: Show | null | undefined;
  episode: Episode | null = null;
  order: string | undefined


  //questions: Questions;


  //Message data
  questions: any[] = []; //Messages from one channel
  newQuestion: string = ''; //New message text
  answer: string = ''; //New message text
  messagesLoading: boolean = true; //Are initial messages loading?

  //GUEST and ANSWER data
  guest: Guest | undefined;
  isGuestOfTheEpisode: boolean = false
  isInputDisabled: boolean = true;
  questionToAnswer: any | undefined


  showContextMenu = false;
  contextMenuX = 0;
  contextMenuY = 0;
  selectedMessage: any; // The message the user right-clicked on

  currentPage: number = 1; //for retrieving messages from database
  pageSize: number = 10; // Number of messages to retrieve per page

  date!: string;
  time!: string;

  timeRemaining: { days: number, hours: number, minutes: number, seconds: number } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  public deadline: Date | undefined;
  public hasDeadlinePassed: boolean = false;

  constructor(
    private userService: UserService,
    private guestsService: GuestsService,
    private showService: ShowService,
    private questionService: QuestionService,
    private returnUrlService: ReturnUrlService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {

  }

  async ngOnInit(): Promise<void> {
    //SHOW DATA
    this.show = this.showService.getStoredShow()
    /*setInterval(() => {
      this.updateCountdown();
    }, 1000);*/
    // @ts-ignore
    const segments = this.router.url.split('/');
    if (segments.length >= 2) {
      this.order = segments[segments.length - 2];
    } else {
      // Handle the case where there are not enough segments in the URL
      console.error('URL does not contain enough segments');
    }
    // @ts-ignore
    this.episode = this.show?.episodes.find((episode: any) => episode.order.toString() === this.order);
    //this.episode = this.showService.getEpisode()
    if (this.episode !== null) {
      this.showService.setEpisode(this.episode)
      this.hasDeadlinePassed = this.calculateDeadlinePassed()

    }
    this.questions = this.showService.getStoredEpisodeQuestions();
    this.messagesLoading = false

    //USER DATA
    await this.fetchUser();
  }

  formattedDate(originalDateInput: string): string {
    const originalDate = new Date(originalDateInput);

    var offset = new Date().getTimezoneOffset();
    var timezone = offset / 60;

    const date = formatDate(originalDate, 'dd.MM.yyyy', 'en-GB', 'UTC+' + timezone)
    const time = formatDate(originalDate, 'HH:mm', 'en-GB', 'UTC+' + timezone)


    return `${date} ${time}`;  }
  scrollTo(messageId: string | null): void {
    if (messageId)
    {
      this.scrollToMessage(messageId)
    }
    else {
      this.scrollToBottom()
    }
  }

  scrollToMessage(messageId: string): void {
    if (messageId) {
      const messageElement = document.querySelector(`[data-message-id="${messageId}"]`);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    } else {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  deleteUserMessage(value: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Da li ste sigurni da želite izbrisati pitanje?',
        message: 'Pitanje će biti izbrisano, i moći ćete postaviti novo.',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        /*this.questionService.delete(value.question).subscribe(result => {
          if (this.episode && this.episode.hasOwnProperty('questions')) {
            this.episode.questions.push(result.data);
          } else {
            if (this.episode) {
              this.episode.questions = this.episode.questions ?? [];
              this.episode.questions.push(result.data);
              this.questions = [result.data]
              this.alreadyAsked = true;
            }
          }
          this.newQuestion = ''; // Clear the input field
          this.scrollToBottom();
        });*/

        /*DELETE QUESTION*/
      }
    });
  }

  calculateDeadlinePassed(): boolean{
    const currentTimestamp = new Date().getTime(); // Get current timestamp in milliseconds
    // @ts-ignore
    const deadlineTimestamp = new Date(this.episode?.questionsDeadline).getTime(); // Convert deadline to timestamp
    return currentTimestamp > deadlineTimestamp;

  }
  hasUserAskedAlready(): boolean{
    // @ts-ignore
    const userQuestionExists = this.episode?.questions?.some(question =>
        // @ts-ignore
      question.user?.userId === this.user?.[UserProperty.id]
    );
    return !!userQuestionExists;

  }

  sendMessage(): void {
    const currentTimestamp = new Date().getTime(); // Get current timestamp in milliseconds
    // @ts-ignore
    const deadlineTimestamp = new Date(this.episode?.questionsDeadline).getTime(); // Convert deadline to timestamp
    if (currentTimestamp > deadlineTimestamp) {
      this.snackBar.open('Vrijeme za postavljanje pitanja je isteklo', 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['hayat-snackbar'],
      });
      return
    }

    if(this.hasUserAskedAlready()){
      this.snackBar.open('Vec ste postavili pitanje', 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['hayat-snackbar'],
      });
      return
    }

    // Create a new message object
    const newQuestion = {
      episode: this.order,
      show: this.show?._id,
      date_time: new Date(),
      userId: this.user?.[UserProperty.id],
      user: {
        userId: this.user?.[UserProperty.id],
        name: this.user?.[UserProperty.firstname] + ' ' + this.user?.[UserProperty.lastname]
      },
      question: this.newQuestion,
      approved: false,
    };

    this.questionService.create(newQuestion).subscribe(result => {
      if (this.episode && this.episode.hasOwnProperty('questions')) {
        this.episode.questions.push(result.data);
      } else {
        if (this.episode) {
          this.episode.questions = this.episode.questions ?? [];
          this.episode.questions.push(result.data);
          this.questions = [result.data]
          this.alreadyAsked = true;
        }
      }
      this.newQuestion = ''; // Clear the input field
      this.scrollToBottom();
    });
  }

  sendAnswer(): void {
    if(this.questionToAnswer){
      const newQuestion = {
        episode: this.order,
        show: this.show?._id,
        question: this.questionToAnswer.question,
        answer: this.answer,
        guestId: this.guest?.[GuestProperty.id],
        guest:{
          guestId: this.guest?.[GuestProperty.id],
          name: this.guest?.name,
          profilePicture: this.guest?.image_60px,
        }
      };
      this.questionService.update(newQuestion).subscribe(
        result =>{
          this.questionToAnswer.answer = this.answer
          this.questionToAnswer.guest = newQuestion.guest
          this.questionToAnswer.guestId = newQuestion.guestId
          this.answer = ''
          this.questionToAnswer = ''
          this.isInputDisabled = true
        }
      )
    }

    return;
    /*if(this.hasUserAskedAlready()){
      this.snackBar.open('Vec ste postavili pitanje', 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['hayat-snackbar'],
      });
      return
    }

    // Create a new message object
    const newQuestion = {
      episode: this.order,
      show: this.show?._id,
      date_time: new Date(),
      user: {
        // @ts-ignore
        userId: this.user?.[UserProperty.id],
        // @ts-ignore
        name: this.user?.[UserProperty.firstname] + ' ' + this.user?.[UserProperty.lastname]
      },
      question: this.newQuestion,
      approved: false,
    };

    this.questionService.create(newQuestion).subscribe(result => {
      if (this.episode && this.episode.hasOwnProperty('questions')) {
        this.episode.questions.push(result.data);
      } else {
        if (this.episode) {
          this.episode.questions = this.episode.questions ?? [];
          this.episode.questions.push(result.data);
          this.questions = [result.data]
          this.alreadyAsked = true;
        }
      }
      this.newQuestion = ''; // Clear the input field
      this.scrollToBottom();
    });*/

  }

  /*scrollToBottom(): void {
    setTimeout(() => {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    });
  }*/

  onMessageContextMenu(event: MouseEvent, message: any) {
    event.preventDefault(); // Prevent the default browser context menu
    this.selectedMessage = message;
    this.contextMenuX = event.clientX;
    this.contextMenuY = event.clientY;
    this.showContextMenu = true;
  }

  editMessage(message: any) {
    // Implement your edit logic here
    // For example, you could toggle a property to enable editing mode
    message.editing = true;
  }

  deleteMessage(message: any) {
    // Implement your delete logic here
    // For example, remove the message from the messages array
    const index = this.questions.indexOf(message);
    if (index !== -1) {
      this.questions.splice(index, 1);
    }

    // Hide the context menu after deleting
    this.showContextMenu = false;
  }

  isEditing(message: any): boolean {
    return message.editing || false; // Return true if the message is in editing mode
  }

  onBlur(event: any, message: any) {
    // Save the edited message when the input loses focus (blur event)
    message.text = event.target.textContent;
    message.editing = false; // Disable editing mode
  }



  async fetchUser() {
    const ret = await this.userService.getUser();
    if(ret?.role === 'guest')
    {
      this.guest = ret
      // @ts-ignore
      this.isGuestOfTheEpisode = this.episode?.guests?.find(guest => guest._id === ret.id) !== undefined;
    }
    else {
      this.user = ret
      this.alreadyAsked = this.hasUserAskedAlready()
      this.answer = ''
    }

    console.log(ret)
  }
  async fetchGuest() {
    this.guest = await this.guestsService.getGuest();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Get the element that was clicked
    const clickedElement = event.target as HTMLElement;

    // Check if the clicked element is within the context menu or its parent container
    const contextMenu = document.querySelector('.context-menu');
    const messagesContainer = document.querySelector('.messages-container');

    if (!contextMenu?.contains(clickedElement) && !messagesContainer?.contains(clickedElement)) {
      // If the clicked element is outside of the context menu and its parent container, hide the context menu
      this.showContextMenu = false;
    }
  }

  redirectToLogin(): void {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    this.router.navigate(['/login']);
  }


  calculateTimeRemaining(): void {
    const now = new Date();
    // @ts-ignore
    const timeDifference = this.deadline.getTime() - now.getTime();

    if (timeDifference <= 0) {
      // Deadline has passed
      this.timeRemaining = {days: 0, hours: 0, minutes: 0, seconds: 0};
    } else {
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      this.timeRemaining = {
        days: days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60
      };
    }
    this.cdr.detectChanges();

  }

  addAnswer(value: any | null): void {
    if(value !== null){
      this.answer = value.answer ?? '';
      this.questionToAnswer = value
      this.isInputDisabled = false
    }
    else {
      this.questionToAnswer = undefined
      this.isInputDisabled = true
    }
  }
  deleteAnswer(value: any | null): void {

    const newQuestion = {
      episode: this.order,
      show: this.show?._id,
      question: value.question,
      answer: undefined,
      guestId: undefined,
      guest: undefined,
    };
    this.questionService.update(newQuestion).subscribe(
      result =>{
        value.answer = undefined
        this.answer = ''
        this.questionToAnswer = ''
        this.isInputDisabled = true
      }
    )

    if(value !== null){
      this.answer = value.answer ?? '';
      this.questionToAnswer = value
      this.isInputDisabled = false
    }
    else {
      this.questionToAnswer = undefined
      this.isInputDisabled = true
    }

    if(this.questionToAnswer){

    }
  }

  editQuestion(value: any | null): void {
    if(value !== null){
      this.answer = value.answer ?? '';
      this.questionToAnswer = value
      this.isInputDisabled = false
    }
    else {
      this.questionToAnswer = undefined
      this.isInputDisabled = true
    }
  }

  updateCountdown(): void {
    this.calculateTimeRemaining();
  }

  formatNumber(value: number): string {
    // Ensure the number has two digits
    return value < 10 ? `0${value}` : `${value}`;
  }

  protected readonly GuestProperty = GuestProperty;
}
