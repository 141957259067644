<div class="about">
  @if (show) {
    <div class="info">
      <h2>{{show.name}}</h2>
      <h3>{{show.about}}</h3>
    </div>
  } @else {
    <div class="loading">
      <span class="loader"></span>
    </div>
  }
</div>
