<div class="show">
  <div class="top">
    <button (click)="toggleEdit()">
      {{ isEditing ? 'Save' : 'Edit' }}
    </button>
  </div>

  @if(isEditing){
    <form [formGroup]="showForm" class="info">
      <div class="left">
        <div class="wrapper name">
          <p>{{show.name}}</p>
          <input type="text" formControlName="topic" id="name">
        </div>
        <div class="wrapper about">
          <!--<textarea type="text" formControlName="about" id="about"></textarea>-->
        </div>
      </div>
      <div class="right">
        <div class="tags">
          <label>Gosti</label>
          <input
            type="text"
            name="userInput"
            [(ngModel)]="userInput"
            [ngModelOptions]="{standalone: true}"
            placeholder="Type to search tags"
            (keyup.enter)="onEnter()"
          />
          @if(allGuests.length || 0 > 0){
            <div class="tag-container">
              <div
                *ngFor="let tag of filterTags()"
                class="tag"
                (click)="addTag(tag)"
              >
                {{ tag.name }}
              </div>
            </div>
          }
          @if (selectedGuests?.length || 0 > 0){
            <div class="tag-container">
              <div
                *ngFor="let selectedGuest of selectedGuests"
                class="selected-tag"
                (click)="removeTag(selectedGuest)"
              >
                {{ selectedGuest.name }}
              </div>
            </div>
          }
        </div>
        <!--<mat-checkbox formControlName="hasChat">Chat</mat-checkbox>-->
      </div>
    </form>
  }
  @else{
    <div class="info">
      <div class="left">
        <div class="wrapper name">
          <p>{{show.name}}</p>
          <p>{{episode?.topic}}</p>
        </div>
        <div class="wrapper about">
          <!--<p> {{ show.about}}</p>-->
        </div>
      </div>
      <div class="right">
        <div class="tags">
          <label>Gosti</label>
          <!--<input
            type="text"
            name="userInput"
            [(ngModel)]="userInput"
            [ngModelOptions]="{standalone: true}"
            placeholder="Type to search tags"
            (keyup.enter)="onEnter()"
          />
          <div *ngIf="allTags.length > 0" class="tag-container">
            <div
              *ngFor="let tag of filterTags()"
              class="tag"
              (click)="addTag(tag)"
            >
              {{ tag.tag }}
            </div>
          </div>-->
          @if (selectedGuests?.length || 0 > 0){
            <div class="tag-container">
              <div
                *ngFor="let selectedGuest of selectedGuests"
                class="selected-tag"
                (click)="removeTag(selectedGuest)"
              >
                {{ selectedGuest.name }}
              </div>
            </div>
          }
        </div>
        <!--<mat-checkbox formControlName="hasChat" [disabled]="true">Chat</mat-checkbox>-->
      </div>
    </div>
  }

  <div class="episodes">
      @for (question of episode?.questions; track question){
        <div class="episode">
          <div class="name">
            {{ question.question }}
          </div>
          <div class="overlay">
            <button class="edit-button" (click)="approve(question)">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="remove-button" (click)="decline(question)">
              <i class="fa fa-remove"></i>
            </button>
          </div>
        </div>
      }

    <!--<div class="show add" (click)="openCreateEpisodeDialog()">
      <p>+</p>
    </div> -->
  </div>
</div>
