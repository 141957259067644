import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";
import {CategoriesService} from "../../../services/categories.services";
import {Categories} from "../../../models/categories.model";
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {AdministratorHeaderComponent} from "../administrator-header/administrator-header.component";
import {ChairService} from "../../../services/chair.service";

@Component({
  selector: 'app-administrator-main',
  templateUrl: './administrator-main.component.html',
  standalone: true,
  imports: [
    AdministratorHeaderComponent,
    RouterOutlet,
  ],
  styleUrls: ['./administrator-main.component.css']
})
export class AdministratorMainComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,

  ) {
  }

  ngOnInit(): void {

  }


}
