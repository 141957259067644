<div class="video-player">
  <div class="top">
    <!--<p>{{data.media.vod_name}}</p>-->
    <h1>USKORO</h1>

    <button (click)="closeDialog()">
      x
    </button>
  </div>
  <div class="video">
    <!--&lt;!&ndash;Nacin 1&ndash;&gt;
  <video controls>
    <source src="https://vod.hayat.ba/hls/Vijesti_24_2102.mp4/index.m3u8" type="application/x-mpegURL">
    Your browser does not support the video tag.
  </video>

  &lt;!&ndash;Nacin 2&ndash;&gt;
  <video controls>
    <source src="blob:https://www.hayat.ba/ab581f1d-640f-4e57-90c8-4124bc99d914" type="application/x-mpegURL">
    Your browser does not support the video tag.
  </video>

  &lt;!&ndash;Nacin 3&ndash;&gt;
  <video id="video" controls></video>
  <script>
    var video = document.getElementById('video');
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource('https://vod.hayat.ba/hls/Vijesti_24_2102.mp4/index.m3u8');
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = 'https://vod.hayat.ba/hls/Vijesti_24_2102.mp4/index.m3u8';
      video.addEventListener('loadedmetadata', function() {
        video.play();
      });
    }
  </script>-->
  </div>
</div>
