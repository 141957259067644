<div class="right">
  <div class="top">
    <button
      [ngClass]="{'selected': !isQA}"
      (click)="selection('')"
      [routerLink]="['.']"
    >
      Tema emisije
    </button>
    <button
      [ngClass]="{'selected': isQA}"
      (click)="selection('qa')"
      [routerLink]="'q&a'"
    >
      Pitanja i odgovori
    </button>
  </div>
  <router-outlet></router-outlet>
</div>
