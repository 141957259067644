<div class="shows">
  @for (show of shows; track show){
    <div class="show"
         [routerLink]="show._id"
    >
      <img src="assets/logo.png">
      <div class="name">
        {{ show.name }}
      </div>
    </div>
  }
</div>
