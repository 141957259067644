<div class="create">
  <form [formGroup]="showForm" (ngSubmit)="onSubmit()" class="episode">

    <div class="form-field">
      <label>Tema:</label>
      <input type="text" formControlName="topic">
    </div>

    <div class="form-field">
      <label>Broj emisije:</label>
      <input type="number" formControlName="order">
    </div>

    <div class="form-field">
      <label>O emisiji:</label>
      <textarea type="text" formControlName="about"></textarea>
    </div>

    <div class="qanda">
      <mat-checkbox formControlName="hasQuestions">Q&A</mat-checkbox>

      <div *ngIf="showForm.get('hasQuestions')?.value">
        <input type="datetime-local" formControlName="questionsDeadline" id="questionsDeadline">
      </div>
    </div>


    <div class="tags">
      <label>Gosti</label>
      <input
        type="text"
        name="userInput"
        [(ngModel)]="userInput"
        [ngModelOptions]="{standalone: true}"
        placeholder="Type to search tags"
        (keyup.enter)="onEnter()"
      />
      <div *ngIf="allGuests.length > 0" class="tag-container">
        <div
          *ngFor="let tag of filterTags()"
          class="tag"
          (click)="addGuest(tag)"
        >
          {{ tag.name }}
        </div>
      </div>
      <div *ngIf="selectedGuests.length > 0" class="tag-container">
        <div
          *ngFor="let selectedTag of selectedGuests"
          class="selected-tag"
          (click)="removeTag(selectedTag)"
        >
          {{ selectedTag.name }}
        </div>
      </div>
    </div>
    <div class="buttons">
      <button type="submit">
        {{ episode ? 'Save' : 'Create' }}
      </button>
    </div>
  </form>
</div>

