<div class="shows">
  @for (show of shows; track show){
    <div class="show" [routerLink]="show._id">
      <img src="assets/logo.png">
      <div class="name">
          {{ show.name }}
        </div>
      <div class="overlay">
        <button class="edit-button" (click)="openCreateShowDialog()">
          <i class="fa fa-pencil"></i>
        </button>
        <!--<button class="remove-button" (click)="deleteArticle(article)">
          <i class="fa fa-remove"></i>
        </button>-->
      </div>
    </div>
  }

  <div class="show add" (click)="openCreateShowDialog()">
    <p>+</p>
  </div>
</div>
