<form [formGroup]="editorForm" (ngSubmit)="onSubmit()" class="survey">
  <label for="email">Email:</label>
  <input type="text" formControlName="email" id="email">

  <div class="password-container">
    <label for="password">Password:</label>
    <input
      type="password"
      formControlName="password"
      id="password"
      placeholder="Enter your password"
    />
    <button class="passButton" type="button" (click)="togglePasswordVisibility()">
      <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </div>

  <label for="name">Ime i prezime:</label>
  <input type="text" formControlName="name" id="name">

  <div class="buttons">
    <button type="submit">Create Survey</button>
  </div>
</form>
