import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  standalone: true,
  imports: [
    MatIconModule
  ],
  styleUrls: ['./privacy-policy-dialog.component.css']
})
export class PrivacyPolicyDialogComponent {
  constructor(private dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
