<div class="search">
  @if(tag){
    <div class="tag">
      <p>#{{tag}}</p>
    </div>
  }

  <!--<div class="masonry-columns">
    <div class="masonry-column">
      <div class="masonry-item" *ngFor="let item of articles | masonry : numColumns : 3; index as i">
        {{ item.title }}
      </div>
    </div>
  </div>-->

  <div class="masonry-grid">
    <div class="photo-newest" [routerLink]="'../../article/' + item.url_title+ '/' + item[articlesProperty.id]" *ngFor="let item of articles" [style.height.px]="'fit-content'">
      <!-- Your item content here -->
      <img src="{{item.image_list?.[0]?.url}}">
      <h3>{{item[articlesProperty.title]}}</h3>
    </div>
  </div>



  <!--<ngx-masonry [ordered]="true">
    <div ngxMasonryItem class="article" *ngFor="let article of articles">
      <img src="{{article.image_list?.[0]?.url}}">
      <h2>{{article[articlesProperty.title]}}</h2>
    </div>
  </ngx-masonry>-->

  <!--<div class="articles">
    @for(article of articles; track article; let i = $index){
      @if(article[articlesProperty.photoPost]){

        <div class="article" [routerLink]="'../article/' + article.url_title+ '/' + article[articlesProperty.id]">
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </div>
      } @else if (article[articlesProperty.videoPost]){

        <div class=" article">
          <img *ngIf="!isVideo(article.image_list?.[0]?.url); else video"
               src="{{ article.image_list?.[0]?.url }}">
          <ng-template #video>
            <video
              width="100%"
              height="197px"
              [src]="article.image_list?.[0]?.url"
              controls
              appVideoPlayer
              loop
              preload="auto"
            ></video>
          </ng-template>
          <h2 [routerLink]="'../article/' + article[articlesProperty.url_title]+ '/' + article[articlesProperty.id]">
            <span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{article[articlesProperty.title]}}
          </h2>
        </div>
      } @else if (article[articlesProperty.textPost]){

        <div class=" article"  [routerLink]="'../article/' + article.url_title+ '/' + article[articlesProperty.id]">
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </div>
      }

      &lt;!&ndash;@if(i % 6 === 0 && i !== 0){
        <div id="hayat_ba_350x350_square_3_responsive" class="sidebar-height square-height">
          <script type="text/javascript">
            (function () {
              var randomiser = (Math.random() + 1).toString(36).substring(7);
              var adID = document.querySelector('#hayat_ba_350x350_square_3_responsive').id = "hayat_ba_350x350_square_3_responsive-" + randomiser;
              inView('#' + adID).once('enter', (function () {
                googletag.cmd.push(function () {
                  if (window.innerWidth >= 1280) {
                    googletag.defineSlot('/272140683/hayat.ba_350x350_square_3_desktop', [[336,280],[300,250],[336,336],[336,320],[320,320],[300,300],[320,250],[350,350]], adID).addService(googletag.pubads());
                  } else {
                    googletag.defineSlot('/272140683/hayat.ba_300x300_square_3_mobile', [[300,250],[300,300],[250,250]], adID).addService(googletag.pubads());
                  }
                  googletag.display(adID);
                  stpd.initializeAdUnit(adID);
                });
              }));
            })();
          </script>
        </div>
      }&ndash;&gt;
    }
  </div>
  <button *ngIf="page < 20" (click)="loadNextPage()" class="next-page">Ucitaj jos</button>
 -->
  <!--<div class="articles-small-devices">
    @for(article of articles; track article; let i = $index){
      @if(article[articlesProperty.photoPost]){
        <div class="photo-highlight article" [routerLink]="'../article/' + article.url_title+ '/' + article[articlesProperty.id]">
          &lt;!&ndash;<div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>&ndash;&gt;
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </div>
      } @else if (article[articlesProperty.videoPost]){
        <div class="video-newest article">
          &lt;!&ndash;<div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>&ndash;&gt;

          <img *ngIf="!isVideo(article.image_list?.[0]?.url); else video"
               src="{{ article.image_list?.[0]?.url }}">
          <ng-template #video>
            <video
              width="100%"
              height="197px"
              [src]="article.image_list?.[0]?.url"
              controls
              appVideoPlayer
              loop
              preload="auto"
            ></video>
          </ng-template>

          &lt;!&ndash;<video
            width="100%"
            height="197px"
            [src]="article.image_list?.[0]?.url"
            controls
            appVideoPlayer
            loop
            preload="auto"
          >
          </video>&ndash;&gt;
          <h2 [routerLink]="'../article/' + article[articlesProperty.url_title]+ '/' + article[articlesProperty.id]">
            <span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{article[articlesProperty.title]}}
          </h2>
        </div>
      } @else if (article[articlesProperty.textPost]){
        <div class="text-highlight article"  [routerLink]="'../article/' + article.url_title+ '/' + article[articlesProperty.id]">
          &lt;!&ndash;<div *ngIf="category.name === 'Najnovije'" class="category-sticker">
            <p>{{article.category.name}}</p>
          </div>&ndash;&gt;
          <img src="{{article.image_list?.[0]?.url}}">
          <h2>{{article[articlesProperty.title]}}</h2>
        </div>
      }
      @if(i % 6 === 0 && i !== 0){
        <div id="hayat_ba_350x350_square_3_responsive" class="sidebar-height square-height">
          <script type="text/javascript">
            (function () {
              var randomiser = (Math.random() + 1).toString(36).substring(7);
              var adID = document.querySelector('#hayat_ba_350x350_square_3_responsive').id = "hayat_ba_350x350_square_3_responsive-" + randomiser;
              inView('#' + adID).once('enter', (function () {
                googletag.cmd.push(function () {
                  if (window.innerWidth >= 1280) {
                    googletag.defineSlot('/272140683/hayat.ba_350x350_square_3_desktop', [[336,280],[300,250],[336,336],[336,320],[320,320],[300,300],[320,250],[350,350]], adID).addService(googletag.pubads());
                  } else {
                    googletag.defineSlot('/272140683/hayat.ba_300x300_square_3_mobile', [[300,250],[300,300],[250,250]], adID).addService(googletag.pubads());
                  }
                  googletag.display(adID);
                  stpd.initializeAdUnit(adID);
                });
              }));
            })();
          </script>
        </div>
      }
    }
  </div>-->
</div>
