import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {User} from "../../../../models/user.model";
import {Router, RouterLink} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {ReturnUrlService} from "../../../../services/return-url.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-user-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.css'
})
export class UserLoginComponent {
  pathToSignup:string = '/signup';

  form!: FormGroup;

  public user: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private returnUrlService: ReturnUrlService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  handleFormSubmit() {
    this.form!.markAllAsTouched();

    if (this.form!.valid) {
      this.userService.login({ ...this.form!.value }).subscribe(async () => {
          const userProfile = await this.userService.getUser();
          if (userProfile) {
            this.userService.login({ ...this.form!.value }).subscribe(async () => {
              const returnUrl = this.returnUrlService.getReturnUrl();
              this.router.navigateByUrl(returnUrl || '/');

              try {
                const localData = this.userService.getLocalData();
                await this.userService.syncLocalDataWithBackend(localData, userProfile).toPromise();
              } catch (error) {
                console.error('Error syncing local data with backend', error);
              }
            });
          } else {
            // Handle case where userProfile is undefined
            console.error('User profile not available.');
          }
        },
        (error) => {
          this.snackBar.open(error.error.message, 'Close', {
            duration: 4000, // Adjust the duration as needed
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['error-snackbar'],

          });
        })
    }
    else this.form!.setErrors({ unauthenticated: true });
  }

  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}

