import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReturnUrlService {
  private returnUrl: string | null = null;

  setReturnUrl(url: string): void {
    this.returnUrl = url;
  }

  getReturnUrl(): string | null {
    const url = this.returnUrl;
    this.returnUrl = null; // Clear the stored URL after retrieving it
    return url;
  }
}
