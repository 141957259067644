import { Component } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {User} from "../../../../models/user.model";
import {Router} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {Route} from "../../../../constants/route.constants";
import {ChairService} from "../../../../services/chair.service";
import {BasicHeaderComponent} from "../../../../common/basic-header/basic-header.component";

@Component({
  selector: 'app-administrator-login',
  templateUrl: './administrator-login.component.html',
  standalone: true,
  imports: [
    BasicHeaderComponent,
    ReactiveFormsModule
  ],
  styleUrls: ['./administrator-login.component.css']
})
export class AdministratorLoginComponent {

  form!: FormGroup;

  public user: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private chairService: ChairService,

  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      role: ['ch']
    });
  }

  handleFormSubmit() {
    this.form!.markAllAsTouched();

    if (this.form!.valid) {
      this.userService.login({ ...this.form!.value }).subscribe(() => {
        this.chairService.fetchChair().subscribe(()=> {
          this.router.navigateByUrl( '/chair');
        })
      })}
    else this.form!.setErrors({ unauthenticated: true });
  }

  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}
