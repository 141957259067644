import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SurveysService} from "../../../services/survey.service";
import {Surveys} from "../../../models/surveys.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {CreateSurveyDialogComponent} from "./create-survey-dialog/create-survey-dialog.component";
import {Subscription} from "rxjs";
import {SurveysProperty} from "../../../models/surveys-property.enum";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NumberOfWinnersDialogComponent} from "./number-of-winners-dialog/number-of-winners-dialog.component";


@Component({
  selector: 'app-administrator-survey',
  templateUrl: './administrator-survey.component.html',
  standalone: true,
  imports: [
    DecimalPipe,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./administrator-survey.component.css']
})
export class AdministratorSurveyComponent implements OnInit{
  surveyForm: FormGroup;
  displaySurveys!: Surveys[];
  activeSurveys!: Surveys[];
  expiredSurveys!: Surveys[];
  selectedFilter: string | undefined;
  selectedSurvey!: Surveys;

  private page = 1;
  private loading = false;
  private surveysSubscription: Subscription | undefined;

  @ViewChild("canvas", { static: true }) canvas!: ElementRef;



  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private surveysService: SurveysService,
  ) {
    this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1] // Default to -1 (for voting)
    });
  }


  ngOnInit(): void {
    this.selectedFilter = 'active'
    this.surveysService.getSurveys().subscribe(
      (surveys: Surveys[]) => {
        this.activeSurveys = surveys; // Assign the retrieved courses to the courses property
        this.displaySurveys = this.activeSurveys; // Assign the retrieved courses to the courses property
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting courses:', error);
      }
    );
  }

  calculatePercentage(count: number, totalCount: number): number {
    return totalCount > 0 ? (count / totalCount) * 100 : 0;
  }


  filterSurveys(filter: string) {
    if (filter === 'active') {
      if (!this.activeSurveys || this.activeSurveys.length === 0) {
        // If activeSurveys is empty, get the surveys through this.surveysService.getSurveys()
        this.surveysService.getSurveys().subscribe(
          (surveys) => {
            this.activeSurveys = surveys;
            this.displaySurveys = this.activeSurveys;
            // Perform any additional logic needed with this.activeSurveys
          },
          (error) => {
            console.error('Error fetching active surveys:', error);
          }
        );
      }
      else{
        this.displaySurveys = this.activeSurveys;
      }
      this.selectedFilter = 'active'
    }

    if (filter === 'expired') {
      if (!this.expiredSurveys || this.expiredSurveys.length === 0 || this.expiredSurveys.length === this.page * 15) {
        // If expiredSurveys is empty, get the surveys through this.surveysService.getExpiredSurveys()
        this.page = 1;
        this.surveysService.getExpiredSurveys(1).subscribe(
          (surveys: Surveys[]) => {
            this.expiredSurveys = surveys;
            this.displaySurveys = this.expiredSurveys;


            // Perform any additional logic needed with this.expiredSurveys
          },
          (error) => {
            console.error('Error fetching expired surveys:', error);
          }
        );
      }
      else {
        this.displaySurveys = this.expiredSurveys
      }
      this.selectedFilter = 'expired'

    }
    this.selectedFilter = filter;
  }


  selectSurvey(survey: any) {
    this.selectedSurvey = survey
  }

  openCreateSurveyDialog() {
    const dialogRef = this.dialog.open(CreateSurveyDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
    });  }





  get answerOptions() {
    return this.surveyForm.get('answerOptions') as FormArray;
  }

  addAnswerOption() {
    if (this.answerOptions.length < 8) {
      this.answerOptions.push(this.formBuilder.control(''));
    }
  }
  onSubmit() {
    if (this.surveyForm.valid) {
      const formData = this.surveyForm.value;

      // Send the form data to the backend
      this.surveysService.create(formData).subscribe(
        (result) => {
          // You can add further logic here, e.g., navigate to a different page
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }


  ngOnDestroy(): void {
    // Unsubscribe from the surveys subscription to avoid memory leaks
    if (this.surveysSubscription) {
      this.surveysSubscription.unsubscribe();
    }
  }

  loadSurveys() {

    this.surveysService.getExpiredSurveys(this.page).subscribe(
      (surveys: any[]) => {
        this.displaySurveys = [...this.displaySurveys, ...surveys];
        this.page++;
      },
      (error) => {
        console.error('Error fetching expired surveys:', error);
      }
    );
  }
  onScroll() {
    // Detect when the user has scrolled to the bottom of the page
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadSurveys();
    }
  }

  generateWinner() {
    const dialogRef = this.dialog.open(NumberOfWinnersDialogComponent, {
      // @ts-ignore
      data: { correctAnswers: this.selectedSurvey[SurveysProperty.answerOptions][this.selectedSurvey[SurveysProperty.correctAnswer]].count },
    });

    dialogRef.afterClosed().subscribe(result => {
      // @ts-ignore
      if (result === undefined || result < 1 || result > this.selectedSurvey[SurveysProperty.answerOptions][this.selectedSurvey[SurveysProperty.correctAnswer]].count)
      {
        this.snackBar.open('Broj koji ste unijeli ne moze biti procesuiran', 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
      else {
        this.surveysService.generateGiftWinner(this.selectedSurvey[SurveysProperty.id], result).subscribe(
          (result) => {
            if (result && result.winners) {
              // Ensure that the winner property exists before adding it
              if (!this.selectedSurvey.hasOwnProperty('winners')) {
                this.selectedSurvey.winners = [];
              }

              // Add the winner details to the selectedSurvey
              // @ts-ignore
              Object.assign(this.selectedSurvey.winners, result.winners);
            }
          }
        );
      }
    });


  }


}
