<app-account-header></app-account-header>
<div class="signup">
  <h1>Kreiraj nalog</h1>
  <form [formGroup]="form" (ngSubmit)="submit()" class="container">

    <div class="form-field">
      <input type="text"
             placeholder="Ime"
             [formControlName]="userProperty.firstname"
             [class.missing-field]="isFieldMissing(userProperty.firstname)"
             (input)="clearMissingField(userProperty.firstname)">

    </div>

    <div class="form-field">
      <input type="text"
             placeholder="Prezime"
             [formControlName]="userProperty.lastname"
             [class.missing-field]="isFieldMissing(userProperty.lastname)"
             (input)="clearMissingField(userProperty.lastname)">

    </div>



    <div class="form-field">
      <input type="email"
             placeholder="Email"
             [formControlName]="userProperty.email"
             [class.missing-field]="isFieldMissing(userProperty.email)"
             (input)="clearMissingField(userProperty.email)">

    </div>

    <div class="form-field no-padding">
      <input type="password"
             placeholder="Kreiraj password"
             [formControlName]="userProperty.password"
             [class.missing-field]="isFieldMissing(userProperty.password)"
             (input)="clearMissingField(userProperty.password)">
    </div>

    <div *ngIf="form.get(userProperty.password)?.hasError('minlength')">
      Password mora sadržavati najmanje 8 karaktera.
    </div>


    <div class="checkbox-container">
      <div class="checkbox">
        <input type="checkbox"
               style="width: 16px"
               formControlName="acceptTerms">
        <p>Prihvatam <button type="button" (click)="openPrivacyDialog()" class="button">uslove korištenja</button></p>
      </div>
      <div class="checkbox-warning">
        <p [style.visibility]="warning ? 'visible' : 'hidden'">Potrebno je da prihvatite uslove korištenja</p>
      </div>

    </div>


    <button *ngIf="!loading"
            class="button-regular"
            type="submit"
            aria-label="submit">
      Sign Up
    </button>
    <div  *ngIf="loading" class="button-regular">
      <div class="loading-indicator">
        <div class="spinner"></div>
      </div>
    </div>

  </form>

  <div class="login">
    <p>Imate kreiran nalog?</p>
    <button class="button" [routerLink]="'/login'"> Prijavite se!</button>
  </div>


</div>
