import {ChangeDetectorRef, Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {QuizService} from "../../../services/quiz.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-quiz-answers',
  standalone: true,
  imports: [],
  templateUrl: './quiz-answers.component.html',
  styleUrl: './quiz-answers.component.css',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void <=> *', animate('300ms ease-in-out')),
    ]),
  ],
})
export class QuizAnswersComponent {
  answer: number = 0;
  teamId: string | null = null;
  display: boolean = false

  private socket: WebSocket | undefined; // Define a WebSocket variable
  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private quizService: QuizService,
    private cdr: ChangeDetectorRef,
  ) {
    this.teamId = this.route.snapshot.paramMap.get('team');

  }
  ngOnInit(): void {
    this.teamId = this.route.snapshot.paramMap.get('team');
    this.cdr.detectChanges();

    // Capture the team ID from the URL
    this.subscriptions = this.route.params.subscribe((params) => {
      {
        this.quizService.socketDisplayState().subscribe(
          (result: any) => {
            // this.answer = result.answerOption;
            this.display = result.displayState
            console.log(this.display)
            this.cdr.detectChanges();
          },
          (error: any) => {
            // Handle error
            console.error('Error fetching event data:', error);
          }
        );

        this.quizService.listenForEventUpdates(this.teamId!).subscribe(
          (result: any) => {
            // this.answer = result.answerOption;
            this.answer = result.answerOption
            this.cdr.detectChanges();
          },
          (error: any) => {
            // Handle error
            console.error('Error fetching event data:', error);
          }
        );
      }
    });
  }

}
