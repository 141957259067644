import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {User} from "../../../../models/user.model";
import {Router} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {ChairService} from "../../../../services/chair.service";
import {Guest} from "../../../../models/guest.model";
import {GuestsService} from "../../../../services/guests.services";

@Component({
  selector: 'app-guest-login',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
  templateUrl: './guest-login.component.html',
  styleUrl: './guest-login.component.css'
})
export class GuestLoginComponent {
  form!: FormGroup;

  public guest: Observable<Guest> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private guestsService: GuestsService,

  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      role: ['gu']
    });

  }

  handleFormSubmit() {
    this.form!.markAllAsTouched();
    if (this.form!.valid) {
      console.log(this.form.value)
      this.userService.login({ ...this.form!.value }).subscribe(() => {
          this.router.navigateByUrl( '/televizija/shows');
      })}
    else this.form!.setErrors({ unauthenticated: true });
  }

  isFieldMissing(fieldName: string) {
    const control = this.form.get(fieldName);
    return control?.invalid && control?.touched;
  }
}
