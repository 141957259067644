import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Categories} from "../../../models/categories.model";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CategoriesService} from "../../../services/categories.services";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

@Component({
  selector: 'app-categories-editor',
  templateUrl: './administrator-categories.component.html',
  standalone: true,
  imports: [
    NgIf,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf
  ],
  styleUrls: ['./administrator-categories.component.css']
})
export class AdministratorCategoriesComponent {
  categories!: Categories[];
  originalCategories!: Categories[];

  public form!: FormGroup;

  public categoriesProperty = CategoriesProperty;

  public category: Categories | undefined;

  public editing: boolean = false;

  categoriesSubscription: any;
  differentOrder = false //is order different from original


  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    public router: Router,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.categoriesService.getCategories().subscribe((response: any)=> {
        this.categories = response;
      })
      this.form = this.formBuilder.group({
        [CategoriesProperty.id]: [this.category?.[CategoriesProperty.id] || ''],
        [CategoriesProperty.name]: [this.category?.[CategoriesProperty.name] || ''],
        [CategoriesProperty.order_number]: [this.category?.[CategoriesProperty.order_number] || ''],
      });
    }
  }

  public addCategory(): void {
    this.form.get(CategoriesProperty.order_number)?.setValue(this.categories.length + 1);
    this.categoriesService.create(this.form.value).subscribe(
      (value) => {
        this.categoriesService.addCategory(this.form.value);
        this.categories.push(this.form.value)
        this.snackBar.open('Category created successfully', 'Close', {
          duration: 3000, // Adjust the duration as needed
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        this.resetForm();
      },
      (error) => {
        this.snackBar.open('Error creating category', 'Close', {
          duration: 3000, // Adjust the duration as needed
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error creating category:', error);
      }
    );
  }

  public removeCategory(id: string): void {
    const data = {
      _id: id
    };
    this.categoriesService.delete(data).subscribe(response => {
      this.categoriesService.gCategories.forEach((item, index) => {
        if (item[CategoriesProperty.id] === id) this.categoriesService.gCategories.splice(index, 1);
      });
      this.categories = this.categoriesService.gCategories;
    });
  }

  /*UPDATE CATEGORY ON FRONTEND AFTER THE VALUE IS SENT TO BACKEND AND SUCCESSFULLY UPDATED*/
  public updateCategory(): void {
    this.categoriesService.updateCategory(this.form.value[CategoriesProperty.id]!, this.form.value).subscribe(value => {
      this.categoriesService.gCategories.forEach((item, index) => {
        if (item[CategoriesProperty.id] === this.form.value[CategoriesProperty.id]) this.categoriesService.gCategories[index] = this.form.value;
      });
      this.categories = this.categoriesService.gCategories;
    });
    this.editing = false;
    this.resetForm();
  }

  insertForm(category: Categories): void {
    this.editing = true;
    this.form = this.formBuilder.group({
      [CategoriesProperty.id]: [category?.[CategoriesProperty.id] || ''],
      [CategoriesProperty.name]: [category?.[CategoriesProperty.name] || ''],
      [CategoriesProperty.order_number]: [category?.[CategoriesProperty.order_number] || ''],
      [CategoriesProperty.category_url]: [category?.[CategoriesProperty.category_url] || ''],
    });
  }

  restartForm(): void {
    this.editing = false;
    this.resetForm()
  }

  isEditing(): boolean {
    return this.editing;
  }

  public updateCategories(): void {
    this.categoriesService.updateCategories(this.categories).subscribe(
      (value) => {
        this.categoriesService._categories = this.categories;
        this.originalCategories = JSON.parse(JSON.stringify(this.categories));
        this.differentOrder = false;

        this.snackBar.open('Categories order updated successfully', 'Close', {
          duration: 3000, // Adjust the duration as needed
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        this.resetForm();
      },
      (error) => {
        this.snackBar.open('Error updating categories order', 'Close', {
          duration: 3000, // Adjust the duration as needed
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error creating category:', error);
      }
    );
  }

  private resetForm(): void {
    this.form.reset();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.differentOrder = false;
    // Update the order_number of each category based on the new array position
    this.categories.forEach((category, index) => {
      if (category[CategoriesProperty.name] !== this.originalCategories[index][CategoriesProperty.name]) {
        category.order_number = index + 1; // Adding 1 to make it 1-based if needed
        this.differentOrder = true;
      }
    });
  }


  /*isAdmin(): boolean {
    return this.editorsServices.getEditor()[EditorProperty.admin];
  }*/


}
