<div class="dialog-buttons">
  <div>
    <p>Highlight</p>
  </div>
  <div>
    <button mat-button (click)="onSubmitClick()" class="button-submit">Submit</button>
    <button mat-button (click)="onNoClick()" class="button-close"><i class="fa fa-close"></i></button>
  </div>
</div>
<div *ngIf="priority1Articles.length == 0; else breaking" class="highlight">
  <!-- Left Column -->
  <div class="column first">
    <div *ngIf="priority2Articles.length > 0" class="article priority-2"
         [routerLink]="'article/' + priority2Articles[0].url_title + '/' + priority2Articles[0][articlesProperty.id]">
      <!-- Article content for priority 2 -->
      <h2><span *ngIf="priority2Articles[0].video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ priority2Articles[0].title }}</h2>
      <div class="category">
        <p>{{ priority2Articles[0].category.name }}</p>
      </div>
      <div *ngIf="priority2Articles[0].image_list && priority2Articles[0].image_list.length > 0" class="media-container">
        <img *ngIf="!priority2Articles[0].video_post; else video"
             ngSrc="{{ priority2Articles[0].image_list[0].url }}"
             fill
             priority>
        <ng-template #video>
          <video
            #videoPriority2
            width="575px"
            height="323px"
            [src]="priority2Articles[0].image_list[0].url"
            controls
            muted
            autoplay
            preload="auto"
            loop
          ></video>
        </ng-template>
      </div>
      <p>{{ priority2Articles[0].subtitle }}</p>
    </div>

    <div *ngFor="let article of priority6Articles" class="article priority-6" [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">
      <div class="info">
        <div class="category">
          <p>{{article.category.name}}</p>
        </div>
      </div>
      <div class="content">
        <!-- Article content for priority 6 -->
        <h2><span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ article[articlesProperty.title] }}</h2>
      </div>
    </div>

    <div class="advertisement medium-screen">
      <img src="assets/logo-color.webp">
    </div>
  </div>

  <!-- Center Column -->
  <div class="column second">
    <div *ngFor="let article of priority4Articles" class="article priority-4" [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">
      <!-- Article content for priority 4 -->
      <div class="category">
        <p>{{article.category.name}}</p>
      </div>
      <!--<div class="category"
           [style.background-color]=article.category.color_code>
        <p>{{article.category.name}}</p>
      </div>-->
      <div *ngIf="article.image_list && article.image_list.length > 0" class="media-container">
        <img *ngIf="!article.video_post; else video"
             ngSrc="{{article.image_list[0].url}}"
             fill
             priority>
        <ng-template #video>
          <video
            width="250px"
            height="140px"
            [src]="article.image_list[0].url"
            controls
            muted
            autoplay
            preload="auto"
            loop
          >
          </video>
        </ng-template>
      </div>
      <h2><span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ article.title }}</h2>
      <p>{{ article.subtitle }}</p>
    </div>
  </div>

  <!-- Right Column -->
  <div class="column third">
    <div *ngFor="let article of priority3Articles" class="article priority-3" [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">
      <!-- Article content for priority 3 -->
      <div class="category">
        <p>{{article.category.name}}</p>
      </div>
      <!--<div class="category"
           [style.background-color]=article.category.color_code>
        <p>{{article.category.name}}</p>
      </div>-->
      <div *ngIf="article.image_list && article.image_list.length > 0"  class="media-container">
        <img *ngIf="!article.video_post; else video"
             ngSrc="{{article.image_list[0].url}}"
             fill
             priority>
        <ng-template #video>
          <video
            #videoPriority3
            width="400px"
            height="225px"
            [src]="article.image_list[0].url"
            controls
            muted
            autoplay
            preload="auto"
            loop
          >
          </video>
        </ng-template>
      </div>
      <h2><span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ article.title }}</h2>
      <p>{{ article.subtitle }}</p>
    </div>
    <div class="advertisement-container">
      <div *ngFor="let article of priority5Articles" class="article priority-5" [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">
        <!-- Article content for priority 4 -->
        <div class="category">
          <p>{{article.category.name}}</p>
        </div>
        <!--<div class="category"
             [style.background-color]=article.category.color_code>
          <p>{{article.category.name}}</p>
        </div>-->
        <div *ngIf="article.image_list && article.image_list.length > 0"  class="media-container">
          <img *ngIf="!article.video_post; else video"
               ngSrc="{{article.image_list[0].url}}"
               fill
               priority>
          <ng-template #video>
            <video
              width="250px"
              height="140px"
              [src]="article.image_list[0].url"
              controls
              muted
              autoplay
              preload="auto"
              loop
            >
            </video>
          </ng-template>
        </div>
        <h2><span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ article.title }}</h2>
      </div>
      <div class="advertisement big-screen">
        <img src="assets/logo-h.webp">
      </div>
      <div class="advertisement small-screen">
        <img src="assets/logo-color.webp">
      </div>
    </div>
  </div>
</div>

<ng-template #breaking>
  <div class="breaking">
    <div *ngFor="let article of priority1Articles" class="article priority-1" [routerLink]="'article/' + article.url_title+ '/' + article[articlesProperty.id]">
      <!-- Article content for priority 2 -->
      <div class="category">
        <h5>{{article.category.name}}</h5>
      </div>
      <div *ngIf="article.image_list && article.image_list.length > 0">
        <img *ngIf="!article.video_post; else video"
             ngSrc="{{article.image_list[0].url}}"
             fill
             priority>
        <ng-template #video>
          <video
            #videoPriority2
            width="575px"
            height="323px"
            [src]="article.image_list[0].url"
            controls
            muted
            autoplay
            preload="auto"
            loop
          >
          </video>
        </ng-template>
      </div>
      <h1><span *ngIf="article.video_post" class="video">VIDEO<span class="red-dot"></span></span>{{ article.title }}</h1>
      <p>{{ article.subtitle }}</p>
    </div>
  </div>
</ng-template>

