import {Component, Inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SurveysService} from "../../../../services/survey.service";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-remove-tag-dialog',
  templateUrl: './remove-tag-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogTitle,
    MatDialogClose,
    MatButtonModule
  ],
  styleUrls: ['./remove-tag-dialog.component.css']
})
export class RemoveTagDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RemoveTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {}


  onSubmit(): void {
    this.dialogRef.close(true);
  }

  close(): void{
    this.dialogRef.close(false);

  }
}
