<div class="login">
  <form (submit)="handleFormSubmit()" [formGroup]="form" class="container">
    <div class="form-field">
      <input type="text"
             placeholder="Username"
             formControlName="email"
             [class.missing-field]="isFieldMissing('email')">
    </div>

    <div class="form-field">
      <input type="password"
             placeholder="Password"
             formControlName="password"
             [class.missing-field]="isFieldMissing('password')">
    </div>

    <button class="button-regular"
            type="submit"
            aria-label="submit">
      Log in
    </button>
  </form>

</div>
