import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ResolverResponse} from "../../../constants/resolver-response.constants";
import {Categories} from "../../../models/categories.model";
import {ActivatedRoute, ActivatedRouteSnapshot, RouterLink} from "@angular/router";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Articles} from "../../../models/articles.model";
import {CategoryArticles} from "../../../models/category-articles.model";
import {CategoryArticlesProperty} from "../../../models/category-articles-property.enum";
import {isPlatformBrowser, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ArticlesService} from "../../../services/articles.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgForOf,
    NgOptimizedImage,
  ],
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit{


  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef<HTMLVideoElement>>;
  private intersectionObserver: IntersectionObserver | undefined;

  public category!: Categories;

  public tag: string | undefined = undefined

  public categoryArticles!: CategoryArticles;
  public articles!: Articles[];

  categoriesSubscription: any;

  page: number = 1;

  public articlesProperty = ArticlesProperty;
  public categoryArticlesProperty = CategoryArticlesProperty;
  public categoriesProperty = CategoriesProperty;


  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private articlesService: ArticlesService,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {

      this.activatedRoute.params.subscribe(async params => {
        const tag = params['tag']; // Assuming 'id' is the parameter name in your URL
        this.tag = tag
        console.log(tag)
        this.articlesService.tagName = tag;

        await this.loadArticles(tag);
        setTimeout(() => {
          this.muteVideos();
        }, 2000);
        //await this.muteVideos();
      })
    }
  }

  async loadArticles(category: string){
    this.page = 1
    this.articlesService.getArticlesByTag().subscribe(async result => {
      // this.categoryArticles = result;
      this.articles = result;
    })
  }

  async muteVideos(){
    this.intersectionObserver = undefined
    if (this.videoElements) {
      this.videoElements.notifyOnChanges(); // Force reload of @ViewChildren
      console.log('Video elements reloaded:', this.videoElements);
    } else {
      console.error('No video elements found.');
    }
    console.log("START OF MUTE FUNCTION")
    if (this.videoElements) {
      console.log("MUTE - VIDEO ELEMENTS EXSIST")
      // After the view is initialized, access all video elements and set them to muted
      this.videoElements.forEach((videoElementRef) => {
        const videoElement = videoElementRef.nativeElement;
        console.log("MUTE - VIDEO ELEMENTS PICKED")


        // Listen for the loadeddata event before muting the video
        videoElement.addEventListener('loadeddata', () => {
          videoElement.muted = true;
          console.log("MUTE - VIDEO ELEMENTS MUTED")
          this.initializeIntersectionObserver(videoElement);
        });

        // Check if the video is already loaded (in case ngAfterViewInit is called after loading)
        if (videoElement.readyState >= 2) {
          videoElement.muted = true;
          console.log("MUTE - VIDEO ELEMENTS MUTED DIFFERENT WAY")
          this.initializeIntersectionObserver(videoElement);
        }
      });
    }
  }

  private initializeIntersectionObserver(videoElement: HTMLVideoElement) {
    // Initialize Intersection Observer
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If video is in viewport and loaded, play it
          videoElement.play();
        } else {
          // If video is not in viewport or not loaded, pause it
          videoElement.pause();
        }
      });
    });
    console.log(this.intersectionObserver)
    // Start observing the video element
    this.intersectionObserver.observe(videoElement);
  }

  loadNewPage(): void {
    this.articlesService.getArticlesByTagByPage(++this.page).subscribe((response) => {
      this.articles.push(...response)
    });
  }


  isVideo(end: string | undefined | null): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

  loadNextPage(): void {
    this.loadNewPage();
  }


  /*getAd(): string{
    var randomiser = (Math.random() + 1).toString(36).substring(7);
    // @ts-ignore
    var adID = document.querySelector('#hayat_ba_450x125_left_rectangle_4_responsive').id = "hayat_ba_450x125_left_rectangle_4_responsive-" + randomiser;
    // @ts-ignore
    inView('#' + adID).once('enter', (function () {
      // @ts-ignore
      googletag.cmd.push(function () {
        if (window.innerWidth >= 1280) {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_450x125_left_rectangle_4_desktop', [[320,100],[320,50],[300,100],[300,50],[450,125]], adID).addService(googletag.pubads());
        } else {
          // @ts-ignore
          googletag.defineSlot('/272140683/hayat.ba_325x125_left_rectangle_4_mobile', [[320,100],[320,50],[300,100],[300,50],[325,125]], adID).addService(googletag.pubads());
        }
        // @ts-ignore
        googletag.display(adID);
        // @ts-ignore
        stpd.initializeAdUnit(adID);
      });
    }));

    return adID
  }*/
}
