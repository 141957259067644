export class ResolverResponse {
  public static ARTICLE_HIGHLIGHT = 'article-highlight';
  public static NEWS = 'news';
  public static CATEGORIES = 'categories';
  public static ARTICLES = 'articles';
  public static ARTICLE = 'article';
  public static DRAFT = 'draft';
  public static MAIN_ARTICLES = 'articles-main';
  public static EDITOR_ARTICLES = 'articles-editor';
  public static EDITOR_ARTICLES_SIZE = 'articles-editor-size';
  public static CATEGORY = 'category';
  public static CATEGORY_SIZE = 'category-size';
  public static EDITORS = 'editors';
  public static EDITOR = 'editor';
  public static SEARCH = 'search';
  public static SEARCH_SIZE = 'search-size';
  public static SCHEDULE_ARTICLES = 'schedule-articles';
  public static ALL_SCHEDULE_ARTICLES = 'all-schedule-articles';
  public static ALL_DRAFT_ARTICLES = 'all-draft-articles';
  public static DELETE = 'delete';

}
