import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {ArticlesService} from "../../../services/articles.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {Images} from "../../../models/images.model";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {ScheduleDialogComponent} from "../schedule-dialog/schedule-dialog.component";

@Component({
  selector: 'app-article-preview-dialog',
  templateUrl: './article-preview-dialog.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    NgForOf,
    NgOptimizedImage,
    RouterLink
  ],
  styleUrls: ['./article-preview-dialog.component.css']
})
export class ArticlePreviewDialogComponent {

  public highlight: Articles[] = [];

  @ViewChild('videoPriority2', { static: false }) videoPlayer1!: ElementRef;
  @ViewChild('videoPriority3', { static: false }) videoPlayer2!: ElementRef;
  @ViewChild('videoPriority5', { static: false }) videoPlayer4!: ElementRef;

  public priority1Articles: Articles[] = [];
  public priority2Articles: Articles[] = [];
  public priority3Articles: Articles[] = [];
  public priority4Articles: Articles[] = [];
  public priority5Articles: Articles[] = [];
  public priority6Articles: Articles[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { articles: Articles[], article: Articles },
    public dialogRef: MatDialogRef<ArticlePreviewDialogComponent>,
  ) {
  }

  public articlesProperty = ArticlesProperty;

  private filterArticlesByPriority(): void {
    this.data.articles.unshift(this.data.article);

    this.priority1Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 1);
    this.priority2Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 2);
    this.priority3Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 3);
    this.priority4Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 4);
    this.priority5Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 5);
    this.priority6Articles = this.data.articles.filter(article => article[ArticlesProperty.priority] === 6);
  }

  ngOnInit(): void {
    this.filterArticlesByPriority();
  };

  ngAfterViewInit() {
    // After the view is initialized, access the video element and set it to muted
    const videoElement1 = this.videoPlayer1.nativeElement;
    videoElement1.muted = true;
    const videoElement2 = this.videoPlayer2.nativeElement;
    videoElement2.muted = true;

    const videoElement4 = this.videoPlayer4.nativeElement;
    videoElement4.muted = true;
  }

  isVideo(end: string | undefined | null | Images): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmitClick(): void {
    this.dialogRef.close(true);
  }
  onScheduleClick(): void {
    this.dialogRef.close(true);
  }

}
