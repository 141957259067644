<div class="surveys">
  <div class="page-title">
    <h1>Gosti</h1>
  </div>
  <div class="top">
    <button (click)="openCreateChairDialog()">Novi gost</button>
  </div>
  <!-- List of Survey Questions -->
  <div class="survey-visual">
    <div class="editor"
         *ngFor="let editor of allGuests"
    >
      <div class="naame">
        {{ editor.name }}
      </div>
      <div class="options">
        <button (click)="openCreateChairDialog(editor)"
                class="edit-button"
                color="primary">
          Edit
        </button>
        <!--<button (click)="deleteEditor(editor._id!)"
                class="edit-button"
                color="warn">
          Delete
        </button>-->
      </div>
    </div>
  </div>
</div>
