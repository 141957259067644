<div class="right">
  <div class="top">
    <button
      [ngClass]="{'selected': !isChat}"
      (click)="selection('')"
      [routerLink]="['.']"
    >
      O emisiji
    </button>

    <button
      [ngClass]="{'selected': isChat}"
      (click)="selection('chat')"
      [routerLink]="'chat'"
    >
      Chat
    </button>
  </div>
  <router-outlet></router-outlet>
</div>
