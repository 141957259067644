<div class="about">
  <h1>
    UPUTSTVO ZA KORIŠTENJE SERVISA GLEDAJ HAYAT/HAYAT PLAY
  </h1>

  <p><strong>Servis GLEDAJ HAYAT je dostupan putem web portala hayat.ba kao i na vašim iOS i ANDROID uređajima, odnosno
    na vašim mobitelima, tabletima i smart TV uređajima, uz željeni paket pretplate. Cijene paketa možete
    pronaći&nbsp;</strong>
    <button [routerLink]="'../servis-gledaj-hayat'">
      <strong>OVDJE</strong>
    </button>
    <strong>.</strong></p>

  <p>Klikom na ikonicu GLEDAJ Hayat dobijate pristup uživo kanalima <strong>Hayat HD</strong>, <strong>Hayat
    PLUS</strong>, <strong>Hayat MUSIC</strong>, <strong>Hayat FOLK</strong> i jedinom dječijiem kanalu na bosanskom
    jeziku – <strong>HAYATOVCI</strong> sada možete gledati bilo kad i bilo gdje, na vašem računaru, tabletu, mobitelu i
    smart TV uređajima.
  </p>

  <p>Klikom na ikonicu Hayat PLAY, dobijate pristup servisu ‘video na zahtjev’ odnosno videoteci u kojoj su vam dostupne
    sve aktuelne emi sije Hayata, koje broje više od 30 000 sati programa ali i najbolje serije unaprijed!</p>

  <p><strong>Prijava na servis, sada je lakša nego ikada</strong></p>

  <p>Da bi sve naše kanale pratili&nbsp;<strong>UŽIVO&nbsp;</strong>na mobitelu, smart tv i tabletu, trebate skinuti
    jednu od najpopularnijih aplikacija u BIH i dijaspori, a to je Hayat aplikacija. Možete je pronaći u vašem&nbsp;<a
      href="https://itunes.apple.com/hr/app/hayat/id1003719038" target="_blank"
      rel="noreferrer noopener"><strong>APP</strong>&nbsp;</a>ili&nbsp;<a
      href="https://play.google.com/store/apps/details?id=com.segvic.hayat&amp;hl=en" target="_blank"
      rel="noreferrer noopener"><strong>PLAY&nbsp;</strong></a>storeu (radnji) i to potpuno besplatno.</p>

  <p>Nakon što skinete Hayat aplikaciju, u meniju kliknite na GLEDAJ Hayat i pratite korake koje vam servis sugeriše.
    Sve što trebate uraditi je ukucati vaš e-mail i lozinku, koju ćete koristiti samo za ovaj servis.&nbsp;</p>

  <p><strong>Na primjer:</strong></p>

  <ul>
    <li>KORISNIČKO IME: sabina&#64;gmail.com</li>
    <li>LOZINKA: Sabina1.</li>
  </ul>

  <p>Dakle, Sabina je za korisničko ime odabrala ovu e-mail adresu i ovu lozinku.</p>

  <p>Savjetujemo da vašu lozinku čuvate samo za sebe i koristite je samo vi. Svakako neka bude vama svojstvena i lako
    pamtljiva za vas. Sa ovim pristupnim podacima možete se prijaviti na servis GLEDAJ Hayat sa bilo kojeg mjesta u
    svijetu, koje ima internet konekciju. Sve što vam uz to treba je mobitel, računar ili tablet. Dakle, istu lozinku
    možete koristiti na bilo kojem od navedenih uređaja.</p>

  <p>Nakon prijave na servis, dobićete poruku da je period validnosti (promotivni period) vašeg naloga istekao. Sada je
    potrebno da kliknete na poruku kojom ćete produžiti vrijeme trajanja vašeg naloga. Nakon klika, servis vas vodi na
    stranicu, na kojoj ćete odabrati željeni paket pretplate, putem&nbsp;<strong>vašeg PayPal&nbsp;</strong>naloga.
    Nakon što odaberete i platite pretplatu, sistem će vam automatski usmjeriti na početnu stranicu servisa, na kojoj
    potom možete odabrati bilo koji od 5 HD TV kanala iz ponude i uživati u omiljenom programu.</p>

  <p>Ako ipak želite gledati neki od Hayat kanala na vašem računaru UŽIVO, princip pristupa servisu je isti kao na
    aplikaciji. Sve što trebate uraditi je, da na jednom od najčitanijih portala u državi, portalu&nbsp;<a
      href="https://www.hayat.ba/gledaj.php" target="_blank" rel="noreferrer noopener">www.hayat.ba</a>&nbsp;u gornjem
    meniju, kliknete na natpis GLEDAJ HAYAT. Da bi gledali bilo koji Hayatov kanal UŽIVO, postupak prijave i plaćanja
    servisa je isti kao na aplikaciji.</p>

  <p>‘To znači da od sada Hayat možete ponijeti sa sobom gdje god da krenete i gledati vaše omiljene programe UŽIVO,
    gdje god da se u svijetu nalazite. Posebno nam je drago istaći, da će vaši mališani s uživanjem, od sada na svom
    TABLETU, MOBITELU,RAČUNARU i SMART TV UREĐAJU gledati prvi bh. dječiji kanal – HAYATOVCI’, kaže Igor Todorovac,
    direktor digitalnih medija Hayata i dodaje da sada svi pretplatnici servisa dobijaju i bonus kanale, bez dodatne
    naplate.</p>

  <p>Također, bitno je istaći da će svi koji se odluče za korištenje ovog servisa biti 100% sigurni i uvjereni da
    koriste pravu stvar, originalni servis i svakako, ovim potezom će podržati domaće.</p>

  <div>__________</div>

  <p>
    <strong>USLOVI KORIŠTENJA</strong>
  </p>

  <p>
    <strong>
      Hayat d.o.o. nije odgovoran za kvalitet internet veze koju vam nudi vaš provajder a putem kojeg koristite
      uslugu GLEDAJ HAYAT i Hayat PLAY. Hayat se odriče od odgovornosti za eventualne štete ili nemogućnosti korištenja
      usluge.
    </strong>
  </p>

  <p><strong>Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY koristiti poštujući autorska i druga srodna
    prava u pogledu sadržaja koji se servisom prenosi. Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY
    koristiti za svoje privatne potrebe te istu neće, ni na koji način učiniti dostupnom javnosti u smislu važećih
    propisa o autorskim i srodnim pravima, a posebno garantuje da uslugu neće koristiti u zajedničkim ili javnim
    prostorijama bilo koje vrste, te da neće vršiti daljnu redistribuciju signala trećim licima, bilo da je u pitanju
    snimljeni ili sadržaj uživo.</strong></p>

  <p><strong>GLEDAJ Hayat/Hayat PLAY</strong></p>

  <p><strong>GLEDAJ HAYAT je multiscreen usluga, koja omogućava praćenje omiljenih TV kanala putem svih uređaja koji
    imaju pristup internetu. (tablet, mobilni, desktop/laptop) Ovaj servis za sada nije moguće pratiti putem Smart ili
    neke druge vrste TV uređaja.</strong></p>

  <p><strong>Registracijom na uslugu GLEDAJ HAYAT/HAYAT PLAY, te plaćanjem pretplate, po jednoj pretplati, validni su
    jedni pristupni podaci. Ako se korisnik koji je platio pretplatu, nalazi na istoj IP adresi gdje i ostali
    potencijalni korisnici (npr. Uslugu platio jedan član porodice a tu su još tri člana porodice), onda i ostali
    potencijalni korisnici mogu pristupiti servisu sa korisničkim podacima baznog vlasnika, odnsono korisničkim podacima
    korisnika, koji je platio pretplatu.</strong></p>

  <p><strong>Ako se bazni vlasnik ne nalazi na istoj IP adresi na kojoj su i ostali potencijalni korisnici, usluga će
    biti validna samo baznom vlasniku ili neće raditi.</strong></p>

  <p>Sa jednim nalogom (shared password) servise nije moguće koristiti istovremeno, na više uređaja, na različitim IP
    adresama.</p>

  <p><strong>Hayat PLAY je VOD (Video On Demand/Video na zahtjev) usluga putem koje možete gledati propuštene epizode
    emisija i serija, koje su trenutno aktuelne i u ponudi servisa.</strong></p>

  <p><strong>Hayat zadržava pravo izmjene programa u ponudi, te u svakom momentu može povući iz ponude stare sadržaje,
    odnosno ubaciti nove.</strong></p>

  <p><strong>Tokom premijernog emitovanja, strani serijski program u ponudi sadrži najmanje 7 a najviše 20 epizoda. U
    rijetkim slučajevima moguć je i veći broj epizoda u ponudi ali samo u ograničenom periodu od najviše mjesec
    dana.</strong></p>

  <p><strong>Nakon otvaranja vašeg naloga i plaćanja pretplate na servis GLEDAJ Hayat/Hayat PLAY, pretplatnik ima
    mogućnost da koristi navedene usluge na bezbroj uređaja u jednom trenutku (tablet, mobilni, desktop/laptop ili TV s
    pristupom internetu) na istoj lokaciji korištenja (istoj pristupnoj adresi). Korištenje usluge na više lokacija
    istovremeno zahtijeva dodatne naloge. Plaćanjem jedne usluge dobijate pristup servisu GLEDAJ Hayat, odnosno gledanju
    kanala iz ponude UŽIVO, ali i servisu Hayat PLAY, odnosno videoteci. Usluge je moguće koristiti bilo gdje u svijetu
    samo s vašim nalogom.</strong></p>

  <p><strong>Ukoliko imate pitanja u vezi sa servisima, korištenjem, pretplatom ili ukoliko imate bilo kakav problem,
    molimo vas obratite se na mail <a href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a></strong></p>

  <p><strong>Ukoliko imate pitanja u vezi sa&nbsp;<em>pretplatom</em>, molimo vas obratite se na mail <a
    href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a>
    ili na broj +387 33 492 942 (09-17h CET)</strong></p>
</div>
