<form [formGroup]="showForm" (ngSubmit)="onSubmit()" class="survey">
  <label for="name">Naziv Emisije:</label>
  <input type="text" formControlName="name" id="name">

  <label for="about">O emisiji:</label>
  <input type="text" formControlName="about" id="about">

  <mat-checkbox formControlName="hasChat">Chat</mat-checkbox>

  <div class="buttons">
    <button type="submit">Create Show</button>
  </div>
</form>
