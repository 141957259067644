<div class="videoteka">
  <h1>Videoteka</h1>
  @if(displayInfo){
    <div class="category-info">
      <h2>{{displayInfo.cat_name}}</h2>
      <img src="https://backend.hayat.ba/uploads/tx_ssvodmeta/{{displayInfo.cat_lpict}}">
      <p>{{displayInfo.cat_desc}}</p>
    </div>
  }
  <div class="list">
    @if(this.isCategory()){
      @for(category of displayData; track category){

        <button class="category" [routerLink]="'vod_cat_' + category.cat_uid">
          <img src="https://backend.hayat.ba/uploads/tx_ssvodmeta/{{category.cat_lpict}}">
          <h3>
            {{category.cat_name}}
          </h3>
        </button>
      }
    } @else {
      @for(media of displayData; track media){
        <button class="media-container" (click)="openDialog(media)">
          <img src="https://backend.hayat.ba/vodthumbs/{{media.vod_filename}}.jpg">
          <p>{{media.vod_name}}</p>
        </button>
      }
    }
  </div>
</div>
