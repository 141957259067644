import { Component } from '@angular/core';

@Component({
  selector: 'app-live-tv',
  standalone: true,
  imports: [],
  templateUrl: './live-tv.component.html',
  styleUrl: './live-tv.component.css'
})
export class LiveTvComponent {

}
