import {Component, HostListener} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationStart, Router, RouterModule, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Hayat.ba';

  constructor(private router: Router) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (performance.navigation.type === 1) {
      window.scrollTo(0, 0); // Scroll to top on page reload
    }
  }

  ngOnInit() {
    // Optionally, you can also listen for NavigationStart events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        window.scrollTo(0, 0); // Scroll to top when navigating using browser back/forward buttons
      }
    });
  }
}
