import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Chair } from '../models/chair.model';
import { ChairProperty } from '../models/chair-property.enum';
import { AuthService } from '../services/auth.service';
import {UserProperty} from "../models/user-property.enum";
import {ChairService} from "../services/chair.service";

@Injectable({
  providedIn: 'root',
})
export class ChairGuard implements CanActivate {
  constructor(
    private chairService: ChairService,
    private router: Router,
    private authService: AuthService
  ) {

  }

  chair!: Chair | undefined;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isLoggedIn()) {
      return of(this.router.createUrlTree(['/login/admin']));
    }

    return this.chairService.getChair().pipe(
      switchMap(chair => {
        this.chair = chair;

        // Continue with the rest of the code inside the switchMap block

        if (this.chair?.[ChairProperty.isAdmin] || this.chair?.[ChairProperty.isEditor]) {
          return of(true);
        } else {
          return of(this.router.createUrlTree(['/login/admin']));
        }
      })
    );
  }
}
