import { Component } from '@angular/core';
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {Categories} from "../../../models/categories.model";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {CategoriesService} from "../../../services/categories.services";
import {filter} from "rxjs";

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  standalone: true,
  imports: [
    RouterLink
  ],
  styleUrls: ['./account-header.component.css']
})
export class AccountHeaderComponent {
  public top: number | undefined;


  public categoriesProperty = CategoriesProperty;

  public categories!: Categories[];

  public selectedRoute: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    router: Router,
    public authService: AuthService,
    public categoriesService: CategoriesService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.selectedRoute = ((event as NavigationEnd).url)?.substring(1);
    });
  }

  ngOnInit(): void {
  }

  public logoutUser(): void{
    this.authService.logout();
  }

  public tokenExists(): boolean {
    return this.authService.getToken() != null;
  }

}
