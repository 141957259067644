<div class="show">
  <app-show-episodes (episodeSelected)="onEpisodeSelected($event)"></app-show-episodes>
  <div style="width: 100%">
    <router-outlet></router-outlet>
  </div>
  <!--<app-show-episodes-info [selectedEpisode]="selectedEpisode"></app-show-episodes-info>-->
</div>


<!--@if(!isMobile()) {

    } @else {
        <mat-menu #appMenu="matMenu">
          <ng-template matMenuContent>
            <a mat-menu-item routerLink="about" routerLinkActive="active-link">About</a>
            <a mat-menu-item routerLink="chat" routerLinkActive="active-link">Chat</a>
            <a mat-menu-item routerLink="q&a" routerLinkActive="active-link">Q&A</a>
          </ng-template>
        </mat-menu>
        <nav>
          <button mat-raised-button [matMenuTriggerFor]="appMenu">{{ page }}</button>
        </nav>
      }-->
