<div class="tags">
  <div class="page-title">
    <h1>Tagovi</h1>
  </div>
  <div class="top">
    <input
      type="text"
      name="userInput"
      [(ngModel)]="userInput"
      [ngModelOptions]="{standalone: true}"
      placeholder="Type to search tags"
      (keyup.enter)="onEnter()"
    />
    <div class="filters">
      <button (click)="setFilter('active')"
              [disabled]="selectedFilter == 'active'">
        Aktivni
      </button>
      <button (click)="setFilter('inactive')"
              [disabled]="selectedFilter == 'inactive'">
        Neaktivni
      </button>
    </div>
  </div>
  @if(active !== undefined){
    @if(selectedFilter == 'active' && active.length > 0){
      <div class="tag-container">
        <div
          *ngFor="let tag of filterTags('active')"
          class="tag"
          (click)="deactivateTag(tag)"
        >
         #{{ tag.tag }}
        </div>
      </div>
    }
  }

  <div *ngIf="selectedFilter == 'inactive' && inactive.length > 0" class="tag-container">
    <div
      *ngFor="let tag of filterTags('inactive')"
      class="tag"
      (click)="deleteTag(tag)"
    >

      {{ tag.tag }}
    </div>
  </div>
</div>
