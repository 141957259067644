import { Component } from '@angular/core';

@Component({
  selector: 'app-service-gledaj-hayat',
  standalone: true,
  imports: [],
  templateUrl: './service-gledaj-hayat.component.html',
  styleUrl: './service-gledaj-hayat.component.css'
})
export class ServiceGledajHayatComponent {

}
