<div class="episodes">
  <button
    [routerLink]="'.'"
    (click)="deselectEpisode()"
  >
    <img src="assets/logo.png">
  </button>
  @for (episode of this.show.episodes; track episode){
    <button
      [ngClass]="{'selected': order === episode.order.toString()}"
      class="episode"
      (click)="selectEpisode(episode)"
      [routerLink]="episode.order.toString()"
    >
      <p>{{episode.order}}.<span class="topic"> {{episode.topic}}</span> </p>
    </button>
  }
</div>
