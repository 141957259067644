import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList, Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Articles} from "../../../../models/articles.model";
import {Categories} from "../../../../models/categories.model";
import {Images} from "../../../../models/images.model";
import {isPlatformBrowser, NgIf, NgOptimizedImage} from "@angular/common";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-home-category-two',
  templateUrl: './home-category-two.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    RouterLink
  ],
  styleUrls: ['./home-category-two.component.css']
})
export class HomeCategoryTwoComponent implements OnInit, AfterViewInit{

  @Input()
  articles!: Articles[];
  @Input()
  category: Categories | undefined;
  @Input()
  categoryNo: number | undefined;

  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  public adID: string = ''
  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private renderer: Renderer2,
  )
  {}

  ngOnInit(): void {

  }

  private isScriptLoaded = false;

  ngAfterViewInit() {
    // After the view is initialized, access all video elements and set them to muted
    this.videoElements.forEach((videoElement) => {
      videoElement.nativeElement.muted = true;
    });

    if (isPlatformBrowser(this.platformID)) {
      this.loadGoogleTagScript(this.categoryNo);
    }
  }

  // loadGoogleTagScript(category: number | undefined) {
  //   if (this.isScriptLoaded) {
  //     return; // Exit if the script has already been loaded
  //   }
  //
  //
  //
  //   const existingScript = document.getElementById('google-tag-script');
  //   if (existingScript) {
  //     this.isScriptLoaded = true;
  //     return; // Exit if the script is already in the DOM
  //   }
  //
  //   const script = document.createElement('script');
  //   script.id = 'google-tag-script';
  //   script.type = 'text/javascript';
  //   if(category === 1){
  //     script.innerHTML = `
  //       googletag.cmd.push(function() {
  //         googletag.display('div-gpt-ad-1723029316274-0');
  //       });
  //     `;
  //     document.getElementById('div-gpt-ad-1723029316274-0')?.appendChild(script);
  //   } else if (category === 4){
  //     script.innerHTML = `
  //       googletag.cmd.push(function() {
  //         googletag.display('div-gpt-ad-1723029252493-0');
  //       });
  //     `;
  //     document.getElementById('div-gpt-ad-1723029252493-0')?.appendChild(script);
  //   } else if (category === 7){
  //     script.innerHTML = `
  //       googletag.cmd.push(function() {
  //         googletag.display('div-gpt-ad-1723029173622-0');
  //       });
  //     `;
  //     document.getElementById('div-gpt-ad-1723029173622-0')?.appendChild(script);
  //   } else if (category === 10){
  //     script.innerHTML = `
  //       googletag.cmd.push(function() {
  //         googletag.display('div-gpt-ad-1723029200827-0');
  //       });
  //     `;
  //     document.getElementById('div-gpt-ad-1723029200827-0')?.appendChild(script);
  //   }
  //
  //   this.isScriptLoaded = true; // Mark the script as loaded
  // }
  loadGoogleTagScript(category: number | undefined) {
    if (this.isScriptLoaded) {
      return; // Exit if the script has already been loaded
    }

    const existingScript = document.getElementById('google-tag-script-category-' + category);
    if (existingScript) {
      console.log("This is exsisting script for category " + category)
      this.isScriptLoaded = true;
      return; // Exit if the script is already in the DOM
    }


    if(category === 1){
      const elementId = 'div-gpt-ad-1723029316274-0'; // The ID of the div you want to check
      const checkElement = () => {
        const divElement = document.getElementById(elementId);
        if (divElement) {
          // Element is found, load the script
          console.log('Element found, loading script...');
          const scriptElement = this.renderer.createElement('script');
          const scriptContent = `googletag.cmd.push(function() { googletag.display('${elementId}'); });`;
          scriptElement.text = scriptContent;
          this.renderer.setAttribute(scriptElement, 'id', 'google-tag-script-category-' + category);
          this.renderer.appendChild(document.body, scriptElement);
        } else {
          // Element not found, wait for a second and check again
          console.log('Element not found, retrying...');
          setTimeout(checkElement, 1000); // Retry after 1 second
        }
      };

      // Start the check
      checkElement();

    } else if (category === 4){
      const elementId = 'div-gpt-ad-1723029252493-0'; // The ID of the div you want to check

      const checkElement = () => {
        const divElement = document.getElementById(elementId);
        if (divElement) {
          // Element is found, load the script
          console.log('Element found, loading script...');
          const scriptElement = this.renderer.createElement('script');
          const scriptContent = `googletag.cmd.push(function() { googletag.display('${elementId}'); });`;
          scriptElement.text = scriptContent;
          this.renderer.setAttribute(scriptElement, 'id', 'google-tag-script-category-' + category);
          this.renderer.appendChild(document.body, scriptElement);
        } else {
          // Element not found, wait for a second and check again
          console.log('Element not found, retrying...');
          setTimeout(checkElement, 1000); // Retry after 1 second
        }
      };

      // Start the check
      checkElement();
    } else if (category === 7){
      const elementId = 'div-gpt-ad-1723029173622-0'; // The ID of the div you want to check
      const checkElement = () => {
        const divElement = document.getElementById(elementId);
        if (divElement) {
          // Element is found, load the script
          console.log('Element found, loading script...');
          const scriptElement = this.renderer.createElement('script');
          const scriptContent = `googletag.cmd.push(function() { googletag.display('${elementId}'); });`;
          scriptElement.text = scriptContent;
          this.renderer.setAttribute(scriptElement, 'id', 'google-tag-script-category-' + category);
          this.renderer.appendChild(document.body, scriptElement);
        } else {
          // Element not found, wait for a second and check again
          console.log('Element not found, retrying...');
          setTimeout(checkElement, 1000); // Retry after 1 second
        }
      };

      // Start the check
      checkElement();
    } else if (category === 10){
      const elementId = 'div-gpt-ad-1723029200827-0'; // The ID of the div you want to check

      const checkElement = () => {
        const divElement = document.getElementById(elementId);
        if (divElement) {
          // Element is found, load the script
          console.log('Element found, loading script...');
          const scriptElement = this.renderer.createElement('script');
          const scriptContent = `googletag.cmd.push(function() { googletag.display('${elementId}'); });`;
          scriptElement.text = scriptContent;
          this.renderer.setAttribute(scriptElement, 'id', 'google-tag-script-category-' + category);
          this.renderer.appendChild(document.body, scriptElement);
        } else {
          // Element not found, wait for a second and check again
          console.log('Element not found, retrying...');
          setTimeout(checkElement, 1000); // Retry after 1 second
        }
      };

      // Start the check
      checkElement();
    }

    this.isScriptLoaded = true; // Mark the script as loaded
  }



  isVideo(end: string | undefined | null | Images): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

    protected readonly articlesProperty = ArticlesProperty;
}
