import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Tag} from "../models/tag.model";


@Injectable({providedIn: 'root'})
export class TagsService {

  private readonly baseUrl: string = `${environment.backendUrl}/tags`;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}`);
  }
  public getPopularTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/popular`);
  }
  public getActiveTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/active`);
  }
  public getInactiveTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.baseUrl}/inactive`);
  }

  public create(tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(`${this.baseUrl}`, tag);
  }


  public update(id: string | undefined, tag: Tag): Observable<Tag> {
    if(id !== undefined){
      return this.http.put<Tag>(`${this.baseUrl}/${id}`, tag);
    }
    return of()
  }

  public delete(id: string | undefined, tag: Tag): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {body: tag});
  }

}
