import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {Show} from "../../../../models/show.model";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ShowProperty} from "../../../../models/show-property.enum";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgForOf, NgIf} from "@angular/common";
import {Guest} from "../../../../models/guest.model";
import {GuestProperty} from "../../../../models/guest-property.enum";
import {GuestsService} from "../../../../services/guests.services";
import {EpisodeServices} from "../../../../services/episode.services";
import {Episode} from "../../../../models/episode.model";
import {EpisodeProperty} from "../../../../models/episode-property.enum";

@Component({
  selector: 'app-crate-episode-dialog',
  standalone: true,
  imports: [
    MatCheckbox,
    ReactiveFormsModule,
    NgForOf,
    NgIf,
    FormsModule
  ],
  templateUrl: './crate-episode-dialog.component.html',
  styleUrl: './crate-episode-dialog.component.css'
})
export class CrateEpisodeDialogComponent {

  @Input() show: Show;
  @Input() episode: Episode;

  @Output() episodeCreated = new EventEmitter<Episode>();
  @Output() episodeUpdated = new EventEmitter<Episode>();


  showForm: FormGroup;

  userInput = '';
  public receivedGuestsControl: Guest[] = [];
  public selectedGuests: Guest[] = [];
  public allGuests: Guest[] = [];

  oldOrder: number | undefined;


  constructor(
    public dialogRef: MatDialogRef<CrateEpisodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { show: Show, episode: Episode },
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private episodeServices: EpisodeServices,
    private guestsService: GuestsService
  ) {
    this.show = data.show;
    this.episode = data.episode;
    this.oldOrder = data.episode?.[EpisodeProperty.order];
    this.showForm = this.formBuilder.group({
      _id: [this.episode?.[EpisodeProperty.id] || ''],
      order: [this.episode?.[EpisodeProperty.order] || this.show[ShowProperty.episodes].length + 1, Validators.required],
      topic: [this.episode?.[EpisodeProperty.topic] || ''],
      about: [this.episode?.[EpisodeProperty.about] || ''],
      guests: [this.episode?.[EpisodeProperty.guests] || ''],
      showId: [this.episode?.[EpisodeProperty.showId] || this.show?.[ShowProperty.id] || ''],
      hasQuestions: [this.episode?.[EpisodeProperty.hasQuestions] || false],
      questionsDeadline: [this.episode?.[EpisodeProperty.questionsDeadline]]
    });
  }

  ngOnInit(): void{
      // @ts-ignore
      this.guestsService.getGuests().subscribe(guests => {
        this.allGuests = guests;
        this.receivedGuestsControl = guests
        if (this.episode) {
          // Extract tag names from the article
          const episodeGuestsNames = this.episode?.guests?.map(guest => guest[GuestProperty.name]);

          // Filter tags that are present in the article
          const selectedTags = this.allGuests.filter(guest => episodeGuestsNames?.includes(guest[GuestProperty.name]));

          // Remove selectedTags from allTags
          this.allGuests = this.allGuests.filter(guest => !episodeGuestsNames?.includes(guest[GuestProperty.name]));

          // Assign selectedTags to a separate property, if needed
          this.selectedGuests = selectedTags;
        }
      });
  }


  onSubmit() {
    if(this.episode !== undefined){
      const formData =
        {
          ...this.showForm.value,
          [EpisodeProperty.guests]: this.selectedGuests,
          oldOrder: this.oldOrder,
        };
      this.episodeServices.update(formData).subscribe(
        (result:any) => {
          this.episodeUpdated.emit(formData); // Emit the event with the created editor
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    } else if (this.showForm.valid) {
      const selectedGuestsToSend = this.selectedGuests.map(guest => ({ id: guest[GuestProperty.id], name: guest[GuestProperty.name] }));
      const formData = {
        ...this.showForm.value,
        [EpisodeProperty.guests]: selectedGuestsToSend
      };
      console.log(formData)
      // Send the form data to the backend
      this.episodeServices.create(formData).subscribe(
        (result:any) => {
          this.episodeCreated.emit(result); // Emit the event with the created editor
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error creating survey:', error);
          // Handle the error as needed
        }
      );
    }
  }


  onEnter(): void {
    const trimmedInput = this.userInput.trim();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.receivedGuestsControl.find(tag => tag[GuestProperty.name].toUpperCase() === trimmedInput.toUpperCase());

    if (this.selectedGuests.find(tag => tag[GuestProperty.name] === trimmedInput)) {
      this.snackBar.open("Gost je već odabran", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
    } else if(existingTag){
      this.addGuest(existingTag);
    }
    else {
      // If the tag doesn't exist, add it to the database and then add it to the selected tags
      const newGuest: any = { name: trimmedInput };
      this.guestsService.create(newGuest).subscribe(
        addedTag => {
          this.allGuests.push(addedTag);
          this.addGuest(addedTag);
        },
        error => {
          console.error('Failed to add new tag:', error);
          // Handle the error (e.g., show a message to the user)
        }
      );
    }
    this.userInput = ''

  }

  addGuest(tag: Guest): void {
    this.selectedGuests.push(tag);
    this.allGuests = this.allGuests.filter(t => t !== tag);
  }

  filterTags(): Guest[] {
    return this.allGuests
      .filter(tag => tag[GuestProperty.name].toLowerCase().includes(this.userInput.toLowerCase()))
      .slice(0, 20);
  }

  removeTag(selectedTag: Guest): void {
    this.selectedGuests = this.selectedGuests.filter(tag => tag !== selectedTag);
    this.allGuests.push(selectedTag);
  }

}
