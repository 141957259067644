import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter} from "rxjs";
import {VodService} from "../../../../services/vod.service";
import {resolveObjectURL} from "node:buffer";

@Component({
  selector: 'app-options',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './options.component.html',
  styleUrl: './options.component.css'
})
export class OptionsComponent implements OnInit{

  public archiveInfo: any[] = []
  public liveInfo: any[] = []

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private vodService: VodService,
  ) {
  }

  ngOnInit(): void{
    this.vodService.getArchiveInfo('').subscribe( result => {
      // @ts-ignore
      this.archiveInfo = result.feed;
      // @ts-ignore
    })
    this.vodService.getChannelsInfo().subscribe( result => {
      // @ts-ignore
      this.liveInfo = result.feed;
    })
  }

}
