import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";
import {Show} from "../../../models/show.model";
import {CreateShowDialogComponent} from "./create-show-dialog/create-show-dialog.component";
import {FormBuilder} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ChairService} from "../../../services/chair.service";
import {ArticlesService} from "../../../services/articles.service";
import {NgForOf} from "@angular/common";
import {ShowService} from "../../../services/show.services";
import {ArticlesProperty} from "../../../models/articles-property.enum";

@Component({
  selector: 'app-administrator-shows',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf
  ],
  templateUrl: './administrator-shows.component.html',
  styleUrl: './administrator-shows.component.css'
})
export class AdministratorShowsComponent {
  shows!: Show[];

  private loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private showService: ShowService,
    private articlesService: ArticlesService,
  ) {
    /*this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1] // Default to -1 (for voting)
    });*/
  }

  ngOnInit(): void {
    this.showService.getShows().subscribe(
      (shows: Show[]) => {
        this.shows = shows; // Assign the retrieved courses to the courses property
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      }
    );

  }

  openCreateShowDialog(show?: Show) {
    const dialogRef = this.dialog.open(CreateShowDialogComponent, {
      width: '500px',
      data: show
    });dialogRef.componentInstance.showCreated.subscribe((createdShow: Show) => {
      // Update this.editors if an editor is created successfully
      if (createdShow) {
        this.shows.push(createdShow);

        // Show snackbar with successful message
        this.snackBar.open("Show created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
