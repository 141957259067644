<div class="categories">

  <div class="left">
    <div *ngIf="categories?.length !== 0">
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let category of categories" cdkDrag>
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
          <p>{{category[categoriesProperty.name]}}</p>
          <div class="options">
            <button *ngIf="!isEditing(); else cancelEdit"
                    (click)="insertForm(category)"
                    class="edit-button"
                    mat-flat-button
                    color="primary">
              Edit
            </button>
            <button (click)="removeCategory(category[categoriesProperty.id]!)"
                    class="edit-button"
                    mat-flat-button
                    color="warn">
              Delete
            </button>
          </div>
          <ng-template #cancelEdit>
            <button (click)="restartForm()"
                    class="edit-button"
                    mat-flat-button
                    color="warn">
              Cancel
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <button *ngIf="this.differentOrder"
            (click)="updateCategories()"
            mat-flat-button
            color="primary">
      Upload
    </button>
  </div>

  <!--<div *ngIf="isAdmin()" class="right">-->
  <div class="right">
    <form [formGroup]="form" class="form_container">
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Ime</mat-label>
        <input matInput [formControlName]="categoriesProperty.name" maxlength="50">
      </mat-form-field>

      <button *ngIf="!isEditing(); else updateButton"
              (click)="addCategory()"
              mat-flat-button
              color="primary">
        Upload
      </button>
      <ng-template #updateButton>
        <button (click)="updateCategory()"
                mat-flat-button
                color="primary">
          Update
        </button>
      </ng-template>
    </form>
  </div>
</div>
