import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {NgForOf} from "@angular/common";
import {Articles} from "../../../../models/articles.model";
import {preserveWhitespacesDefault} from "@angular/compiler";
import {FormsModule} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-number-of-winners-dialog',
  templateUrl: './number-of-winners-dialog.component.html',
  standalone: true,
  imports: [
    MatButton,
    NgForOf,
    FormsModule
  ],
  styleUrls: ['./number-of-winners-dialog.component.css']
})
export class NumberOfWinnersDialogComponent {

  numberOfUsers: number = 1;
  constructor(
    public dialogRef: MatDialogRef<NumberOfWinnersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { correctAnswers: number, },
    private snackBar: MatSnackBar,

  ) {

  }

  updateNumberOfUsers() {
    // Handle changes here if needed
    console.log('Current value of numberOfUsers:', this.numberOfUsers);
    console.log(this.data.correctAnswers)
  }

  onSubmit() {
    console.log(this.data.correctAnswers)
    console.log(this.numberOfUsers)
    console.log(this.numberOfUsers !< 1)
    if (this.numberOfUsers >= 1 && this.numberOfUsers < this.data.correctAnswers) {
      this.dialogRef.close(this.numberOfUsers);
    }
    else {
      console.log("SHIT")
      this.snackBar.open('Broj koji ste unijeli ne moze biti procesuiran', 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

}
