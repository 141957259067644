import { Component } from '@angular/core';

@Component({
  selector: 'app-administrator-dashboard',
  templateUrl: './administrator-dashboard.component.html',
  standalone: true,
  styleUrls: ['./administrator-dashboard.component.css']
})
export class AdministratorDashboardComponent {

}
