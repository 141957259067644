import {inject} from '@angular/core';
import {ResolveFn, Router} from '@angular/router';
import {map, of} from 'rxjs';
import {Route} from "../constants/route.constants";
import {Articles} from "../models/articles.model";
import {ArticlesService} from "../services/articles.service";
import {catchError} from "rxjs/operators";
import {CategoryArticles} from "../models/category-articles.model";

/*@Injectable({providedIn: 'root'})
export class ArticlesResolver implements Resolve<CategoryArticles> {

  constructor(private articlesService: ArticlesService,
              private categoriesService: CategoriesService)
  { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<CategoryArticles> | Promise<CategoryArticles> | CategoryArticles {
    const categories = route.paramMap.get(Route.CATEGORY.substring(1));
    this.articlesService.categoryName = categories;
    let page: string | null;
    if(route.queryParamMap.get('page') == null){
      page = '1';
    }
    else {
      page = route.queryParamMap.get('page');
    }
    this.articlesService.pageNumber = page;

    const articles = this.articlesService.getArticlesByCategory();
    if(!articles) {
      throw 'Item not found!';
    }
    return articles;
  }
}*/

export const ArticlesResolver: ResolveFn<CategoryArticles | undefined> = (route, state) =>{
  let articlesService = inject(ArticlesService)
  let router = inject(Router);

  articlesService.categoryName = route.paramMap.get(Route.CATEGORY.substring(1));
  let page: string | null;
  if(route.queryParamMap.get('page') == null){
    page = '1';
  }
  else {
    page = route.queryParamMap.get('page');
  }
  articlesService.pageNumber = page;

  return articlesService.getArticlesByCategory().pipe(
    map((articles) => {
      if (articles) {
        return articles;
      } else {
        router.navigate(['']);
        return undefined;
      }
    }),
    catchError((error) => {
      console.error('Error fetching article:', error);
      router.navigate(['']);
      return of(undefined);
    })
  );

}

