import {inject} from '@angular/core';
import {ResolveFn, Router} from '@angular/router';
import {Route} from "../constants/route.constants";
import {Articles} from "../models/articles.model";
import {ArticlesService} from "../services/articles.service";
import {map, of} from "rxjs";
import {catchError} from "rxjs/operators";

export const ArticleResolver: ResolveFn<Articles | undefined> = (route, state) =>{
  let articlesService = inject(ArticlesService)
  let router = inject(Router);

  const id = route.paramMap.get(Route.ID.substring(1));
  return articlesService.getArticle(id!).pipe(
    map((article) => {
      if (article) {
        return article;
      } else {
        router.navigate(['']);
        return undefined;
      }
    }),
    catchError((error) => {
      console.error('Error fetching article:', error);
      router.navigate(['']);
      return of(undefined);
    })
  );

  // return articlesService.getArticle(id!)
}
