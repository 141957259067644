<div class="dashboard">
  <div class="options">
    <div class="welcome">
      <h1>Dobrodošli</h1>
    </div>
    <div class="selection">
      <div class="live-tv">
        <div class="description">
          <h3></h3>
        </div>
        <div class="preview">
          @for(channels of liveInfo; track channels){
            <div class="category">
              <img src="https://backend.hayat.ba/logos/large/{{channels.ch}}.png">
            </div>
          }
        </div>
        <div class="button-container">
          <!--<button [routerLink]="'live-tv'">
            LIVE TV
          </button>-->
          <a href="https://www.hayat.ba/gledaj.php">
            LIVE TV
          </a>
        </div>
      </div>
      <div class="archive">
        <div class="description">
          <h3></h3>
        </div>
        <div class="preview">
          @for(category of archiveInfo; track category){
            <div class="category">
              <img src="https://backend.hayat.ba/uploads/tx_ssvodmeta/{{category.cat_pict}}">
            </div>
          }
        </div>
        <div class="button-container">
          <!--<button [routerLink]="'videoteka'">
            VIDEOTEKA
          </button>-->
          <a href="https://www.hayat.ba/play.php">VIDEOTEKA</a>
        </div>
      </div>
    </div>
  </div>
</div>
