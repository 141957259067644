import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {CreateEditorDialogComponent} from "./create-editor-dialog/create-editor-dialog.component";
import {Chair} from "../../../models/chair.model";
import {EditorsServices} from "../../../services/editors.services";
import {ArticlesService} from "../../../services/articles.service";
import {Articles} from "../../../models/articles.model";
import {ChairProperty} from "../../../models/chair-property.enum";
import {CategoriesProperty} from "../../../models/categories-property.enum";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-administrator-editors',
  templateUrl: './administrator-editors.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    NgIf
  ],
  styleUrls: ['./administrator-editors.component.css']
})
export class AdministratorEditorsComponent {
  surveyForm: FormGroup;
  editors!: Chair[];
  selectedEditor!: Chair;
  articles: Articles[] | undefined;

  private page = 1;
  private loading = false;
  private surveysSubscription: Subscription | undefined;
  private chairProperty = ChairProperty;


  constructor(
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private editorsServices: EditorsServices,
      private articlesService: ArticlesService,
  ) {
    this.surveyForm = this.formBuilder.group({
      surveyType: ['survey', Validators.required],
      question: ['', Validators.required],
      answerOptions: this.formBuilder.array([
        this.formBuilder.control(''), // Add your initial answer options here
        this.formBuilder.control(''),
      ]),
      correctAnswer: [-1] // Default to -1 (for voting)
    });
  }


  ngOnInit(): void {
    this.editorsServices.getEditors().subscribe(
        (editors: Chair[]) => {
          this.editors = editors; // Assign the retrieved courses to the courses property
        },
        (error) => {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
          console.error('Error getting editors:', error);
        }
    );
  }


  selectEditor(editor: any) {
    this.selectedEditor = editor
    this.page = 1;
    this.loadArticles()
  }

  openCreateEditorDialog(editor?: Chair) {
    const dialogRef = this.dialog.open(CreateEditorDialogComponent, {
      width: '500px',
      data: editor
    });dialogRef.componentInstance.editorCreated.subscribe((createdEditor: Chair) => {
      // Update this.editors if an editor is created successfully
      if (createdEditor) {
        this.editors.push(createdEditor);

        // Show snackbar with successful message
        this.snackBar.open("Editor created successfully", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteEditor(chairId: string): void{
    this.editorsServices.delete(chairId).subscribe(
      (result) => {
        const index = this.editors.findIndex(editor => editor[ChairProperty.id] === chairId);

        if (index !== -1) {
          this.editors.splice(index, 1); // Remove the editor from the array
        }
        this.snackBar.open("Profil uredno izbrisan", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      }
    );
  }


  loadArticles() {
    this.articlesService.getArticlesByEditor(this.selectedEditor[ChairProperty.id], this.page).subscribe(
        (articles: any[]) => {
          this.articles = articles;
          this.page++;
        },
        (error) => {
          console.error('Error fetching expired surveys:', error);
        }
    );
  }


  onScroll() {
    // Detect when the user has scrolled to the bottom of the page
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadArticles();
    }
  }

    protected readonly CategoriesProperty = CategoriesProperty;
}
