import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ReturnUrlService} from "../../services/return-url.service";
import {CategoriesService} from "../../services/categories.services";
import {UserService} from "../../services/user.service";
import {filter} from "rxjs";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {MatMenuModule} from "@angular/material/menu";

@Component({
  selector: 'app-gledaj-hayat-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgForOf,
    NgIf,
    RouterLink,
    MatMenuModule
  ],
  templateUrl: './gledaj-hayat-header.component.html',
  styleUrl: './gledaj-hayat-header.component.css'
})
export class GledajHayatHeaderComponent {
  sidebarOpen: boolean = false;

  public selectedRoute: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private router: Router,
    public authService: AuthService,
    private returnUrlService: ReturnUrlService,
    public userService: UserService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.selectedRoute = ((event as NavigationEnd).url)?.substring(1);
      console.log(this.selectedRoute)
    });
  }

  isLogedIn(): boolean{
    return this.authService.isLoggedIn();
  }

  updateTokenExists(): boolean {
    return this.authService.getToken() !== null;
  }

  public async logoutUser(): Promise<void> {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    await this.userService.logoutProfile();
    this.router.navigate(['/']);
  }

  redirectToLogin(): void {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    this.router.navigate(['/login']);
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }
}
