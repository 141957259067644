import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {SurveysService} from "../../services/survey.service";
import {Surveys} from "../../models/surveys.model";
import {SurveysProperty} from "../../models/surveys-property.enum";
import {User} from "../../models/user.model";
import {UserService} from "../../services/user.service";
import {SurveyConfirmationDialogComponent} from "./survey-confirmation-dialog/survey-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {ReturnUrlService} from "../../services/return-url.service";
import {UserProperty} from "../../models/user-property.enum";
import {Observable, of} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DecimalPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  animations: [
    trigger('fadeInOut', [
      state('true', style({
        filter: 'blur(3px)',
      })),
      state('false', style({
        filter: 'blur(0px)',
      })),
      transition('false <=> true', animate('2s')),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgClass,
    DecimalPipe,
    NgForOf
  ],
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {


  @Input() surveyId!: string | null;
  @Input() survey!: Surveys | undefined;

  user: User | undefined;

  expired = false;
  blur = false;

  selectedAnswer: number | undefined;

  hasUserTakenSurvey: number | null = null;
  hasUserTakenTrivia: number | null = null;
  isTrivia: boolean = false;
  isSurvey: boolean = false;

  public deadline: Date | undefined;

  timeRemaining: { days: number, hours: number, minutes: number, seconds: number } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  public surveysProperty = SurveysProperty;
  constructor(
    private surveysService: SurveysService,
    private returnUrlService: ReturnUrlService,
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {
    this.expired = false
  }

  async ngOnInit() {
    if (!this.survey) {
      if (!this.surveyId) {
        this.route.paramMap.subscribe(params => {
          this.surveyId = params.get('id');
        });
      }
      try {
        this.survey = await this.surveysService.getSurveysById(this.surveyId!).toPromise();
        if (this.oldDeadline()) {
          this.expired = true
        }
      } catch (error) {
        console.error('Failed to get channels:', error);
      }
    }
    try {
      this.user = await this.userService.getUser();
    } catch (error) {
      console.error('Error fetching user:', error);
      this.user = undefined;
    }
    if (this.oldDeadline()) {
      this.expired = true
      //this.cdr.detectChanges();
    } else if (this.survey?.[this.surveysProperty.surveyType] == 'survey') {
      if (this.user && this.user.surveys?.some((completedSurvey: {
        id: string | undefined;
      }) => completedSurvey.id === this.survey?.[this.surveysProperty.id])) {
        const completedTrivia = this.user.surveys.find(
          (completedSurvey: {
            id: string | undefined;
            answer: number | undefined;
          }) => completedSurvey.id === this.survey?.[this.surveysProperty.id]
        );
        this.hasUserTakenTrivia = completedTrivia.answer;
        //this.cdr.detectChanges();
        this.isTrivia = true;
      }
      this.hasUserTakenSurvey = this.surveysService.hasUserTakenSurvey(this.survey[this.surveysProperty.id]!);
      this.isSurvey = true;

      this.hasUserTakenTrivia = null;
      this.isTrivia = false;
    } else if (this.survey?.[this.surveysProperty.surveyType] == 'trivia') {

      if (this.user && this.user.surveys?.some((completedSurvey: {
        id: string | undefined;
      }) => completedSurvey.id === this.survey?.[this.surveysProperty.id])) {
        const completedTrivia = this.user.surveys.find(
          (completedSurvey: {
            id: string | undefined;
            answer: number | undefined;
          }) => completedSurvey.id === this.survey?.[this.surveysProperty.id]
        );
        this.hasUserTakenTrivia = completedTrivia.answer;
        //this.cdr.detectChanges();
        this.isTrivia = true;
      } else {
        if(this.user !== undefined){
          try {
            const localData = this.userService.getLocalData();
            //await this.userService.syncLocalDataWithBackend(localData, this.user).toPromise();
          } catch (error) {
            console.error('Error syncing local data with backend', error);
          }
        }
        this.hasUserTakenTrivia = this.surveysService.hasUserTakenSurvey(this.survey[this.surveysProperty.id]!);
        //this.cdr.detectChanges();
        this.isTrivia = true;
      }

      this.hasUserTakenSurvey = null;
      this.isSurvey = false;


      if (this.user === undefined && this.hasUserTakenTrivia) {
        setTimeout(() => {
          this.blur = true;
          //this.cdr.detectChanges();
        }, 2000);
      }

    }

    setInterval(() => {
      this.updateCountdown();
    }, 1000);
    // @ts-ignore
    this.deadline = new Date(this.survey?.[this.surveysProperty.deadline]);

    // Trigger change detection manually
    //this.cdr.detectChanges();


  }


  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(SurveyConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Da li ste sigurni?',
        message: 'Nakon potvrde vise necete moci promijeniti vas odgovor.',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        // User clicked "Yes", perform the submission logic here
        this.submitGift();
        // Trigger change detection manually
        //this.cdr.detectChanges();
      }
    });
  }

  submitSurvey() {
    if (this.selectedAnswer !== undefined && !this.hasUserTakenSurvey) {
      if (this.user !== undefined) {
        let data = {
          userId: this.user[UserProperty.id],
          answer: this.selectedAnswer,
        }
        this.surveysService.updateSurveyResponse(this.survey?.[this.surveysProperty.id], data).subscribe(
          (result) => {
            this.surveysService.setSurveyResponse(
              this.survey?.[this.surveysProperty.id]!,
              this.selectedAnswer!).subscribe(
              (response) => {
                // Increment total count
                if (this.survey) {
                  this.survey[SurveysProperty.totalCount] = (this.survey[SurveysProperty.totalCount] ?? 0) + 1;
                  // @ts-ignore
                  this.survey[SurveysProperty.answerOptions][this.selectedAnswer].count++;
                  // @ts-ignore
                  this.hasUserTakenSurvey = this.selectedAnswer;
                }
                // @ts-ignore
                this.hasUserTakenTrivia = this.selectedAnswer;
                this.snackBar.open("Vaš odgovor je spašen", 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  panelClass: ['hayat-snackbar'],
                });
              },
              (error) => {
                console.log("Error: unable to save survey response locally!")
              }
            )
          }
        )
        this.user[UserProperty.surveys].push(data);
      } else {
        this.surveysService.increaseResponse(
          this.survey?.[this.surveysProperty.id]!,
          this.selectedAnswer!
        ).subscribe(
          (response) => {
            this.surveysService.setSurveyResponse(
              this.survey?.[this.surveysProperty.id]!,
              this.selectedAnswer!,).subscribe(
              (response) => {
                // Increment total count
                if (this.survey) {
                  this.survey[SurveysProperty.totalCount] = (this.survey[SurveysProperty.totalCount] ?? 0) + 1;
                  // @ts-ignore
                  this.survey[SurveysProperty.answerOptions][this.selectedAnswer].count++;
                }
                // @ts-ignore
                this.hasUserTakenSurvey = this.selectedAnswer;

              },
              (error) => {
                console.log("Error: unable to save survey response locally!")
              }
            )
          },
          (error) => {
            console.log("Error: unable to increase survey response count!")
          }
        )
      }
    }
  }

  submitGift() {
    if (this.selectedAnswer !== undefined && this.hasUserTakenTrivia == null) {
      if (this.user !== undefined) {
        let data = {
          userId: this.user[UserProperty.id],
          answer: this.selectedAnswer,
        }
        this.surveysService.updateSurveyResponse(this.survey?.[this.surveysProperty.id], data).subscribe(
          (result) => {
            this.surveysService.setSurveyResponse(
              this.survey?.[this.surveysProperty.id]!,
              this.selectedAnswer!).subscribe(
              (response) => {
                // Increment total count
                if (this.survey) {
                  this.survey[SurveysProperty.totalCount] = (this.survey[SurveysProperty.totalCount] ?? 0) + 1;
                  // @ts-ignore
                  this.survey[SurveysProperty.answerOptions][this.selectedAnswer].count++;
                }
                // @ts-ignore
                this.hasUserTakenTrivia = this.selectedAnswer;

                this.snackBar.open("Sudjelujete u dodjeli poklona", 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  panelClass: ['hayat-snackbar'],
                });
              },
              (error) => {
                console.log("Error: unable to save survey response locally!")
              }
            )
          }
        )
        this.user[UserProperty.surveys].push(data);
      } else {
        this.surveysService.setSurveyResponse(
          this.survey?.[this.surveysProperty.id]!,
          this.selectedAnswer!,).subscribe(
          (response) => {
            // Increment total count
            if (this.survey) {
              this.survey[SurveysProperty.totalCount] = (this.survey[SurveysProperty.totalCount] ?? 0) + 1;
              // @ts-ignore
              this.survey[SurveysProperty.answerOptions][this.selectedAnswer].count++;
            }
            // @ts-ignore
            this.hasUserTakenTrivia = this.selectedAnswer;
          },
          (error) => {
            console.log("Error: unable to save survey response locally!")
          }
        )
        setTimeout(() => {
          this.blur = true
          //this.cdr.detectChanges();
        }, 2000);

      }
    }
  }

  redirectToLogin(): void {
    const returnUrl = this.router.url;
    this.returnUrlService.setReturnUrl(returnUrl);
    this.router.navigate(['/login']);
  }

  oldDeadline(): boolean {
    // @ts-ignore
    const surveyDeadline = new Date(this.survey?.[this.surveysProperty.deadline]);
    const currentDate = new Date();
    return surveyDeadline < currentDate
  }

  calculateTimeRemaining(): void {
    const now = new Date();
    // @ts-ignore
    const timeDifference = this.deadline.getTime() - now.getTime();

    if (timeDifference <= 0) {
      // Deadline has passed
      this.timeRemaining = {days: 0, hours: 0, minutes: 0, seconds: 0};
    } else {
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      this.timeRemaining = {
        days: days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60
      };
    }
    //this.cdr.detectChanges();

  }

  updateCountdown(): void {
    this.calculateTimeRemaining();
  }

  formatNumber(value: number): string {
    // Ensure the number has two digits
    return value < 10 ? `0${value}` : `${value}`;
  }

  copySurveyLink(): void {
    navigator.clipboard.writeText("www.proba.hayat.ba/survey/" + this.survey?.[this.surveysProperty.id]).then(() => {
      this.snackBar.open("Link ankete kopiran", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    })
  }

  calculatePercentage(count: number, totalCount?: number): number {
    if (totalCount === undefined || isNaN(count) || isNaN(totalCount) || totalCount <= 0) {
      return 0; // Return 0 if totalCount is undefined or not a valid number, or if it's non-positive
    }

    return (count / totalCount) * 100;
  }
}
