export enum EpisodeProperty {
  id = '_id',
  order = 'order',
  topic = 'topic',
  about = 'about',
  showId = 'showId',
  guests = 'guests',
  hasQuestions = 'hasQuestions',
  questions = 'questions',
  questionsDeadline = 'questionsDeadline'
}
