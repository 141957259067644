import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {ResolverResponse} from "../../constants/resolver-response.constants";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {Categories} from "../../models/categories.model";
import {isPlatformBrowser} from "@angular/common";
import {HeaderComponent} from "../../common/header/header.component";
import {CategoriesService} from "../../services/categories.services";
import {FooterComponent} from "../../common/footer/footer.component";
import {response} from "express";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
  ],
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  public categories: Categories[] = [];

  categoriesSubscription: any;

  constructor(
    @Inject(PLATFORM_ID)private platformID: Object,
    private activatedRoute: ActivatedRoute,
    private categoriesService: CategoriesService,
    private router: Router,
    private renderer: Renderer2,
  ) {

  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      const scriptElement1 = this.renderer.createElement('script');
      const scriptContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1723629237806-0'); });`;
      scriptElement1.text = scriptContent;
      this.renderer.appendChild(document.body, scriptElement1);
    }
  }
}
