import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {mergeMap, Observable, of, tap, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../models/user.model';
import {SignInForm} from "../models/sign-in-form.interface";
import {AuthResponse} from "../models/auth-response.interface";
import {AuthService} from "./auth.service";
import {Chair} from "../models/chair.model";
import {ReturnUrlService} from "./return-url.service";
import {Router} from "@angular/router";
import {Editor} from "../models/editor.model";
import {ChairProperty} from "../models/chair-property.enum";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ChairService {

  private readonly baseUrl: string = `${environment.backendUrl}/chairs`;

  private chair: Chair | undefined;
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private returnUrlService: ReturnUrlService,
  ) {

  }

  public getChairs(): Observable<Chair[]> {
    return this.http.get<Chair[]>(`${this.baseUrl}`);
  }

  public getSignatures(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/signatures`);
  }

  public getChairById(id: number): Observable<User> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });

    return this.http.get<User>(`${this.baseUrl}/${id}`, {headers});
  }

  public updateChair(user: Chair): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    return this.http.put<Chair>(`${this.baseUrl}/${user._id}`, user, {headers});
  }

  public create(chair: Chair): Observable<Chair> {
    return this.http.post<Chair>(`${this.baseUrl}`, chair);
  }

  public delete(chairId: string | undefined): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${chairId}`);
  }


  public login(signInForm: SignInForm): Observable<void> {
    const body = {
      email: signInForm.email,
      password: signInForm.password,
      role: signInForm.role,
    };

    return this.authService.logIn(body)
  }


  /*public fetchUser(role: string): Observable<void> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });
    if(role === 'ch'){
      return this.http.get<Chair>(`${this.baseUrl}/ch/whoami`, { headers }).pipe(
        mergeMap(response => {
          this.chair = response;
          this.profile = undefined;
          return of(undefined);
        })
      );
    }
    else {
      return this.http.get<User>(`${this.baseUrl}/whoami`, { headers }).pipe(
        mergeMap(response => {
          this.profile = response;
          /!*CHECK LOCAL STORAGE FOR SURVEY RESPONSES*!/
          console.log(this.profile)
          this.chair = undefined;
          return of(undefined);
        })
      );
    }


  }*/

 /* public fetchUser(role: string): Observable<Observable<undefined>> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });

    let userObservable: Observable<User | Chair>;

    if (role === 'ch') {
      userObservable = this.http.get<Chair>(`${this.baseUrl}/whoami`, {headers});
    } else {
      userObservable = this.http.get<User>(`${this.baseUrl}/whoami`, {headers});
    }

    return userObservable.pipe(
      mergeMap(async response => {
        if (role === 'ch') {
          // @ts-ignore
          this.chair = response;
          this.profile = undefined;
        } else {
          // @ts-ignore
          this.profile = response;
          console.log(this.profile);

          // Retrieve survey responses from local storage
          const surveyResponsesFromLocalStorage = this.getSurveyResponsesFromLocalStorage();

          // Compare with user profile and update if needed
          this.updateUserProfileWithLocalResponses(surveyResponsesFromLocalStorage);

          console.log(this.profile)

          this.chair = undefined;
        }
        return of(undefined);
      })
    );
  }*/


  public checkToken(): Observable<any> {
    return of(undefined)
  }

  /*CHAIR*/
  public getChair(): Observable<Chair> {
    if (this.chair == undefined) {
      return this.fetchChair().pipe(
        tap(response => {
          this.chair = response;
        })
      );
    }
    return of(this.chair);
  }

  public logout(): void {
    this.chair = undefined
  }

  public isAdmin(): boolean{
    if(this.chair){
      return this.chair[ChairProperty.isAdmin];
    }
    return false
  }

  public fetchChair(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });

    return this.http.get<Chair>(`${this.baseUrl}/whoami`, { headers }).pipe(
      catchError(error => {
        // Handle the error here, e.g., log it
        console.error('Error fetching chair:', error);

        this.authService.logout()
        // Redirect the user to the login page or another appropriate page
        this.router.navigate(['/admin']); // Adjust the route accordingly

        // Propagate the error to the subscriber
        return of(undefined);
      })
    );
  }

 /* public fetchChair(): Observable<Observable<undefined>> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + `${this.authService.getToken()}`
    });

    let chairObservable: Observable<Chair>;

    return this.http.get<Chair>(`${this.baseUrlC}/whoami`, {headers}).pipe(
      mergeMap(async response => {
        // @ts-ignore
        this.chair = response;
        this.profile = undefined;

        return of(undefined);
      })
    );

  }*/

}
