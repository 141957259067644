<div class="terms-of-use">
  <h1>
    Opšti uslovi korištenja
  </h1>
  <p><strong>Na osnovu Opće uredbe Evropske unije o zaštiti podataka („GDPR“) 2016/679 Evropskog parlamenta i Vijeća EU
    o zaštiti pojedinaca u vezi s obradom ličnih podataka i slobodnom kretanju takvih podataka, koja mijenja trenutna
    pravila vezana za zaštitu podataka, Hayat.ba mijenja opće uvjete korištenja u skladu s navedenom uredbom.</strong>
  </p>


  <p>Korištenjem bilo kojeg dijela domene hayat.ba prihvatate sve uvjete korištenja navedene u Općim uvjetima
    korištenja, kao i sva specifična pravila koja jesu ili mogu biti donesena za pojedine sastavne dijelove domene
    hayat.ba. Hayat.ba će biti kontrolor podataka (tj. određivat će svrhe i načine obrade podataka). Sve podatke koje
    obrađuje Hayat.ba nakon primanja podataka štitit će u skladu s Uredbom o zaštiti podataka. Hayat.ba će, također,
    pružiti pomoć u slučaju da korisnik čiji se podaci obrađuju želi ostvariti svoje pravo na pristup, ispravljanje,
    brisanje ili prenos podataka, kao i u slučaju zahtjeva od nadležnih tijela da se dokaže ispunjenje obaveza. Takve
    odgovarajuće zaštitne mjere su standardne klauzule o zaštiti podataka koje je usvojila Komisija EU, uključujući
    dostupnost efikasnih pravnih lijekova, među ostalim onih za dobijanje efikasne sudske zaštite i traženje naknade.
    Navedene mjere odnose se na obradu ličnih podataka, načela tehničke i integrirane zaštite podataka. Podaci koje
    prikuplja i obrađuje Hayat.ba koriste se radi identifikacije korisnika i sklapanja obligacionih odnosa. Podaci su
    dostupni uposlenicima koji rade na servisima te uposlenicima koji rade na održavanju servisa /aplikacija Hayat.ba i
    čuvaju se trajno, ne brišu se.</p>


  <p>Ukoliko Hayat.ba bude obrađivao podatke koji ne pripadaju korisničkom ugovoru, takvi podaci obrađivat će se u
    skladu s izjavom o privatnosti koja se odnosi na korisnike Hayat.ba.</p>


  <p>Pretpostavlja se da ste korištenjem bilo kojeg dijela domene hayat.ba, u svakom trenutku upoznati s aktuelnim
    sadržajem Općih uvjeta korištenja te svim specifičnim pravilima koja su donesena za pojedine sastavne dijelove
    domene hayat.ba, te da ste ih u potpunosti razumjeli, prihvatili i samim prihvatom saglasni ste da se vaši podaci
    obrađuju.</p>


  <p><strong>PRAVA I OBAVEZE KORISNIKA</strong></p>


  <p>Hayat.ba posjetiocima u dobroj namjeri osigurava korištenje sadržajem na domeni hayat.ba. Svi korisnici i
    posjetioci imaju pravo besplatno služiti se sadržajem, pod uvjetom da ne krše odredbe Općih uvjeta korištenja te
    svih drugih javno dostupnih dokumenata kojima se regulira korištenje domene hayat.ba. Hayat.ba sastoji se od
    vlastitih sadržaja, preuzetih sadržaja, sadržaja sastavljenih od posjetilaca i korisnika te veza na druge stranice
    izvan domene hayat.ba. Prava korisnika su da imaju pristup dostavljenim podacima koje su naveli korisnici. Korisnici
    na zahtjev imaju pravo na brisanje podataka koje su dostavili ukoliko to ne dovodi u pitanje slobodu izražavanja i
    istraživanja.</p>


  <p><strong>RIZICI</strong></p>


  <p>Korištenjem sadržaja internet-stranice hayat.ba korisnik prihvata sve rizike koji nastaju iz njihovog korištenja te
    prihvata koristiti njihov sadržaj isključivo za ličnu upotrebu i na vlastitu odgovornost.</p>


  <p><strong>ODRICANJE OD ODGOVORNOSTI</strong></p>


  <p>Hayat.ba se u potpunosti odriče svake odgovornosti koja je na bilo koji način bila povezana s korištenjem domene
    hayat.ba, za bilo koje radnje korisnika povezanih s upotrebom ili zloupotrebom korištenja domene hayat.ba, kao i za
    svaku štetu koja može nastati korisniku ili bilo kojoj trećoj strani, a u vezi s upotrebom ili zloupotrebom
    korištenja domene. Hayat.ba. će informirati korisnike ukoliko dođe do otuđivanja&nbsp; ili zloupotrebe podataka, a
    koji se eventualno budu prikupljali.</p>


  <p><strong>TAČNOST INFORMACIJA</strong></p>


  <p>Informacije objavljene unutar domene hayat.ba ne moraju nužno biti potpune, iscrpne, precizne ili ažurirane te ne
    predstavljaju obavezujuće stručno mišljenje. Hayat.ba ne odgovara za bilo kakvu štetu koja može nastati kao
    posljedica korištenja bilo koje informacije objavljene unutar domene hayat.ba.</p>


  <p><strong>VEZE</strong></p>


  <p>Hayat.ba ima veze na druge internet-stranice. Ove veze objavljene su u dobroj namjeri. Hayat.ba ne može biti
    odgovorna ni za kakve sadržaje koji se nalaze izvan domene hayat.ba, a s kojima na ovakav način može biti
    povezana.</p>


  <p><strong>AUTORSKA I DRUGA PRAVA</strong></p>


  <p>Dokumenti, podaci i informacije objavljeni na domeni hayat.ba mogu se koristiti samo za individualne potrebe
    korisnika, uz poštivanje svih autorskih i vlasničkih prava te prava trećih osoba. Hayat.ba polaže autorska prava na
    sve vlastite sadržaje (tekstualne, vizualne i audiomaterijale, baze podataka i programski kod). Neovlašteno
    korištenje bilo kojeg dijela domene hayat.ba smatra se kršenjem autorskih prava i može rezultirati sudskim i drugim
    postupcima protiv prekršitelja.</p>


  <p>Ukoliko smatrate da sadržaj objavljen na portalu hayat.ba krši vaše autorsko, lično ili drugo pravo ili interes,
    ili na način da se vaši podaci obrađuju u svrhu izravnog marketinga, imate pravo prigovora na takvu obradu te
    zahtijevati objavu odgovora, ispravke ili brisanja podataka. Slučaj će biti u najkraćem roku razmotren, a sporni
    sadržaji bit će uklonjeni ili ispravljeni odmah po eventualnom ustanovljavanju istinitosti sadržaja žalbe. Sve
    pritužbe i prigovore možete slati na e-mail:&nbsp;<a href="mailto:digitala@hayat.ba">digitala&#64;hayat.ba</a>.
    Materijal poslat na ovaj e-mail će se smatrati pravovaljanim. Svi drugi oblici prigovora neće se smatrati
    relevantnim i portal hayat.ba nema obavezu postupiti prema njima.</p>


  <p><strong>ODNOS S DRUGIM SADRŽAJIMA</strong></p>


  <p>Domena hayat.ba sadrži i dokumente, podatke, informacije te veze prema drugim internet-stranicama napravljenim od
    trećih osoba. Hayat.ba nema nadzor nad navedenim dokumentima, podacima, informacijama ili drugim internet-stranicama
    te se u potpunosti odriče svake odgovornosti, uključujući one vezane za tačnost, potpunost i dostupnost sadržaja na
    internet-stranicama kreiranim od</p>


  <p>trećih osoba.</p>


  <p><strong>POLITIKA PRIVATNOSTI</strong></p>


  <p>Hayat.ba u najvećoj mogućoj mjeri štiti privatnost svojih korisnika. Hayat.ba se obavezuje da će u dobroj namjeri
    koristiti podatke dobijene od korisnika tokom korištenja domene hayat.ba te da privatne podatke neće distribuirati
    niti prodavati trećoj strani, osim uz dozvolu korisnika. Hayat.ba može, u skladu sa zakonom, prikupljati određene
    podatke o korisnicima dobijene tokom njihovog korištenja domene hayat.ba ili podatke unesene u postupku
    registracije. Hayat.ba obavezuje se da prikupljene privatne podatke neće bez dozvole svakog pojedinačnog korisnika
    na bilo koji način distribuirati. Hayat.ba se obavezuje da će čuvati privatnost korisnika u svim slučajevima, osim u
    slučaju kršenja ovih pravila ili nezakonitih aktivnosti korisnika. Hayat.ba, također, se obavezuje da će preduzeti
    odgovarajuće tehničke i organizacijske mjere radi poštivanja uvjeta ove uredbe, ispunjavajući načela tehničke
    zaštite podataka i integrirane zaštite podataka.</p>


  <p>Na pismeni zahtjev nadležnih tijela ili direktno zainteresiranih osoba, a u svrhu istrage, pokretanja ili vođenja
    sudskih ili drugih postupka, Hayat.ba može tim tijelima ili osobama dostaviti podatke koje prikupi o pojedinim
    osobama. Portal Hayat.ba nije odgovoran ukoliko usljed hakerskog napada dođe do otkrivanja korisničkih podataka.</p>


  <p><strong>GDPR</strong></p>


  <p>Voditelj zbirke osobnih podataka dužan je na moj zahtjev:</p>


  <ul>
    <li>dostaviti potvrdu o tome da li se Osobni podaci obrađuju ili ne;</li>
    <li>&nbsp;dati obavijest u razumljivom obliku o Osobnim podacima čija je obrada u toku te o izvoru tih podataka;
    </li>
    <li>&nbsp;omogućiti uvid u evidenciju zbirke Osobnih podataka te uvid u Osobne podatke i njihovo prepisivanje;</li>
    <li>&nbsp;dopuniti, izmijeniti ili brisati Osobne podatke ako su podaci nepotpuni, netačni ili neažurni te ako
      njihova obrada nije u skladu s odredbama Zakona o zaštiti osobnih podataka;
    </li>
    <li>&nbsp;dostaviti izvatke, potvrde ili ispise osobnih podataka sadržanih u zbirci osobnih podataka koji se na mene
      odnose, a koji moraju sadržavati i naznaku svrhe i pravnog temelja prikupljanja, obrade i korištenja tih podataka;
    </li>
  </ul>


  <p>Zahtjev se može podnijeti u bilo koje doba putem slijedeće adrese elektroničke pošte:<a
    href="mailto:digitala@hayat.ba">digitala&#64;hayat.ba</a>, a Voditelj zbirke osobnih podataka dužan je, o svom
    trošku, udovoljiti zahtjevu u roku od 30 dana od dana zaprimanja zahtjeva. Ovime potvrđujem i izjavljujem da sam
    upoznat/a sa svojim pravom da u cijelosti ili djelomično povučem dozvolu.</p>


  <p>U slučaju povlačenja dozvole u cijelosti, Voditelj zbirke osobnih podataka prestat će s daljnjom obradom Osobnih
    podataka te ih izbrisati iz zbirke osobnih podataka. U slučaju djelomičnog povlačenja dozvole, dozvola se može
    povući bilo u odnosu na davanje Osobnih podataka bilo kojem ili svim Ovlaštenim primateljima, bilo u odnosu na
    iznošenje Osobnih podataka u bilo koju ili sve države, osim države mog prebivališta, podnošenjem zahtjeva.
    Posljedica uskraćivanja davanja Osobnih podataka bit će nemogućnost sudjelovanja u bilo kojim marketinškim i drugim
    promidžbenim aktivnostima, odnosno nemogućnost ostvarivanja koristi u vezi sa takvim aktivnostima.</p>


  <p><strong>KOMENTARI NA PORTALU</strong></p>


  <p>Hayat.ba se ograđuje i nije odgovoran za sadržaj poruka i komentara objavljenih na portalu. Poruke i komentari na
    portalu predstavljaju stavove i mišljenja autora, a ne vlasnika, redakcije ili administracije portala. Kao korisnik
    portala zadržavate svu odgovornost za sadržaj poruka i komentara koje ste napisali i odričete svu odgovornost
    portala Hayat.ba. Kao korisnik, dužni ste poštivati privatnost drugih članova i odgovorni ste za zaštitu svoje
    privatnosti kroz materijale koje objavljujete i kontakte koje ostvarite posredstvom portala.</p>


  <p><strong>ŠTO SU KOLAČIĆI (COOKIES) I KOJA IM JE FUNKCIJA?</strong></p>


  <p>Kolačići (cookies) su tekstualne datoteke koje na računar korisnika sprema web-stranica koju korisnik posjeti.</p>


  <p>Internet-preglednik ima mogućnost korištenja kolačića i ako je u njegovim postavkama uključeno njihovo korištenje,
    server će snimiti kolačić na korisnički računar i koristit će ga pri sljedećem otvaranju iste stranice.</p>


  <p>Kolačići omogućavaju web-stranici da sazna korisnikove željene postavke, brzo i efikasno kretanje po stranicama
    zahvaljujući memoriranju korisničkih odabira, opcija i omiljenih postavki.</p>


  <p>Web-stranica može se posjetiti i ako su kolačići onemogućeni, no određene mogućnosti će biti onemogućene.</p>


  <p><strong>KOJE PODATKE SPREMAJU KOLAČIĆI?</strong></p>


  <p>Kolačići obično spremaju podatke poput preferiranog jezika stranice te adrese pa tako, pri svakom sljedećem
    otvaranju stranice, internet-preglednik šalje kolačiće koji pripadaju toj stranici i omogućava joj da prikaže
    informacije prilagođene korisničkim potrebama.</p>


  <p>Kolačići mogu spremiti i lične informacije (npr. korisničko ime ili e-mail), ali one mogu biti spremljene jedino
    ako korisnik to omogući.</p>


  <p>Kolačići ne mogu pristupiti informacijama koje im nisu date, a to uključuje i sadržaj korisničkog računara (poput
    datoteka, slika, dokumenata i sl). Svrha kolačića je dobronamjerna; nisu štetni i ne mogu nositi viruse, a usmjereni
    su na bolje iskustvo surfanja.</p>


  <p><strong>KAKO ONEMOGUĆITI KOLAČIĆE?</strong></p>


  <p>Zadane aktivnosti spremanja i slanja kolačića nisu vidljive, no svejedno se mogu promijeniti postavke korisničkog
    preglednika na način da korisnici sami biraju hoće li zahtjeve za spremanje kolačića odobriti ili odbiti, da pobrišu
    spremljene kolačiće automatski pri zatvaranju preglednika i dr.</p>


  <p>Postavke kolačića uređuju se u web-pregledniku, a njihovim onemogućavanjem neće se moći koristiti neke od
    funkcionalnosti web-stranica.</p>


  <p><strong>KAKVI KOLAČIĆI POSTOJE?</strong></p>


  <p>Razlikujemo privremene i stalne kolačiće. Privremeni se uklanjaju s korisničkog računara nakon zatvaranja
    preglednika, a stalni ostaju pospremljeni na računaru nakon zatvaranja preglednika.</p>


  <p>Također, razlikujemo kolačiće prve i treće strane. Kolačići prve strane obuhvataju već opisane (privremene i
    stalne), dok kolačići treće strane potječu od drugih partnerskih web-mjesta koja gledate, a nalaze se na
    web-stranici koju korisnik posjećuje. Pomoću njih web-stranice mogu pratiti korisničko korištenje interneta u
    marketinške svrhe.</p>


  <p><strong>REGISTRIRANI KORISNICI</strong></p>


  <p>Registriranjem korisnik je obavezan proći postupak registracije, unijeti istinite podatke te odabrati korisničko
    ime /username/ i lozinku /password/. Registriranjem na portal pristajete na ove uvjete korištenja i na korištenje
    vaših ličnih podataka u svrhe navedene u ovim uvjetima korištenja.</p>


  <p>Pojedinom korisniku može biti zabranjeno korištenje portala ili dijela portala, ako se ustanovi da je kršio
    pravila.</p>


  <p>Korisnik je dužan odmah prijaviti portalu Hayat.ba neovlašteno korištenje njegovog korisničkog računa. Portal
    Hayat.ba nije odgovoran za neovlašteno korištenje korisničkog računa, niti za eventualnu štetu nastalu na taj
    način.</p>


  <p><strong>UVJETI KORIŠTENJA SERVISA “GLEDAJ HAYAT” I “HAYAT PLAY”</strong></p>


  <p>Hayat d.o.o. nije odgovoran za kvalitet internet-veze koju vam nudi vaš provider, a putem kojeg koristite uslugu
    GLEDAJ HAYAT i Hayat PLAY. Hayat se odriče odgovornosti za eventualne štete ili nemogućnosti korištenja usluge.</p>


  <p>Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY koristiti poštujući autorska i druga srodna prava u
    pogledu sadržaja koji se servisom prenosi. Pretplatnik se obavezuje da će servis GLEDAJ HAYAT/Hayat PLAY koristiti
    za svoje privatne potrebe te neće ni na koji način učiniti dostupnom javnosti u smislu važećih propisa o autorskim i
    srodnim pravima, a posebno garantira da uslugu neće koristiti u zajedničkim ili javnim prostorijama bilo koje vrste,
    te da neće vršiti daljnju distribuciju signala trećim osobama, bilo da je u pitanju snimljeni ili sadržaj uživo.</p>


  <p><strong>GLEDAJ HAYAT/HAYAT PLAY</strong></p>


  <p><strong>GLEDAJ HAYAT</strong>&nbsp;je multiscreen usluga koja omogućava praćenje omiljenih TV-kanala putem svih
    uređaja koji imaju pristup internetu (tablet, mobilni, desktop/laptop). Ovaj servis nije moguće pratiti putem smart
    ili neke druge vrste televizora.</p>


  <p>Registracijom na uslugu GLEDAJ HAYAT te plaćanjem pretplate, po jednoj pretplati, validni su jedni pristupni
    podaci. Ako se korisnik koji je platio pretplatu nalazi na istoj IP-adresi gdje i ostali potencijalni korisnici
    (npr. uslugu platio jedan član porodice, a tu su još tri člana), onda i ostali potencijalni korisnici mogu
    pristupiti servisu s korisničkim podacima baznog vlasnika, odnosno korisničkim podacima korisnika koji je platio
    pretplatu. Ako se bazni vlasnik ne nalazi na istoj IP-adresi na kojoj su i ostali potencijalni korisnici, usluga će
    biti validna samo baznom vlasniku.</p>


  <p><strong>Hayat PLAY</strong>&nbsp;je VOD (Video On Demand/Video na zahtjev) usluga putem koje možete gledati
    propuštene epizode emisija i serija, koje su trenutno aktuelne i u ponudi servisa.</p>


  <p>Nakon otvaranja vašeg naloga i plaćanja pretplate na servis GLEDAJ Hayat/Hayat PLAY, pretplatnik ima mogućnost da
    koristi navedene usluge na bezbroj uređaja u jednom trenutku (tablet, mobilni, desktop/laptop ili TV s pristupom
    internetu) na istoj lokaciji korištenja (istoj pristupnoj adresi). Korištenje usluge na više lokacija istovremeno
    zahtijeva dodatne naloge. Plaćanjem jedne usluge dobijate pristup servisu GLEDAJ Hayat, odnosno gledanju kanala iz
    ponude UŽIVO, ali i servisu Hayat PLAY, odnosno videoteci. Usluge je moguće koristiti bilo gdje u svijetu samo s
    vašim nalogom.</p>


  <p>Ukoliko imate pitanja u vezi sa servisima, molimo vas da se obratite na mail:&nbsp;<a
    href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a></p>


  <p>Ukoliko imate pitanja u vezi s pretplatom, molimo vas obratite se na e-mail:&nbsp;<a
    href="mailto:pretplata@hayat.ba">pretplata&#64;hayat.ba</a>&nbsp;ili na broj +387 33 492 942 (09-17h CET)</p>


  <p><strong>USLOVI KORIŠTENJA HAYAT SAT KARTICE</strong></p>


  <p>Ove opće odredbe sastavni su dio Ugovora o tv pretplati i najmu HAYAT-SAT kartice kojom se omogućuje pristup
    gledanju tv programa iz Ugovora o tv pretplati i najmu HAYAT-SAT kartice sklopljenog između Hayat d.o.o. i
    pretplatnika (korisnika) naznačenog u Ugovoru o tv pretplati i najmu HAYAT-SAT kartice (Ugovor o tv pretplati i
    najmu).</p>


  <p>&nbsp;Ugovor o tv pretplati i najmu s ovim Općim uvjetima stupa na snagu danom kada Pretplatnik (korisnik) potpiše
    Ugovor o tv pretplati i najmu, te ostaje na snazi do dana otkaza ili raskida ugovora. Ugovor o tv pretplati i najmu
    sklopljen je na vrijeme naznačeno kao period plaćanja tv pretplate i najma, te istječe na kraju zadnjeg mjeseca
    perioda tv pretplate i najma.</p>


  <p>Hayat d.o.o. može prije isteka Ugovora o tv pretplati i najmu, dostaviti Pretplatniku (korisniku) račun za tv
    pretplatu za sljedeći pretplatnički period, te ako Pretplatnik (korisnik) istu plati u roku navedenom na računu,
    smatrat će se da je Ugovor o tv pretplati i najmu kartice produžen, zajedno sa svim uvjetima iz ovog Ugovora.
    Ugovorne strane mogu isključiti primjenu pojedinih odredbi Općih uvjeta jedino izričitim pristankom na drugačije
    i/ili suprotne odredbe u Ugovoru o tv pretplati i najmu. U svim ostalim slučajevima smatrat će se da su ovi Opći
    uvjeti prihvaćeni u cijelosti, te da čine sastavni dio Ugovora o tv pretplati i najmu. Sva obavještenja jedne
    ugovorne strane drugoj bit će dostavljana isključivo preporučeno u pisanoj formi. Svako obavještenje poslano na
    drugačiji način smatrat će se nevažećim u smislu zasnivanja prava i obaveza za ugovorne strane, osim ako Ugovorom o
    tv pretplati i najmu ili ovim Općim uvjetima nije predviđeno drugačije.</p>


  <p><strong>HAYAT-SAT&nbsp;</strong><strong>KARTICA</strong></p>


  <p>Kartica za dekodiranje programa Hayat d.o.o., odnosno programa utvrđenih u Ugovoru o tv pretplati i najmu, koji se
    emitiraju putem satelita, izdaje se na ime, u skladu s Ugovorom o tv pretplati i najmu, te je lična i
    neprenosiva.</p>


  <p>Ugovorne strane su saglasne da Hayat d.o.o. u svakom trenutku, bilo u toku trajanja Ugovora o tv pretplati i najmu,
    bilo nakon isteka ili raskida, ostaje vlasnik HAYAT-SAT kartice, kao i vlasnik softvera i opreme (mikroprocesora) na
    takvoj kartici. Pretplatnik (korisnik) je jedini odgovoran za upotrebu i čuvanje kartice. Svaki pokušaj umnožavanja
    ili bilo kakve upotrebe kartice suprotno ovim Općim uvjetima i Ugovoru o tv pretplati i najmu podliježe sankcijama
    predviđenim ovim Općim uvjetima i zakonom. Pretplatnik (korisnik) je dužan odmah pisanim putem obavijestiti Hayat
    d.o.o. o svakom gubitku, krađi ili oštećenju HAYAT-SAT kartice, a najkasnije u roku od 24 sata od takvog
    događaja.</p>


  <p><strong>OBAVEZE KORISNIKA-PRETPLATNIKA</strong></p>


  <p>Potpisivanjem Ugovora o tv pretplati i najmu Pretplatnik (korisnik) se obavezuje uredno ispunjavati sve ugovorne
    obaveze, a posebno:</p>


  <ul>
    <li>Čuvati HAYAT-SAT karticu za koju plaća tv pretplatu, te je na zahtjev Hayat d.o.o. vratiti, a sve u skladu sa
      Općim uvjetima.
    </li>
    <li>U toku trajanja Ugovora o tv pretplati i najmu Pretplatnik (korisnik) se obavezuje da neće naplaćivati ili
      komercijalno iskorištavati gledanje programa iz ovog Ugovora.
    </li>
    <li>U odnosu na Pretplatnika (korisnika) Ugovor o tv pretplati i najmu je lični ugovor, te ga Pretplatnik (korisnik)
      ne smije prenositi na treću osobu niti ustupiti toj osobi bez prethodnog pisanog odobrenja Hayat d.o.o. U odnosu
      na Hayat d.o.o, Ugovor o tv pretplati i najmu nije lični i Hayat d.o.o. ga može u svako vrijeme prenijeti na treće
      osobe, bez ikakve saglasnosti Pretplatnika. U slučaju iz prethodnog stavka Hayat d.o.o. je dužna obavijestiti
      Pretplatnika (korisnika) o osobi na koju prenosi Ugovor o tv pretplati i najmu, u roku od 15 dana, računajući od
      dana prijenosa Ugovora o tv pretplati i najmu na treću osobu.
    </li>
  </ul>


  <p><strong>IZMJENE I STUPANJE NA SNAGU</strong></p>


  <p>Portal Hayat.ba zadržava pravo izmjene i ažuriranja Općih uvjeta korištenja u bilo kojem trenutku te neće biti
    odgovoran ni za kakve posljedice koje mogu proizaći iz takvih promjena. Promjene u skladu s Uredbom EU 2016/679,
    stupaju na snagu trenutkom njihovog objavljivanja na domeni hayat.ba.</p>


  <p><strong>Zadnja izmjena:</strong>&nbsp;23.05.2018.</p>
</div>
