import {afterNextRender, afterRender, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, mergeMap, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {SignInForm} from '../interfaces/sign-in-form.interface';
import {AuthResponse} from '../interfaces/auth-response.interface';
import {Router} from '@angular/router';
import {Route} from '../constants/route.constants';
import {jwtDecode} from 'jwt-decode';
import {map} from "rxjs/operators";
import {isPlatformBrowser} from "@angular/common";


@Injectable({providedIn: 'root'})
export class AuthService {

  private readonly baseUrl: string = `${environment.backendUrl}/user/authenticate`;

  private jwt: string | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
  ) {
    afterNextRender(()=>{
      // runs on client / browser
      this.loadToken();
    })
  }
  public loadToken(): void {
    if (isPlatformBrowser(this.platformId)) {
      const tokenCookie = document.cookie.split('; ')
        .find(row => row.startsWith('jwt='));

      if (tokenCookie) {
        this.jwt = tokenCookie.split('=')[1];
      }
    }
  }

  public getToken(): string | null {
    if(this.jwt === null){
      this.loadToken()
    }
    return this.jwt;
  }

  public logIn(body: any): Observable<void> {
    return this.http.post<AuthResponse>(`${this.baseUrl}`, body).pipe(
      mergeMap(response => {
        this.jwt = response.token;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);


        document.cookie = `jwt=${this.jwt}; expires=${expirationDate.toUTCString()}; Secure; SameSite=None; path=/`;
        /*localStorage.removeItem('jwt');
        localStorage.setItem('jwt', this.jwt);*/

        // Parse the token to get the expiration time
        const decodedToken = jwtDecode(response.token);
        // @ts-ignore
        const expirationTime = decodedToken.exp * 1000; // Convert seconds to milliseconds
        const now = new Date().getTime();
        const expiresIn = expirationTime - now;
        // Set a timeout to automatically log the user out when the token expires
        setTimeout(() => {
          this.logout();
        }, expiresIn);

        return of(undefined);
      })
    );
  }



  /*public logout(): void {
    this.jwt = null;
    localStorage.removeItem('jwt');
  }*/
  public logout(): void {
    // Wait for the next event loop cycle to ensure the cookie is set
    setTimeout(() => {
      this.jwt = null;

      // Clear the token cookie
      document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None; path=/';
    }, 0);
  }



  public goToLogin():void {
    this.router.navigate([Route.LOGIN]);
  }

  isLoggedIn(): boolean {
    this.loadToken()
    return this.jwt !== null;
  }

}
