<div class="image-selector">
  <div class="tags">
    <input
      type="text"
      name="userInput"
      [(ngModel)]="userInput"
      [ngModelOptions]="{standalone: true}"
      placeholder="Type to search tags"
      (keyup.enter)="onEnter()"
    />
    <div *ngIf="allTags.length > 0 || selectedTags.length > 0" class="tag-container">
      <div
        *ngFor="let selectedTag of selectedTags"
        class="selected-tag"
        (click)="removeTag(selectedTag)"
      >
        {{ selectedTag.tag }}
      </div>
      <div
        *ngFor="let tag of filterTags()"
        class="tag"
        (click)="addTag(tag)"
      >
        {{ tag.tag }}
      </div>
    </div>
  </div>

  <!--WORKING -->
  <!--<div class="images">
    <div class="images-container">
      <div *ngFor="let image of allImages; trackBy: trackByFn" [class.selected]="isSelected(image)" (click)="toggleImage(image)" class="image">
        <img [src]="image.url" alt="Selected Image" />
      </div>
    </div>
  </div>-->

  <div class="images">
    <div class="images-container">
      <div *ngFor="let image of allImages; trackBy: trackByFn" [class.selected]="isSelected(image)" (click)="toggleImage(image)" class="image">
        <div *ngIf="isLoading(image)" class="loading-spinner"></div>
        <img [src]="image.url" alt="Selected Image" (load)="onImageLoad(image)" />
      </div>
    </div>
    <button *ngIf="more_images" (click)="loadNextPage()">LOAD MORE</button>
  </div>


</div>

<mat-dialog-actions>
  <button mat-button (click)="onConfirm()">Confirm</button>
  <button mat-button (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
