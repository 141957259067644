<app-basic-header></app-basic-header>
<div class="login">
 <!-- <h1>Dobro došli!</h1>
  <form (submit)="handleFormSubmit()" [formGroup]="form" class="container">
    <div class="form-field">
      <input type="text"
             placeholder="Email"
             formControlName="email"
             [class.missing-field]="isFieldMissing('username')">
    </div>

    <div class="form-field">
      <input type="password"
             placeholder="Password"
             formControlName="password"
             [class.missing-field]="isFieldMissing('password')">
    </div>

    <button class="button-regular"
            type="submit"
            aria-label="submit">
      Log in
    </button>
  </form>

  <p>Niste prijavljeni? <a [routerLink]="pathToSignup">Kreiraj profil</a></p>-->

  <router-outlet></router-outlet>
</div>
