<div>
  <h2>Schedule Article</h2>
  <div class="selection">
    <input type="datetime-local" [(ngModel)]="selectedDate"  id="deadline" [min]="currentDateTime()">
  </div>
  <div class="buttons">
    <button (click)="onCancel()">Cancel</button>
    <button (click)="onSave()">Save</button>
  </div>
</div>
