<form [formGroup]="guestForm" (ngSubmit)="onSubmit()" class="survey">
  <label for="name">Ime i prezime:</label>
  <input type="text" formControlName="name" id="name">

  <label for="email">Username:</label>
  <input type="text" formControlName="email" id="email">

  <div class="password-container">
    <label for="password">Password:</label>
    <input
      type="password"
      formControlName="password"
      id="password"
      placeholder="Enter your password"
    />
    <button class="passButton" type="button" (click)="togglePasswordVisibility()">
      <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </div>

  <div class="image-input">
    <div class="file">
      <label for="file-upload" class="custom-file-upload">
        Profile Picture
      </label>
      <div class="image-preview">
        @if(guest[GuestProperty.image_60px] != undefined){
          <img src="{{guest[GuestProperty.image_60px]}}" class="profile-image">
        } @else{
          <div class="image-info"> No profile picture</div>
        }
      </div>

      <input id="file-upload" type="file" multiple (change)="selectFiles($event)" accept=".png, .jpg, .jpeg, .webp"/>
    </div>
    <div class="server">
      <!--<button (click)="openImageSelector()">Izaberi fotografiju</button>-->
    </div>
  </div>

  <div class="buttons">
    <button type="submit">Create Guest</button>
  </div>
</form>
